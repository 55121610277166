<template>
    <el-drawer
        :title="type === 0 ? $i18n.t('ota.text9') : $i18n.t('ota.text105')"
        :visible.sync="drawer"
        :size="500"
        direction="rtl"
        append-to-body
        :wrapperClosable="false"
        @open="handleOpen"
        @close="closeDialog">
        <div class="title">{{$i18n.t('ota.text41')}}</div>
        <el-form
            :model="form" size="small"
            :rules="rules"
            ref="ruleForm"
            label-position="top"
            class="ota_form">

            <el-form-item prop="pid">
                <span slot="label">
                    {{$i18n.t('ota.text42')}}
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            <p>{{$i18n.t('ota.text43')}}</p>
                            <p>{{$i18n.t('ota.text44')}}</p>
                        </div>
                        <i
                            :class="productIcon"
                            @mouseover="productIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="productIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select
                    style="width: 100%;"
                    :remote-method="remoteMethod"
                    @change="selectPid"
                    allow-create
                    :loading="loadingProduct"
                    v-model="form.pid"
                    remote
                    ref="selectProduct"
                    filterable
                    :disabled="type === 1"
                    :placeholder="$i18n.t('ota.text45')">
                    <el-option-group
                        v-for="group in productList"
                        :key="group.label"
                        :label="group.label">
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.label + `(PID：${item.value})`"
                            :value="item.value">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>


            <el-form-item prop="firmwareModel">
                <span slot="label">
                    {{$i18n.t('ota.text46')}}
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            {{$i18n.t('ota.text47')}}
                        </div>
                        <i
                            :class="firmwareIcon"
                            @mouseover="firmwareIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="firmwareIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-input :disabled="type === 1" style="width: 100%;" v-model="form.firmwareModel" :placeholder="$i18n.t('ota.text48')"/>
            </el-form-item>

            <el-form-item class="is-required" :error="firmwareErr">
                <span slot="label">
                    {{$i18n.t('ota.text53')}}
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            {{$i18n.t('ota.text54')}}
                        </div>
                        <i
                            :class="uploadIcon"
                            @mouseover="uploadIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="uploadIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>

                <div class="upload-body"
                     :style="{cursor: type === 0 ? (file.name ? 'context-menu' : 'pointer') : 'not-allowed', opacity: type === 0 ? '1' : '.8'}"
                     :loading="fileLoading">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        :disabled="type === 1 || !!file.name"
                        :action="$message.uploadImgUrl"
                        :data="{
                           bucket: 'ota',
                           holdName: 'N' // 保持上传文件名不变
                        }"
                        :headers="{
                            accessToken: $token.getToken().accessToken,
                            refreshToken: $token.getToken().refreshToken,
                            language: language
                        }"
                        drag
                        v-loading="fileLoading"
                        :on-success="onSuccess"
                        :on-error="onError"
                        :before-upload="onbeforeunload"
                        :limit="1"
                        :show-file-list="false"
                        accept=".zip">
                        <div v-if="!file.name" :loading="fileLoading">
                            <i class="el-icon-upload" style="margin-top: 20px;font-size: 40px;"></i>
                            <div class="el-upload__text">{{$i18n.t('empower.text65')}}</div>
                            <div class="el-upload__subtitle">{{$i18n.t('ota.text49')}}</div>
                        </div>
                        <div class="upload-msg"
                             :style="{cursor: type === 0 ? (file.name ? 'context-menu' : 'pointer') : 'not-allowed', opacity: type === 0 ? '1' : '.8'}" v-else>
                            <span>{{ file.name }}</span>
                            <el-button type="text" style="padding: 0px; margin-left: 10px;"
                                       :disabled="type === 1"
                                       icon="el-icon-delete" @click.stop="delBtn()"></el-button>
                        </div>
                    </el-upload>
                    <div class="upload_title">
                        {{$i18n.t('ota.text50')}}
                        <a style="color: rgb(24, 144, 255)" href="https://oss.funlink.cloud/common/hbxpfca/res/20240928/dz7rpgjfzeo0/ota_pack_2_1_21_33.zip">{{$i18n.t('ota.text51')}}</a>
<!--                        <el-button type="text"-->
<!--                                 style="font-size: 12px;margin-left: 10px;">升级包示例-->
<!--                        </el-button>-->
                    </div>
                </div>
            </el-form-item>

            <div class="title" style="margin-top: 36px;margin-bottom: 17px;">{{$i18n.t('ota.text52')}}</div>
            <el-form-item prop="triggerType">
                <span slot="label">
                    {{$i18n.t('ota.text55')}}
                    <el-popover
                        placement="bottom"
                        title=""
                        width="200"
                        trigger="hover">
                        <div>
                            {{$i18n.t('ota.text56')}}<br>
                            {{$i18n.t('ota.text57')}}<br>
                            {{$i18n.t('ota.text58')}}<br>
                            {{$i18n.t('ota.text59')}}<br>
                        </div>
                        <i
                            :class="triggerModeIcon"
                            @mouseover="triggerModeIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="triggerModeIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select style="width: 100%;" v-model="form.triggerType" @change="triggerChange" :disabled="form.pid === ''" :placeholder="$i18n.t('ota.text60')">
                    <el-option
                        v-for="item in triggerModeList"
                        :disabled="item.isDisabled"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="downloadType">
                <span slot="label">
                    {{$i18n.t('ota.text63')}}
                    <el-popover
                        placement="bottom"
                        title=""
                        width="260"
                        trigger="hover">
                        <div>
                            {{$i18n.t('ota.text64')}}<br>
                            {{$i18n.t('ota.text65')}}<br>
                        </div>
                        <i
                            :class="downloadTypeIcon"
                            @mouseover="downloadTypeIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="downloadTypeIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select style="width: 100%;" v-model="form.downloadType" @change="downloadChange" :disabled="form.triggerType ===''" :placeholder="$i18n.t('ota.text61')">
                    <el-option
                        v-for="item in downloadTypeList"
                        :disabled="item.isDisabled"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="installType">
                <span slot="label">
                    {{$i18n.t('ota.text66')}}
                    <el-popover
                        placement="bottom"
                        title=""
                        width="260"
                        trigger="hover">
                        <div>
                            {{$i18n.t('ota.text67')}}<br>
                            {{$i18n.t('ota.text68')}}<br>
                        </div>
                        <i
                            :class="installTypeIcon"
                            @mouseover="installTypeIcon = 'empower_question_coicon icon-yuanyin'"
                            @mouseleave="installTypeIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>
                    </el-popover>
                </span>
                <el-select style="width: 100%;" v-model="form.installType" :disabled="form.downloadType === ''" :placeholder="$i18n.t('ota.text62')">
                    <el-option
                        v-for="item in installTypeList"
                        :disabled="item.isDisabled"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item :label="$i18n.t('ota.text69')" prop="reboot">
                <el-radio-group v-model="form.reboot">
                    <el-radio :label="item.value" :key="index" v-for="(item, index) in rebootList">
                        {{ item.label }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item :label="$i18n.t('ota.text70')">
                <el-checkbox-group v-model="partitions">
                    <el-checkbox :label="item.value" :key="index" v-for="(item, index) in partitionList">{{item.label}}</el-checkbox>
                </el-checkbox-group>
                <el-button type="text" @click="addPartition">{{$i18n.t('ota.text71')}}</el-button>
            </el-form-item>

            <el-form-item :label="$i18n.t('ota.text72')" prop="versionDescribe">
                <el-input
                    type="textarea"
                    :rows="4"
                    :maxlength="200"
                    :placeholder="$i18n.t('ota.text73')"
                    v-model="form.versionDescribe">
                </el-input>
                <div style="position: absolute;right: 0">{{form.versionDescribe.length}}/200</div>
            </el-form-item>

        </el-form>

        <!-- 弹窗底部按钮 start -->
        <div class="demo-drawer__footer">
            <el-button @click="closeDialog">{{$i18n.t('ota.text35')}}</el-button>
            <el-button type="primary" @click="submit" :loading="loading">{{type === 0 ? $i18n.t('ota.text74') : $i18n.t('ota.text75')}}</el-button>
        </div>
        <!-- 弹窗底部按钮 end -->
    </el-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "addOTADrawer",

    computed: {
        ...mapState('maintain', ['addOTADrawerVisible', 'addOTADrawerType', 'firmwareMsg'])
    },

    watch: {

        addOTADrawerVisible: {
            handler(newVal) {
                this.drawer = newVal;
            },
            deep: true
        },

        addOTADrawerType: {
            handler(newVal) {
                console.log('addOTADrawerType', newVal)
                this.type = newVal;
            },
            deep: true
        },

        firmwareMsg: {
            async handler(newVal) {
                console.log(newVal)

                if (newVal.productType === 1) {
                    await this.getProduct();
                } else {
                    this.productList = [{
                        label: this.$i18n.t('ota.text77'),
                        options: [
                            {
                                label: newVal.productName,
                                value: newVal.pid
                            }
                        ]
                    }]
                }
                this.form = {
                    otaFileCode: newVal.otaFileCode,
                    firmwareModel: newVal.firmwareModel,
                    filePath: newVal.srcFilePath,
                    fileUrl: newVal.srcFileUrl,
                    productType: newVal.productType,
                    triggerType: (newVal.triggerType || newVal.triggerType === 0) ? newVal.triggerType : '',
                    downloadType: (newVal.downloadType || newVal.downloadType === 0) ? newVal.downloadType : '',
                    installType: (newVal.installType || newVal.installType === 0) ? newVal.installType : '',
                    reboot: newVal.reboot ? "1" : "2",
                    versionDescribe: newVal.versionDescribe ? newVal.versionDescribe : '',
                    pid: newVal.pid
                }
                console.log(this.productList)
                let partitions = [];
                if (newVal.wipeData) partitions.push(1)
                if (newVal.wipeMedia) partitions.push(2)
                newVal.wipeArea && newVal.wipeArea !== "" && JSON.parse(newVal.wipeArea).forEach(item => {
                    this.partitionList.push({label: item.n, value: item.k})
                    if (item.v) partitions.push(item.k)
                })
                this.partitions = partitions;
                if (!newVal.srcFilePath) return
                let filePathList = newVal.srcFilePath.split("/")
                this.file.name = filePathList[filePathList.length - 1];
            }
        }
    },

    data() {
        return {
            type: 0,
            drawer: false,
            loading: false,
            productIcon: "empower_question_alicon iconfont_al icon-yiwen",
            firmwareIcon: "empower_question_alicon iconfont_al icon-yiwen",
            uploadIcon: "empower_question_alicon iconfont_al icon-yiwen",
            triggerModeIcon: "empower_question_alicon iconfont_al icon-yiwen",
            downloadTypeIcon: "empower_question_alicon iconfont_al icon-yiwen",
            installTypeIcon: "empower_question_alicon iconfont_al icon-yiwen",
            fileLoading: false,
            loadingProduct: false,
            productName: '',
            language: localStorage.getItem('language'),
            form: {
                otaFileCode: '',
                pid: "",
                firmwareModel: "",
                filePath: "",
                fileUrl: "",
                productType: 1,
                triggerType: "",
                downloadType: "",
                installType: "",
                reboot: "1",
                versionDescribe: ""
            },
            partitions: [],
            file: {},
            firmwareErr: "",
            rules: {
                pid: {required: true, message: this.$i18n.t('ota.text45'), trigger: 'change'},
                firmwareModel: {required: true, message: this.$i18n.t('ota.text78'), trigger: 'change'},
                triggerType: {required: true, message: this.$i18n.t('ota.text60'), trigger: 'change'},
                downloadType: {required: true, message: this.$i18n.t('ota.text61'), trigger: 'change'},
                installType: {required: true, message: this.$i18n.t('ota.text62'), trigger: 'change'},
                reboot: {required: true, message: this.$i18n.t('ota.text79'), trigger: 'change'},
                versionDescribe: {required: true, message: this.$i18n.t('ota.text73')},
            },
            installTypeList: this.$message.installTypeList,
            triggerModeList: this.$message.triggerModeList,
            productList: [
                {
                    label: this.$i18n.t('ota.text76'),
                    options: []
                },
                {
                    label: this.$i18n.t('ota.text77'),
                    options: []
                }
            ],
            downloadTypeList: [
                {label: this.$i18n.t('ota.text80'), value: 0, isDisabled: false},
                {label: this.$i18n.t('ota.text81'), value: 1, isDisabled: false},
            ],
            rebootList: [
                {label: this.$i18n.t('ota.text82'), value: '1'},
                {label: this.$i18n.t('ota.text83'), value: '2'},
            ],
            partitionList: [
                {label: this.$i18n.t('ota.text84'), value: 1},
                {label: this.$i18n.t('ota.text85'), value: 2},
            ],
            searchProductTimeout: null
        }
    },

    methods: {

        ...mapActions('product', ['queryProduct']),
        ...mapActions('maintain', ['closeAddOTADrawer', 'queryProductAndAliasSimpleList', 'addProductalias', 'importFirmwareFile', 'modifyFirmwareFile']),

        selectPid(event) {
            console.log(event)
            if (this.productName === event) {
                this.$dialog.showConfirm({
                    content: this.$i18n.t('ota.text86'),
                    title: this.$i18n.t('ota.text87'),
                    btn1: this.$i18n.t('ota.text82'),
                    btn2: this.$i18n.t('ota.text83'),
                    icon: 2
                }, () => {
                    this.addProductalias({
                        name: event
                    }).then(res => {
                        if (this.productList[1].options) {
                            this.productList[1].options.push({
                                label: event,
                                value: res.result
                            })
                        } else {
                            this.productList[1] ={
                                label: this.$i18n.t('ota.text77'),
                                options: [{
                                    label: event,
                                    value: res.result
                                }]
                            }
                        }
                        this.form.pid = res.result;
                        this.form.productType = 2;
                        this.triggerModeList.forEach(item => {
                            item.isDisabled = !(item.value === 0 || item.value === 3);
                        })
                        this.form.triggerType = "";
                        this.form.downloadType = "";
                        this.form.installType = "";
                        this.$refs.selectProduct.blur();

                    })
                }, () => {
                    this.form.pid = ""
                    this.$refs.selectProduct.blur();
                })
            } else {
                this.form.pid = event;
                let isIOTProduct = false;
                this.productList[0].options.forEach(item => {
                    if (item.value === event) {
                        isIOTProduct = true;
                    }
                });
                this.form.productType = isIOTProduct ? 1 : 2;
                this.form.productType === 1 && this.triggerModeList.forEach(item => {item.isDisabled = false;})
                this.form.productType === 2 && this.triggerModeList.forEach(item => {
                    item.isDisabled = !(item.value === 0 || item.value === 3);
                })
                this.form.triggerType = "";
                this.form.downloadType = "";
                this.form.installType = "";
            }
        },

        getProduct() {
            this.loadingProduct = true;
            this.productList = []
            this.queryProduct({"currPage":1,"pageSize":10,"keyword":"","productStatus":3,"protocolType":""}).then(res => {
                let productList = [];
                res.result.list.forEach(item => {
                    productList.push({
                        label: item.name,
                        value: item.pid
                    })
                })

                this.productList[0] = productList.length > 0 ? {
                    label: this.$i18n.t('ota.text76'),
                    options: productList
                } : {}
                this.productList[1] = {}
            }).finally(() => this.loadingProduct = false)
        },

        remoteMethod(event) {
            this.productName = event;
            this.loadingProduct = true;
            if (this.searchProductTimeout) clearTimeout(this.searchProductTimeout);
            this.searchProductTimeout = setTimeout(() => {
                if (event === '') {
                    this.getProduct();
                    return;
                }
                this.queryProductAndAliasSimpleList({
                    productName: event
                }).then(res => {
                    if (res.code === '0') {
                        let productAliasList = [];
                        let productList = [];
                        res.result.productAliasList.forEach(item => {
                            productAliasList.push({
                                label: item.name,
                                value: item.pid
                            })
                        })
                        res.result.productList.forEach(item => {
                            productList.push({
                                label: item.name,
                                value: item.pid
                            })
                        })
                        this.productList[0] = productList.length > 0 ? {
                            label: this.$i18n.t('ota.text76'),
                            options: productList
                        } : {}
                        this.productList[1] = productAliasList.length > 0 ? {
                            label: this.$i18n.t('ota.text77'),
                            options: productAliasList
                        } : {}
                    }
                }).finally(() => this.loadingProduct = false)
            }, 1000)
        },

        addPartition() {
            this.$prompt(this.$i18n.t('ota.text88'), this.$i18n.t('ota.text71'), {
                confirmButtonText: this.$i18n.t('ota.text89'),
                cancelButtonText: this.$i18n.t('ota.text35'),
                inputPattern: /^(?!\s*$).{1,20}$/,
                inputErrorMessage: this.$i18n.t('ota.text90')
            }).then(({ value }) => {
                this.partitionList.push({
                    value: this.partitionList.length + 1,
                    label: value
                })
                this.$message({
                    type: 'success',
                    message: this.$i18n.t('ota.text91')
                });
            }).catch(() => {});
        },

        delBtn() {
            this.file = {}
            this.form.filePath = '';
            this.form.fileUrl = '';
            this.$refs.upload.clearFiles();
        },

        triggerChange(event) {
            this.form.downloadType = "";
            this.form.installType = "";
            // 产品
            (this.form.productType === 1 && event === 0) && this.downloadTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 1 && event === 1) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 1;
            });
            (this.form.productType === 1 && event === 2) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && event === 3) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });

            // 昵称
            (this.form.productType === 2 && event === 0) && this.downloadTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 2 && event === 3) && this.downloadTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
        },

        downloadChange(event) {
            this.form.installType = "";
            //产品
            (this.form.productType === 1 && this.form.triggerType === 0 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 1 && this.form.triggerType === 0 && event === 1) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && this.form.triggerType === 1 && event === 1) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && this.form.triggerType === 2 && event === 0) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 1 && this.form.triggerType === 3 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});

            // 昵称
            (this.form.productType === 2 && this.form.triggerType === 0 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});
            (this.form.productType === 2 && this.form.triggerType === 0 && event === 1) && this.installTypeList.forEach(item => {
                item.isDisabled = item.value !== 0;
            });
            (this.form.productType === 2 && this.form.triggerType === 3 && event === 0) && this.installTypeList.forEach(item => {item.isDisabled = false;});
            console.log(this.form.productType, this.form.triggerType, event, this.installTypeList)
        },

        onbeforeunload(file) {
            console.log(file, file.size)
            this.fileLoading = true;
            if (file.size > (8*1024*1024*1024)) {
                this.firmwareErr = this.$i18n.t('ota.text92');
                this.fileLoading = false;
                return false;
            }
            return true;
        },

        onSuccess(response, file, fileList) {
            this.fileLoading = false;

            console.log(response)

            if (response.success) {
                this.file = file;
                this.file.name = file.name;
                this.form.filePath = response.result.fileList[0].filePath;
                this.form.fileUrl = response.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
        },

        onError(err, file, fileList) {
            this.fileLoading = false;
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },

        handleOpen() {
            this.type = this.addOTADrawerType;
        },

        closeDialog() {
            this.closeAddOTADrawer();
            this.$refs.ruleForm.resetFields();
            this.$store.commit('maintain/SET_FIRMWARE_MSG', {});
            this.form = {
                otaFileCode: "",
                pid: "",
                firmwareModel: "",
                filePath: "",
                fileUrl: "",
                productType: 1,
                triggerType: "",
                downloadType: "",
                installType: "",
                reboot: "1",
                versionDescribe: ""
            }
            this.partitions = []
            this.partitionList = [
                {label: this.$i18n.t('ota.text84'), value: 1},
                {label: this.$i18n.t('ota.text85'), value: 2},
            ]
            this.file = {};
            this.firmwareErr = ""
        },

        submit() {
            if (!this.form.fileUrl) {
                this.firmwareErr = this.$i18n.t('ota.text93')
                return;
            }
            this.firmwareErr = ""
            let wipeArea = [];
            let wipeData = "false";
            let wipeMedia = "false";
            this.partitionList.forEach((item, index) => {
                if (index > 1) {
                    wipeArea.push({
                        k: item.value,
                        v: this.partitions.includes(item.value),
                        n: item.label
                    })
                }
            })
            if (this.partitions.length > 0) {
                this.partitions.forEach(item => {
                    if (item === 1) {
                        wipeData = "true";
                    } else if (item === 2) {
                        wipeMedia = "true";
                    }
                })
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    if (this.type === 0) {
                        this.importFirmwareFile({
                            downloadType: this.form.downloadType,
                            filePath: this.form.filePath,
                            fileUrl: this.form.fileUrl,
                            firmwareModel: this.form.firmwareModel,
                            installType: this.form.installType,
                            pid: this.form.pid,
                            reboot: this.form.reboot === '1' ? 'true' : 'false',
                            productType: this.form.productType,
                            triggerType: this.form.triggerType,
                            versionDescribe: this.form.versionDescribe,
                            wipeArea: JSON.stringify(wipeArea),
                            wipeData,
                            wipeMedia
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('ota.text94'), this.$config.TOAST_SUCCESS);
                            this.$emit('getMsg');
                            this.closeDialog();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                    if (this.type === 1) {
                        this.modifyFirmwareFile({
                            downloadType: this.form.downloadType,
                            installType: this.form.installType,
                            otaFileCode: this.form.otaFileCode,
                            productType: this.form.productType,
                            reboot: this.form.reboot === '1' ? 'true' : 'false',
                            triggerType: this.form.triggerType,
                            versionDescribe: this.form.versionDescribe,
                            wipeArea: JSON.stringify(wipeArea),
                            wipeData,
                            wipeMedia
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('ota.text95'), this.$config.TOAST_SUCCESS);
                            this.$emit('getMsg');
                            this.closeDialog();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                }
            })
        }

    }

}
</script>

<style scoped>
/deep/ .el-drawer__header {
    font-weight: bold !important;
    text-align: left;
    color: black !important;
    padding: 10px 20px;
    margin-bottom: 0px;
    border-bottom: 1px solid #ebecf0;
}

/deep/ .el-drawer__body {
    padding: 0 20px;
}

/deep/ .el-divider--horizontal {
    margin: 0;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px 20px;
    width: 500px;
    text-align: left;
    background-color: white;
    border-top: 1px solid #ebecf0;
}

.title {
    font-size: 14px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    margin-top: 17px;
}

.upload_title {
    font-size: 12px;
    color: rgb(127, 127, 127);
    /*margin-top: -26px;*/
    margin-top: -16px;
    line-height: 20px;
}

.ota_form {
    margin-bottom: 100px;
    margin-top: 14px;
}

.empower_question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    top: 1px;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.empower_question_alicon {
    color: #3296FA;
    font-size: 16px;
    position: relative;
    top: 1px;
    cursor: pointer;
}

.upload-body {
    /*position: relative;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    height: 150px;
    border-radius: 4px;
}

.upload-demo {
    /*position: absolute;*/
    /*top: 10px;*/
    /*bottom: 10px;*/
    /*left: 10px;*/
    /*right: 10px;*/
}

.upload-msg {
    margin-top: 50px;
    font-size: 20px;
    color: #3296FA;
}

/deep/ .el-upload-dragger, /deep/ .el-upload {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
}

.el-upload__text {
    line-height: 5px;
    margin-top: -10px;
}

.el-upload__subtitle {
    font-size: 12px;
    line-height: 30px;
}
</style>
