<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="新增人员"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog"
        append-to-body
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="姓名：" prop="name">
                <el-input
                    type="text"
                    v-model.trim="form.name"
                    placeholder="请输入姓名">
                </el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="telephone">
                <el-input
                    type="text"
                    v-model.trim="form.telephone"
                    placeholder="请输入手机号">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    name: "addPersonnelDialog",

    computed: {
        ...mapState('maintain', ['addPersonnelDialogVisible'])
    },

    watch: {

        addPersonnelDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            form: {
                name: "",
                telephone: ""
            },

            rules: {
                name: [
                    {required: true, message: "请输入姓名"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: '输入不能超过20个字符'}
                ],
                telephone: [
                    {required: true, message: "请输入手机号"},
                    {pattern: /^0?1[0-9]{10}$/, message: '手机号格式不正确'}
                ]
            }

        }

    },

    methods: {

        ...mapActions('maintain', ['closeAddPersonnelDialog']),

        openDialog() {

        },

        closeDialog() {
            this.closeAddPersonnelDialog();
        }

    }

}
</script>

<style scoped>

</style>
