<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="type == 1?'编辑菜单':(type == 2?'新增一级菜单':'新增二级菜单')"
        :visible.sync="visible"
        width="30%"
        :top="type == 4?'5vh':'15vh'"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item label="上级菜单：" v-if="type != 2 && form.level > 1">
                用户管理
            </el-form-item>
            <el-form-item label="菜单名称：" :prop="(type == 1 || type == 2 || type == 3)?'name':''">
                <el-input
                    v-model.trim="form.name"
                    placeholder="请输入品类名称"
                    v-if="type != 4">
                </el-input>
                <span v-else>{{ form.name }}</span>
            </el-form-item>
            <el-form-item label="显示排序：" :prop="(type == 1 || type == 2 || type == 3)?'sort':''">
                <el-input
                    v-model.trim="form.sort"
                    placeholder="请输入显示排序"
                    type="number"
                    v-if="type != 4">
                </el-input>
                <span v-else>{{ form.sort }}</span>
            </el-form-item>
            <el-form-item label="创建人：" :prop="(type == 1 || type == 2 || type == 3)?'founder':''">
                <el-input
                    v-model.trim="form.founder"
                    placeholder="请输入创建人"
                    v-if="type != 4">
                </el-input>
                <span v-else>{{ form.founder }}</span>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
    computed: {
        ...mapState('menu', ['addMenuDialogVisible', 'addMenuType', 'menuMsgItem'])
    },
    watch: {
        addMenuDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },
        addMenuType: {
            handler(newVal) {
                this.type = newVal;
            }
        },
        menuMsgItem: {
            handler(newVal) {
                this.form = newVal;
            },
            deep: true
        }
    },
    data() {
        return {
            formLabelWidth: "100px",
            loading: false,
            visible: false,
            type: 1,
            form: {},
            rules: {
                name: [{required: true, message: "请输入菜单名称", trigger: "blur"}],
                sort: [{required: true, message: "请输入显示排序", trigger: "blur"}],
                founder: [{required: true, message: "请输入创建人", trigger: "blur"}],
            }
        }
    },
    methods: {
        ...mapActions('menu', ['closeAddMenuDialog']),
        closeDialog() {
            this.closeAddMenuDialog();
            this.$refs.ruleForm.resetFields();
        },
        openDialog() {
            this.type = this.addMenuType;
        }
    },
}
</script>

<style scoped>

</style>
