<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="新增测试"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" label-width="100px">

            <el-form-item label="升级方式：" prop="upgradeType">

                <el-select v-model="form.upgradeType" :popper-append-to-body="false" clearable placeholder="请选择">
                    <el-option
                        v-for="item in $message.upgradeTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>

            </el-form-item>

            <el-form-item label="升级用户：" prop="upgradeUsers">
                <el-select v-model="form.upgradeUsers" :popper-append-to-body="false" clearable multiple placeholder="请选择">
                    <el-option label="张三" :value="1"></el-option>
                    <el-option label="李四" :value="2"></el-option>
                    <el-option label="王五" :value="3"></el-option>
                    <el-option label="赵六" :value="4"></el-option>
                </el-select>
            </el-form-item>

        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "addFirmwareTestDialog",

    computed: {
        ...mapState('maintain', ['addFirmwareTestDialogVisible'])
    },

    watch: {

        addFirmwareTestDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            form: {
                upgradeType: "",
                upgradeUsers: []
            },

            rules: {
                upgradeType: [
                    {required: true, message: "请选择升级方式", trigger: "blur"}
                ],
                upgradeUsers: [
                    {required: true, message: "请选择升级用户", trigger: "blur"}
                ]
            }

        }

    },

    methods: {

        ...mapActions('maintain', ['closeAddFirmwareTestDialog']),

        openDialog() {},

        closeDialog() {
            this.closeAddFirmwareTestDialog();
        },

    }

}
</script>

<style scoped>
.el-select {
    width: 100%;
}
</style>
