<template>
    <el-card
        :body-style="{
            textAlign: 'left',
            padding: '15px'
        }"
        shadow="never">
        <div slot="header" class="clearfix">
            <span style="font-size: 16px;color: black;">安全管理</span>
        </div>
        <div class="content">
            <el-form ref="form" label-width="80px">
                <el-form-item label="手机号：">
                    {{ from.tel }}
                    <el-button type="text" class="btn" @click="updateTel">更换手机号</el-button>
                </el-form-item>
                <el-form-item label="用户ID：">
                    {{ from.userId }}
                </el-form-item>
                <el-form-item label="密码：">
                    {{ from.password }}
                    <el-button type="text" class="btn" @click="updatePassword">修改密码</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-card>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['userInfo'])
    },

    watch: {
        userInfo: {
            handler(newVal) {
                this.from.tel = newVal.telephone;
                this.from.userId = newVal.userId;
            }
        }
    },

    data() {
        return {
            from: {
                tel: "",
                userId: "",
                password: "***********"
            }
        }
    },

    methods: {

        updateTel() {
            this.$store.commit("setting/SET_UPDATE_TEL_DIALOG_VISIBLE", true);
        },

        updatePassword() {
            this.$store.commit("setting/SET_UPDATE_PASSWORD_DIALOG_VISIBLE", true);
        }

    }

}
</script>

<style scoped>

.el-card {
    border: none;
    border-radius: 8px;
    height: calc(50% - 23px);
    margin: 0 20px;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.content {
    margin-left: 80px;
    margin-top: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.el-form-item {
    margin-bottom: 5px;
}

/deep/ .el-form-item__label {
    font-weight: bold;
}

.btn {
    margin-left: 20px;
    color: #02A7F0;
}
</style>
