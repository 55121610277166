<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('empower.text50')"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm">

            <el-form-item prop="amount">
                <el-input
                    type="text"
                    v-model.trim="form.amount"
                    :placeholder="$i18n.t('empower.text51')">
                </el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{$i18n.t('empower.text52')}}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('empower.text53')}}</el-button>
        </span>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "addAuthDialog",

    computed: {
        ...mapState('empower', ['addAuthDialogVisible', 'itemMsg'])
    },

    watch: {
        addAuthDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },
        itemMsg: {
            handler(newVal) {
                this.msg = newVal;
            }
        }
    },

    data() {
        return {

            loading: false,

            visible: false,

            form: {
                //导出数量
                amount: ""
            },

            msg: {},

            rules: {
                amount: [
                    {required: true, message: this.$i18n.t('empower.text51'), trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: this.$i18n.t('empower.text54')},
                ]
            }

        }
    },

    methods: {

        ...mapActions('empower', ['closeAddAuthDialog', 'addIncreaseAuthorization']),

        closeDialog() {
            this.closeAddAuthDialog();
            this.form.amount = "";
            this.$refs.ruleForm.resetFields();
        },

        openDialog() {
        },

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.addIncreaseAuthorization({
                        authorizationCode: this.msg.code,
                        amount: this.form.amount
                    }).then(res => {
                        this.$dialog.showMessage(this.$i18n.t('empower.text55'), this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.$emit('getMsg')
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        }

    }
}
</script>

<style scoped>

/deep/ .el-dialog__body {
    margin-top: 20px;
}
</style>
