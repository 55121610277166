<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('mainIndex.text17')"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item :label="$i18n.t('mainIndex.text18')" prop="productName">
                <el-input
                    type="text"
                    v-model.trim="form.productName"
                    :placeholder="$i18n.t('mainIndex.text19')">
                </el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('mainIndex.text20')" prop="productModel">
                <el-input
                    type="text"
                    v-model.trim="form.productModel"
                    :placeholder="$i18n.t('mainIndex.text21')">
                </el-input>
            </el-form-item>
<!--            <el-form-item label="面板程序：">-->
<!--                <el-select v-model="form.panel" placeholder="选择面板程序">-->
<!--                    <el-option-->
<!--                        v-for="item in panelList"-->
<!--                        :key="item.id"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{$i18n.t('mainIndex.text22')}}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('mainIndex.text23')}}</el-button>
        </span>

    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('main', ['copyProductDialogVisible', 'productItemMsg'])
    },

    data() {
        return {

            //表单label宽度
            formLabelWidth: "130px",

            loading: false,

            formMsg: {
                //产品名称
                productName: "",
                //产品类型
                productType: "",
                //产品类型名称
                productTypeName: ""
            },

            form: {
                //产品型号
                productModel: "",
                //产品名称
                productName: "",
                //面板编号
                panel: ""
            },

            //面板列表
            panelList: [],

            //弹窗开关
            visible: false,

            rules: {
                productName: [
                    {required: true, message: this.$i18n.t('mainIndex.text19'), trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('mainIndex.text24')},
                ],
                productModel: [
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('mainIndex.text24')},
                    {pattern: /^[a-z_0-9]*$/, message: this.$i18n.t('mainIndex.text25')},
                ]
            }
        }
    },

    watch: {

        //复制产品弹窗开关
        copyProductDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
                console.log(newVal)
            }
        },

        //产品信息
        productItemMsg: {
            handler(newVal) {
                this.formMsg = newVal;
            },
            deep: true
        }

    },

    methods: {

        ...mapActions('main', ['closeCopyProductDialog']),
        ...mapActions('product', ['copeProduct']),
        ...mapActions('panel', ['queryApplyList']),

        /**
         * 关闭弹窗回调
         */
        closeDialog() {
            this.form = {
                productModel: "",
                productName: "",
                panel: ""
            }
            this.closeCopyProductDialog();
            this.$refs.ruleForm.resetFields();
        },

        /**
         * 打开弹窗回调
         */
        openDialog() {
            let language = window.localStorage.getItem('language');
            this.formLabelWidth = language === 'zh' ? '100px' : '130px'
            //获取面板列表
            this.queryApplyList({
                productCode: this.formMsg.code
            }).then(res => {
                this.panelList = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 复制产品
         */
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.copeProduct({
                        model: this.form.productModel,
                        name: this.form.productName,
                        productCode: this.formMsg.code,
                        rnApplyId: this.form.panel
                    }).then(res => {
                        this.$dialog.showMessage(this.$i18n.t('mainIndex.text26'), this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.$emit('getMsg');
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        }

    }

}
</script>

<style scoped>
.el-select {
    width: 100%;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-form {
    margin-top: 20px;
}
</style>
