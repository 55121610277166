<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="新增License"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm">

            <el-form-item prop="amount">
                <el-input
                    type="text"
                    v-model.trim="form.amount"
                    placeholder="请输入申请数量">
                </el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 认</el-button>
        </span>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "addLicenseDialog",

    computed: {
        ...mapState('empower', ['addLicenseDialogVisible'])
    },

    watch: {
        addLicenseDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },

    data() {
        return {

            loading: false,

            visible: false,

            form: {
                //导出数量
                amount: ""
            },

            rules: {
                amount: [
                    {required: true, message: "请输入申请数量", trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数'},
                ]
            }

        }
    },

    methods: {

        ...mapActions('empower', ['closeAddLicenseDialog', 'generateLicense']),

        closeDialog() {
            this.closeAddLicenseDialog();
            this.form.amount = "";
            this.$refs.ruleForm.resetFields();
        },

        openDialog() {
        },

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.generateLicense({
                        authorizationCode: this.$route.query.code,
                        amount: this.form.amount
                    }).then(res => {
                        this.$dialog.showMessage("申请License成功", this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.$emit('getMsg')
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            })
        }

    }

}
</script>

<style scoped>
/deep/ .el-dialog__body {
    margin-top: 20px;
}
</style>
