<template>
    <div class="step2">
        <span class="step2-title">网络设置修改后，所有配网方式的网络设置将同步更新</span>
        <div class="next-btn">
            <span class="next-btn-label">展示单选框：</span>
            <el-switch
                style="width: 40px;"
                @change="onNetSettingChange"
                v-model="form.isShowRadio">
            </el-switch>
        </div>
        <div class="next-btn" v-if="activeNum === '0' && stepNum === '2'">
            <span class="next-btn-label">开启【跳过】按钮：</span>
            <el-switch
                style="width: 40px;"
                @change="onNetSettingChange"
                v-model="form.isOpenSkip">
            </el-switch>
        </div>
    </div>
</template>

<script>
export default {
    name: "netSetting",

    props: {
        formValue: {
            Type: String,
            default: ""
        },
        activeName: {
            Type: String,
            default: ""
        },
        step: {
            Type: String,
            default: ""
        }
    },

    watch: {
        activeName: {
            handler(newVal) {
                this.activeNum = newVal;
            }
        },
        step: {
            handler(newVal) {
                this.stepNum = newVal;
            }
        },
        formValue: {
            handler(newVal) {
                if (newVal) {
                    this.form = JSON.parse(newVal)
                }
            }
        }
    },

    data() {
        return {
            form: {
                name: "",
                isShowRadio: true,
                isOpenSkip: true
            },
            activeNum: "",
            stepNum: ""
        }
    },

    methods: {

        onNetSettingChange() {
            this.$emit('change', this.form);
        }

    }
}
</script>

<style scoped>


.step2 {
    width: calc(100% - 48px);
    margin: 24px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.step2-title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
}

.next-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.next-btn-label {
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}
</style>
