<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="(type === 0 ? $i18n.t('userInfo.text46') : $i18n.t('userInfo.text47'))"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <p style="margin-top: 20px;" v-show="isFirst">{{$i18n.t('userInfo.text48')}}</p>
        <el-form :model="form1" size="small" :rules="rules1" ref="ruleForm1" v-show="isFirst">
            <el-form-item prop="password" :error="passwordErr">
                <el-input
                    :type="isShowPwd ? 'text' : 'password'"
                    v-model.trim="form1.password"
                    auto-complete="off"
                    @focus="()=> passwordErr = ''"
                    :placeholder="$i18n.t('userInfo.text14')">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd = !isShowPwd"></i>
                </el-input>
            </el-form-item>
        </el-form>
        <el-form :model="form" size="small" style="margin-top: 20px;" :rules="rules" ref="ruleForm" v-show="!isFirst">
            <el-form-item prop="areaNumber">
                <el-select v-model="form.areaNumber" style="width: 100%;" placeholder="请选择国家/地区" v-if="type === 0">
                    <el-option
                        v-for="item in areaNumberList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :error="phoneErr" v-if="type === 0">
                <el-input
                    type="text"
                    @focus="()=> phoneErr = ''"
                    v-model.trim="form.username"
                    :placeholder="$i18n.t('userInfo.text49')">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :error="emailErr" v-else>
                <el-input
                    type="text"
                    @focus="()=> emailErr = ''"
                    v-model.trim="form.username"
                    :placeholder="$i18n.t('userInfo.text50')">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input
                    v-model.trim="form.code"
                    :placeholder="$i18n.t('userInfo.text34')">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">{{$i18n.t('userInfo.text35')}}</el-button>
			<el-button type="primary" :loading="loading" @click="next" v-if="isFirst">{{$i18n.t('userInfo.text51')}}</el-button>
			<el-button type="primary" :loading="loading" @click="submit" v-else>{{$i18n.t('userInfo.text36')}}</el-button>
		</span>
    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('setting', ['updateTelDialogVisible', 'updateTelDialogType']),
        ...mapState('login', ['userInfo'])
    },

    watch: {

        updateTelDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        updateTelDialogType: {
            handler(newVal) {
                this.type = newVal;
            }
        }

    },

    props: {
        areaNumberList: {
            Type: Array,
            default: []
        }
    },

    data() {
        return {
            loading: false,
            isShowPwd: false,
            form1: {
                password: ""
            },
            form: {
                username: "",
                code: "",
                smsId: "",
                areaNumber: ""
            },
            codeTime: null,
            isFirst: true,
            codeBtnLoading: false,
            time: 60,
            isCodeBtnDisabled: false,
            codeBtnMsg: this.$i18n.t('userInfo.text37'),
            visible: false,
            type: 0,
            phoneErr: "",
            emailErr: "",
            passwordErr: "",
            rules: {
                areaNumber: [{required: true, message: this.$i18n.t('userInfo.text38'), trigger: "blur"}],
                code: [{required: true, message: this.$i18n.t('userInfo.text39'), trigger: "blur"}]
            },
            rules1: {
                password: [{required: true, message: this.$i18n.t('userInfo.text52'), trigger: "blur"}]
            }
        }
    },

    methods: {

        ...mapActions('setting', ['closeUpdateTelDialog']),

        ...mapActions('login', ['sendSms', 'modifyPhone', 'sendUserSms', 'checkPassword', 'getlogininfo', 'isAllowRegistration', 'modifyEmail']),

        openDialog() {
            this.form.areaNumber = this.userInfo.areaNumber;
        },

        closeDialog() {
            this.cleanCountDown();
            this.closeUpdateTelDialog();
            this.$refs.ruleForm.resetFields();
            this.$refs.ruleForm1.resetFields();
            this.isFirst = true;
            this.form1 = {
                password: ""
            };
            this.form = {
                username: "",
                areaNumber: "",
                code: "",
                smsId: ""
            };
        },

        submit() {
            if (!this.form.smsId) {
                this.$dialog.showMessage(this.$i18n.t('userInfo.text44'), this.$config.TOAST_WARNING);
                return
            }
            let isStart = true;
            if (this.type === 0) {
                if (!this.$config.phoneRight(this.form.username)) {
                    this.phoneErr = this.$i18n.t('userInfo.text40')
                    isStart = false;
                }
                if (!this.form.username) {
                    this.phoneErr = this.$i18n.t('userInfo.text32')
                    isStart = false;
                }
            } else {
                if (!this.$config.emailRight(this.form.username)) {
                    this.emailErr = this.$i18n.t('userInfo.text41')
                    isStart = false;
                }
                if (!this.form.username) {
                    this.emailErr = this.$i18n.t('userInfo.text33')
                    isStart = false;
                }
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    this.loading = true;
                    if (this.type === 0) {
                        this.modifyPhone({
                            code: this.form.code,
                            newPhone: this.form.username,
                            smsId: this.form.smsId,
                            areaNumber: this.form.areaNumber
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('userInfo.text26'), this.$config.TOAST_SUCCESS);
                            this.closeDialog();
                            this.$emit('getUserInfo');
                            this.getlogininfo();
                        }, err => {
                            console.log(err);
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false;
                        })
                    } else {
                        this.modifyEmail({
                            code: this.form.code,
                            email: this.form.username,
                            smsId: this.form.smsId
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('userInfo.text26'), this.$config.TOAST_SUCCESS);
                            this.closeDialog();
                            this.$emit('getUserInfo');
                            this.getlogininfo();
                        }, err => {
                            console.log(err);
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false;
                        })
                    }
                }
            });
        },

        next() {
            this.$refs.ruleForm1.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.checkPassword({
                        password: this.$md5(this.form1.password)
                    }).then(res => {
                        if (res.result) {
                            this.cleanCountDown();
                            this.isFirst = false;
                        } else {
                            this.passwordErr = this.$i18n.t('userInfo.text53');
                        }
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        this.isFirst = true;
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        },

        async getCode() {
            if (this.type === 0) {
                if (!this.$config.phoneRight(this.form.username)) {
                    this.phoneErr = this.$i18n.t('userInfo.text40');
                    return;
                }
                if (!this.form.username) {
                    this.phoneErr = this.$i18n.t('userInfo.text32');
                    return;
                }
            } else {
                if (!this.$config.emailRight(this.form.username)) {
                    this.emailErr = this.$i18n.t('userInfo.text41');
                    return;
                }
                if (!this.form.username) {
                    this.emailErr = this.$i18n.t('userInfo.text33');
                    return;
                }
            }

            let isRegistred = false;
            await this.isAllowRegistration({
                platformType: 2,
                userName: this.form.username,
                areaNumber: this.form.areaNumber
            }).catch(err => {
                isRegistred = true;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
            if (isRegistred) {
                return
            }

            this.phoneErr = ""
            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.form.username,
                type: this.type === 0 ? '4' : '9',
                areaNumber: this.form.areaNumber
            }).then(res => {
                this.form.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        getUserCode() {
            if (!this.form1.tel) {
                this.$dialog.showMessage(this.$i18n.t('userInfo.text54'), this.$config.TOAST_WARNING);
                return
            }
            this.codeBtnLoading = true;
            this.sendUserSms({
                type: '4'
            }).then(res => {
                this.form1.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + this.$i18n.t('userInfo.text42')
            this.codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + this.$i18n.t('userInfo.text42')
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = this.$i18n.t('userInfo.text43')
                    this.isCodeBtnDisabled = false;
                    clearInterval(this.codeTime);
                }
            }, 1000)
        },

        cleanCountDown() {
            if (this.codeTime) {
                clearInterval(this.codeTime);
            }
            this.time = 60;
            this.codeBtnLoading = false;
            this.codeBtnMsg = this.$i18n.t('userInfo.text37');
            this.isCodeBtnDisabled = false;
        }

    }

}
</script>

<style scoped>
/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

/deep/ .el-dialog__footer {
    text-align: right;
}


.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}
</style>
