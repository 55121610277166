import { render, staticRenderFns } from "./addMenuDialog.vue?vue&type=template&id=4f181b21&scoped=true"
import script from "./addMenuDialog.vue?vue&type=script&lang=js"
export * from "./addMenuDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f181b21",
  null
  
)

export default component.exports