var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step1"},[(_vm.activeNum === '0' && _vm.stepNum === '1')?_c('div',{staticClass:"next-btn"},[_c('span',{staticClass:"next-btn-label"},[_vm._v("开启配对码验证：")]),_c('el-switch',{staticStyle:{"width":"40px"},model:{value:(_vm.form.isOpenCode),callback:function ($$v) {_vm.$set(_vm.form, "isOpenCode", $$v)},expression:"form.isOpenCode"}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.activeNum === '0' && _vm.stepNum === '1' && _vm.form.isOpenCode) || _vm.activeNum !== '0'),expression:"(activeNum === '0' && stepNum === '1' && form.isOpenCode) || activeNum !== '0'"}],staticClass:"step-form"},[_c('el-form',{ref:"leftForm",staticClass:"left-form",attrs:{"size":"small","rules":_vm.rules,"model":_vm.form.zh,"label-width":"130px"}},[_c('el-form-item',{attrs:{"label":"页面名称:","prop":"pageName"}},[_c('span',{staticClass:"item-tip"},[_vm._v("简体中文")]),_c('el-input',{attrs:{"placeholder":"请输入页面名称"},on:{"blur":_vm.onFormChange},model:{value:(_vm.form.zh.pageName),callback:function ($$v) {_vm.$set(_vm.form.zh, "pageName", $$v)},expression:"form.zh.pageName"}})],1),_c('el-form-item',{attrs:{"label":"引导说明:","prop":"instructions"}},[_c('span',{staticClass:"item-tip"},[_vm._v("简体中文")]),_c('el-input',{attrs:{"type":"textarea","maxlength":"40","rows":"4","show-word-limit":"","placeholder":"请输入引导说明"},on:{"blur":_vm.onFormChange},model:{value:(_vm.form.zh.instructions),callback:function ($$v) {_vm.$set(_vm.form.zh, "instructions", $$v)},expression:"form.zh.instructions"}})],1),_c('el-form-item',{attrs:{"label":"引导图片:","prop":"file"}},[_c('span',{staticClass:"item-tip"},[_vm._v("简体中文")]),_c('el-upload',{attrs:{"list-type":"picture-card","action":_vm.$message.uploadImgUrl,"data":{
                           bucket: 'netConfig',
                           holdName: 'N' // 保持上传文件名不变
                        },"headers":{
                            accessToken: _vm.$token.getToken().accessToken,
                            refreshToken: _vm.$token.getToken().refreshToken,
                            language: _vm.language
                        },"accept":".png","limit":3,"on-success":(response, file, fileList) => _vm.onSuccess(response, file, fileList, true),"on-error":_vm.onError,"before-upload":(file) => _vm.onbeforeunload(file),"file-list":_vm.form.zh.file,"on-remove":(file, fileList) => _vm.handleRemove(file, fileList, true)}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.zh.file.length < 3),expression:"form.zh.file.length < 3"}],staticClass:"el-icon-plus",attrs:{"id":`picture-card-zh`}})])],1)],1),_c('el-form',{ref:"rightForm",staticClass:"right-form",attrs:{"size":"small","rules":_vm.rules,"model":_vm.form.en,"label-width":"0"}},[_c('el-form-item',{attrs:{"prop":"pageName"}},[_c('span',{staticClass:"item-tip"},[_vm._v("英语")]),_c('el-input',{attrs:{"placeholder":"Please enter the boot name"},on:{"blur":_vm.onFormChange},model:{value:(_vm.form.en.pageName),callback:function ($$v) {_vm.$set(_vm.form.en, "pageName", $$v)},expression:"form.en.pageName"}})],1),_c('el-form-item',{attrs:{"prop":"instructions"}},[_c('span',{staticClass:"item-tip"},[_vm._v("英语")]),_c('el-input',{attrs:{"type":"textarea","maxlength":"40","rows":"4","show-word-limit":"","placeholder":"Please enter the guide description"},on:{"blur":_vm.onFormChange},model:{value:(_vm.form.en.instructions),callback:function ($$v) {_vm.$set(_vm.form.en, "instructions", $$v)},expression:"form.en.instructions"}})],1),_c('el-form-item',{attrs:{"prop":"file"}},[_c('span',{staticClass:"item-tip"},[_vm._v("英语")]),_c('el-upload',{attrs:{"list-type":"picture-card","action":_vm.$message.uploadImgUrl,"data":{
                           bucket: 'netConfig',
                           holdName: 'N' // 保持上传文件名不变
                        },"headers":{
                            accessToken: _vm.$token.getToken().accessToken,
                            refreshToken: _vm.$token.getToken().refreshToken,
                            language: _vm.language
                        },"accept":".png","limit":3,"on-success":(response, file, fileList) => _vm.onSuccess(response, file, fileList, false),"on-error":_vm.onError,"before-upload":(file) => _vm.onbeforeunload(file),"file-list":_vm.form.en.file,"on-remove":(file, fileList) => _vm.handleRemove(file, fileList, false)}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.en.file.length < 3),expression:"form.en.file.length < 3"}],staticClass:"el-icon-plus",attrs:{"id":`picture-card-en`}})])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }