<template>
    <div>
        <el-form
            label-position="left"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="login-container"
        >
            <el-form-item prop="areaNumber">
                <el-select v-model="ruleForm.areaNumber" style="width: 100%;" :placeholder="$i18n.t('login.text5')">
                    <el-option
                        v-for="item in areaList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="username">
                <el-input
                    type="text"
                    ref="username"
                    v-model.trim="ruleForm.username"
                    auto-complete="off"
                    :placeholder="$i18n.t('login.text8') + usernameTitle">
                    <template slot="append" v-if="isTelLogin">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item prop="code" v-if="isTelLogin">
                <el-input
                    v-model.trim="ruleForm.code"
                    :placeholder="$i18n.t('login.text9')">
                </el-input>
            </el-form-item>
            <el-form-item prop="password" v-else>
                <el-input
                    :type="isShowPwd ? 'text' : 'password'"
                    v-model.trim="ruleForm.password"
                    auto-complete="off"
                    :placeholder="$i18n.t('login.text10')">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd = !isShowPwd"></i>
                </el-input>

            </el-form-item>

            <el-form-item style="margin-bottom:10px;">
                <el-row style="margin-top: -10px;">
                    <el-col :span="12" style="text-align: left;">
                        <el-button type="text" @click="showReset">{{$i18n.t('login.text11')}}</el-button>
                    </el-col>
                    <el-col :span="12" style="text-align: right;">
                        <el-button type="text" @click="isTelLogin = !isTelLogin">{{ !isTelLogin ? ($i18n.t('login.text34')) : $i18n.t('login.text12') }}
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-checkbox class="remember" v-model="rememberpwd">
                    <span>
                        {{$i18n.t('login.text13')}}
                        <el-link type="primary" href="#/protocol?type=fwxy" target="_blank">{{$i18n.t('login.text14')}}</el-link>
                        {{$i18n.t('login.text16')}}
                        <el-link type="primary" href="#/protocol?type=ysxy" target="_blank">{{$i18n.t('login.text15')}}</el-link>
                    </span>
                </el-checkbox>
            </el-form-item>
            <el-form-item style="width: 100%">
                <el-button
                    type="primary"
                    style="width: 100%;margin-top: 10px;"
                    :loading="logining"
                    @click="login()"
                >
                    {{$i18n.t('login.text3')}}
                </el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['menuData'])
    },

    watch: {
        //菜单列表
        menuData: {
            handler(newVal) {
                setTimeout(() => {
                    this.logining = false;
                    //判断是否有菜单权限
                    let menu = newVal.homeMenuList.concat(newVal.settingMenuList)
                    let url = this.$route.query.url
                    if (url) {
                        window.location.href = url;
                    } else {
                        this.$router.push({path: menu.length === 0 ? "/403" : "/main"});
                    }
                })
            },
            deep: true
        },

        systemConfig: {
            handler(newVal) {
                this.usernameTitle =
                    ` ${(newVal.supportTelephone ? this.$i18n.t('login.text6') : '') +
                    (newVal.supportTelephone && newVal.supportEmail ? '/' : '') +
                    (newVal.supportEmail ? this.$i18n.t('login.text7') : '')}`
            },
            deep: true
        },

        areaNumberList: {
            handler(newVal) {
                this.areaList = newVal;
                this.ruleForm.areaNumber = this.areaList[0].value
                console.log(this.areaList[0].value)
            },
            deep: true
        }
    },

    props: {
        dataCenters: {
            Type: String,
            default: "CN"
        },
        language: {
            Type: String,
            default: "zh"
        },
        systemConfig: {
            Type: Object,
            default: () => ({
                "supportTelephone": true,
                "supportWechat": false,
                "supportMicroApp": false,
                "supportApple": false,
                "supportEmail": false,
                "supportGzh": false
            })
        },
        areaNumberList: {
            Type: Array,
            default: []
        }
    },

    data() {

        const validateEmailOrPhone = (rule, value, callback) => {
            const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
            const phoneRegex = /^1[0-9]{10}$/;
            if (this.systemConfig.supportTelephone && this.systemConfig.supportEmail) {
                if (!value) {
                    callback(new Error(this.$i18n.t('login.text17')));
                    return;
                } else if (!emailRegex.test(value) && !phoneRegex.test(value)) {
                    callback(new Error(this.$i18n.t('login.text18')));
                    return;
                }
            } else if (this.systemConfig.supportTelephone) {
                if (!value) {
                    callback(new Error(this.$i18n.t('login.text19')));
                    return;
                } else if (!phoneRegex.test(value)) {
                    callback(new Error(this.$i18n.t('login.text20')));
                    return;
                }
            } else if (this.systemConfig.supportEmail) {
                if (!value) {
                    callback(new Error(this.$i18n.t('login.text21')));
                    return;
                } else if (!emailRegex.test(value)) {
                    callback(new Error(this.$i18n.t('login.text22')));
                    return;
                }
            }
            callback();
        };

        return {

            isTelLogin: false,

            //定义loading默认为false
            logining: false,

            rememberpwd: false,

            codeBtnLoading: false,

            ruleForm: {
                //username和password默认为空
                username: "",
                password: "",
                code: "",
                smsId: "",
                areaNumber: ""
            },

            isShowPwd: false,

            time: 60,

            isCodeBtnDisabled: false,

            codeBtnMsg: this.$i18n.t('login.text23'),

            //rules前端验证
            rules: {
                username: [
                    {required: true, validator: validateEmailOrPhone }
                ],
                password: [{required: true, message: this.$i18n.t('login.text24'), trigger: "blur"}],
                code: [{required: true, message: this.$i18n.t('login.text25'), trigger: "blur"}],
                areaNumber: [{required: true, message: this.$i18n.t('login.text5'), trigger: "blur"}]
            },

            usernameTitle: '',

            areaList: []

        }

    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.username.focus();
        })
    },

    methods: {

        ...mapActions('login', ['getUserAuthorityTree', 'sendSms']),

        /**
         * 清空表单
         */
        initForm() {
            this.$refs.ruleForm.resetFields();
            this.ruleForm.areaNumber = this.areaList[0].value
        },

        /**
         * 打开重置密码弹窗
         */
        showReset() {
            this.$store.commit("login/SET_RESET_PASSWORD_DIALOG_VISIBLE", true);
        },

        login() {
            let isStart = true;
            console.log(this.ruleForm)
            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    if (!this.rememberpwd) {
                        this.$dialog.showMessage(this.$i18n.t('login.text26'), this.$config.TOAST_WARNING);
                        return
                    }
                    this.logining = true
                    this.$store.dispatch("login/login", {
                        password: this.isTelLogin ? this.ruleForm.code : this.$md5(this.ruleForm.password),
                        platformType: "2",
                        tid: "2",
                        smsId: this.ruleForm.smsId,
                        type: this.isTelLogin ? '2' : '1',
                        userName: this.ruleForm.username,
                        vcode: this.ruleForm.code,
                        areaNumber: this.ruleForm.areaNumber
                    }).then(res => {
                        console.log(res);
                        this.$token.setToken(res.result.accessToken, res.result.refreshToken);
                        this.getUserAuthorityTree();
                    }, err => {
                        this.logining = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        },

        /**
         * 获取验证码
         */
        getCode() {
            const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
            const phoneRegex = /^1[0-9]{10}$/;
            if (this.systemConfig.supportTelephone && this.systemConfig.supportEmail) {
                if (!this.ruleForm.username) {
                    return;
                } else if (!emailRegex.test(this.ruleForm.username) && !phoneRegex.test(this.ruleForm.username)) {
                    return;
                }
            } else if (this.systemConfig.supportTelephone) {
                if (!this.ruleForm.username) {
                    return;
                } else if (!phoneRegex.test(this.ruleForm.username)) {
                    return;
                }
            } else if (this.systemConfig.supportEmail) {
                if (!this.ruleForm.username) {
                    return;
                } else if (!emailRegex.test(this.ruleForm.username)) {
                    return;
                }
            }

            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.ruleForm.username,
                type: '2',
                areaNumber: this.ruleForm.areaNumber
            }).then(res => {
                this.ruleForm.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        /**
         * 获取验证码后设置倒计时
         */
        countDown() {
            this.isCodeBtnDisabled = true;
            let language = window.localStorage.getItem('language')
            this.codeBtnMsg = language === 'zh' ? (this.time + "s" + this.$i18n.t('login.text27')) : (this.$i18n.t('login.text27') + this.time + "s")
            let codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = language === 'zh' ? (this.time + "s" + this.$i18n.t('login.text27')) : (this.$i18n.t('login.text27') + this.time + "s")
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = this.$i18n.t('login.text28')
                    this.isCodeBtnDisabled = false;
                    clearInterval(codeTime);
                }
            }, 1000)
        }

    }

}
</script>

<style scoped>
.login-container {
    width: 80%;
    margin: 10% auto;
}

.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}

.text-link {
    color: #1C204F;
}

.remember {
    float: left;
    margin-bottom: -23px;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}

/deep/ .el-checkbox__label {
    display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left;
}
</style>
