<template>
    <div style="overflow-y: hidden;">
        <div class="page_title">
            {{$i18n.t('dashboard.text26')}}
        </div>

        <div class="table_card">
            <div class="header">
                <span class="title">
                    {{$i18n.t('dashboard.text27')}}
                    <el-popover
                        placement="right"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="$i18n.t('dashboard.text28')">

                        <i
                            :class="contentIcon"
                            @mouseover="contentIcon = 'question_coicon icon-yuanyin'"
                            @mouseleave="contentIcon = 'question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>

                    </el-popover>
                </span>

                <el-form :inline="true" size="small" class="demo-form-inline">

                    <el-form-item :label="$i18n.t('dashboard.text29')">
                        <el-cascader
                            clearable
                            @change="getMsg(1)"
                            :show-all-levels="false"
                            :placeholder="$i18n.t('dashboard.text30')"
                            v-model="productForm.productType"
                            :options="productTypeList"
                            :props="props">
                        </el-cascader>
                    </el-form-item>

                    <el-form-item>
                        <el-date-picker
                            style="width: 145px"
                            @change="getMsg(1)"
                            :clearable="false"
                            v-model="productForm.day"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            :placeholder="$i18n.t('dashboard.text31')">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-input :placeholder="$i18n.t('dashboard.text32')" v-model="productForm.deviceId" size="small" style="width: 330px;">
                            <el-button slot="append" type="text" icon="el-icon-search" @click="getMsg(1)"></el-button>
                        </el-input>
                    </el-form-item>

                </el-form>
            </div>



            <el-table
                ref="factoryTestTable"
                class="factoryTestTable"
                :data="deviceStatisticsMsg.list"
                :height="deviceStatisticsMsg.list.length === 0 ? tableHeight : ''"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                @sort-change="sortChange"
                style="width: 100%;">

                <el-table-column
                    width="200"
                    :label="$i18n.t('dashboard.text29')">
                    <template slot-scope="scope">
                        {{language === 'zh' ? JSON.parse(scope.row.categoryName).zh : JSON.parse(scope.row.categoryName).en}}
                    </template>
                </el-table-column>

                <el-table-column
                    width="400"
                    prop="deviceId"
                    :label="$i18n.t('dashboard.text33')">
                </el-table-column>

                <el-table-column
                    width="300"
                    sortable="custom"
                    prop="online_times"
                    :label="$i18n.t('dashboard.text34')">
                    <template slot-scope="scope">
                        {{scope.row.onlineTimes ? scope.row.onlineTimes : 0}}
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="300">
                    <template slot="header" slot-scope="scope">
                        <div style="display: flex;flex-direction: row;align-items: center;">

                            <div style="margin-right: 4px;">{{$i18n.t('dashboard.text35')}}</div>

                            <el-popover
                                placement="right"
                                title=""
                                width="200"
                                trigger="hover"
                                :content="$i18n.t('dashboard.text36')">

                                <i
                                    :class="tableIcon"
                                    @mouseover="tableIcon = 'question_coicon icon-yuanyin'"
                                    @mouseleave="tableIcon = 'question_alicon iconfont_al icon-yiwen'"
                                    slot="reference"></i>

                            </el-popover>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <span :style="{color: scope.row.onlineTimes >= 50 ? 'rgb(255, 40, 37)' : '#16C60C'}">
                            {{scope.row.onlineTimes >= 50 ? $i18n.t('dashboard.text37') : $i18n.t('dashboard.text38')}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column
                    fixed="right"
                    :label="$i18n.t('dashboard.text16')"
                    width="120">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="toLog(scope.row)"
                            size="small">
                            {{$i18n.t('dashboard.text17')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>



            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(deviceStatisticsMsg.totalRecords)">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {

    name: "deviceStatistics",

    data()  {
        return {
            contentIcon: "question_alicon iconfont_al icon-yiwen",

            tableIcon: "question_alicon iconfont_al icon-yiwen",

            page: 1,

            //查询表单
            productForm: {
                productType: "",
                deviceId: "",
                day: ""
            },

            //品类数据
            productTypeList: [],


            //级联选择器配置选项
            props: {
                //开启懒加载
                lazy: true,
                //指定选项的值为选项对象的某个属性值
                value: "code",
                //指定选项标签为选项对象的某个属性值
                label: "name",
                //指定选项的子选项为选项对象的某个属性值
                children: "children",
                //加载动态数据的方法
                //node为当前点击的节点，resolve为数据加载完成的回调(必须调用)
                lazyLoad: (node, resolve) => {
                    console.log(node);
                    if (!node.value) {
                        //获取一级品类
                        this.getProtocolTypeList();
                        return
                    }
                    this.getLevelProtocolTypeList(node.value, resolve)
                }
            },

            deviceStatisticsMsg: {
                list: [],
                totalRecords: 10
            },

            tableHeight: 50,

            loading: false,

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            language: localStorage.getItem('language')

        }
    },

    mounted() {
        setTimeout(() => {
            this.tableHeight = (window.innerHeight - 280);
        }, 100);
        window.addEventListener('resize', this.setHeight);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        this.productForm.day = `${year}-${month < 10 ? ('0' + month) : month}-${day < 10 ? ('0' + day) : day}`;
        this.getMsg();
    },

    methods: {

        ...mapActions('category', ['queryFirstLevelCategory', 'queryChildsCategory']),
        ...mapActions('onlineStatistics', ['queryOnlineStatisticsByDay']),

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = (window.innerHeight - 280);
        },

        toLog(row) {
            this.$router.push({
                path: "/deviceLog",
                query: {
                    deviceId: row.deviceId
                }
            })
        },

        getMsg(page, orderBy, isAsc) {
            console.log(this.productForm)
            this.loading = true;
            if (page) this.page = page;
            let data = {
                currPage: this.page,
                pageSize: 10,
                deviceId: this.productForm.deviceId,
                theDate: this.productForm.day,
                categoryCode: this.productForm.productType[2]
            }
            if (orderBy && isAsc) data.orderBy = orderBy;
            if (isAsc) data.isAsc = isAsc === "ascending";
            this.queryOnlineStatisticsByDay(data).then(res => {
                this.deviceStatisticsMsg.list = res.result.list;
                this.deviceStatisticsMsg.totalRecords = res.result.totalRecords;
                console.log(this.deviceStatisticsMsg.totalRecords)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.loading = false)
        },

        /**
         * 获取产品类型列表
         */
        getProtocolTypeList() {
            this.queryFirstLevelCategory().then(res => {
                if (res.result.length == 0) return
                res.result.forEach(item => {
                    item.name = this.language === 'zh' ? JSON.parse(item.name).zh : JSON.parse(item.name).en;
                    item.leaf = false;
                })
                this.productTypeList = res.result
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },


        /**
         * 获取下级产品类型列表
         * @param code
         * @param resolve
         */
        getLevelProtocolTypeList(code, resolve) {
            this.queryChildsCategory({
                code
            }).then(res => {
                res.result.forEach(item => {
                    item.name = this.language === 'zh' ? JSON.parse(item.name).zh : JSON.parse(item.name).en;
                    if (item.level == 2) {
                        item.children = [];
                        item.leaf = false;
                    }
                    if (item.level == 3) {
                        item.leaf = true;
                    }
                })
                console.log(res.result)
                resolve(res.result)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },



        sortChange({ prop, order }) {
            console.log(prop, order);
            this.getMsg(1, prop, order);
        },


        handleCurrentChange(page) {
            this.getMsg(page);
        }
    }

}
</script>

<style scoped>


.table_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 10px 24px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 20px 0;
}

.question_alicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
}


.question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.el-form-item {
    margin-bottom: 0!important;
}
</style>
