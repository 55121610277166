<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            测试
        </div>
        <!-- 标题 end -->

        <!-- 头部固件详情 start -->
        <div class="firmware_msg">
            <div class="firmware_msg_list">
                <div class="firmware_msg_top">
                    <div class="firmware_name">固件信息</div>
                </div>
                <div class="firmware_msg_bottom">
                    <div class="firmware_msg_item"><span class="firmware_msg_item_title">固件版本：</span>{{ firmwareData.firmwareVersion}}</div>
                    <div class="firmware_msg_item"><span class="firmware_msg_item_title">创建时间：</span>{{ firmwareData.createTime }}</div>
                    <div class="firmware_msg_item"><span class="firmware_msg_item_title">更新日志：</span>{{ firmwareData.firmwareLog }}</div>
                </div>
            </div>
        </div>
        <!-- 头部固件详情 end -->


        <!-- 固件升级用户列表卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '0',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <el-form :inline="true" size="small" :model="form">
                <el-form-item>
                    <el-input v-model.trim="form.keyword" clearable placeholder="手机号/设备ID" @clear="getMsg(1)"
                              @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.upgradeState" clearable placeholder="全部">
                        <el-option
                            v-for="item in $message.upgradeStateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="$store.commit('maintain/SET_ADD_WHITE_LIST_DIALOG_VISIBLE', true)">
                        白名单用户
                    </el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" plain @click="$store.commit('maintain/SET_ADD_FIRMWARE_TEST_DIALOG_VISIBLE', true)">
                        新增测试
                    </el-button>
                </el-form-item>
            </el-form>


            <el-table
                ref="logTable"
                class="log_table"
                :data="upgradeUsersMsg.list"
                :height="cardHeight - 75 - 16"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">

                <el-table-column
                    label="升级用户">
                </el-table-column>

                <el-table-column
                    label="用户ID">
                </el-table-column>

                <el-table-column
                    label="设备ID">
                </el-table-column>

                <el-table-column
                    label="状态">
                </el-table-column>

            </el-table>

            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(upgradeUsersMsg.totalRecords)">
            </el-pagination>

        </el-card>
        <!-- 固件升级用户列表卡片 end -->

        <!-- 新增测试弹窗 -->
        <add-firmware-test-dialog></add-firmware-test-dialog>

        <!-- 用户白名单弹窗 -->
        <add-white-list-dialog></add-white-list-dialog>

    </div>
</template>

<script>
import AddFirmwareTestDialog from "@/views/maintain/components/dialog/addFirmwareTestDialog";
import AddWhiteListDialog from "@/views/maintain/components/dialog/addWhiteListDialog";

export default {

    name: "firmwareTest",

    components: {AddWhiteListDialog, AddFirmwareTestDialog},

    data() {

        return {

            loading: false,

            firmwareData: {
                //固件版本
                firmwareVersion: "nkjansfjnasjkf",
                //创建时间
                createTime: "2022-1-1  12:00",
                //更新日志
                firmwareLog: "修复BUG"
            },

            upgradeUsersMsg: {
                list: [],
                totalRecords: 10
            },

            cardHeight: 300,

            form: {
                keyword: "",
                upgradeState: ""
            }

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
    },

    methods: {

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 300;
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        getMsg(page) {

        },

        handleCurrentChange(page) {

        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    }

}
</script>

<style scoped>
.firmware_msg {
    height: 92px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 24px 24px;
}

.firmware_msg_list {
    height: 72px;
    margin: 10px 20px;
    float: left;
    text-align: left;
}

.firmware_msg_top {
    height: 22px;
    padding: 7px 0;
}

.firmware_name {
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    float: left;
    line-height: 22px;
}

.firmware_msg_bottom {
    height: 22px;
    margin-top: 10px;
}

.firmware_msg_item {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.65);
    margin-left: 40px;
}

.firmware_msg_item:first-child {
    margin-left: 0;
}

.firmware_msg_item_title {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
}

.el-select {
    width: 100px;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 0 24px;
    padding: 16px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .el-form--inline .el-form-item {
    margin-right: 16px!important;
}
</style>
