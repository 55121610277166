<template>
    <div class="step">
        <div v-for="(item,index) in stepList" :key="index" :class="index == 0?'step_item_first':'step_item'">
            <div :class="index <= selectId?'dashed':'dashed_off'" v-show="index != 0"></div>
            <div class="step_title">
                <div :class="index <= selectId?'number':'number_off'" @click="toMenu(item.id,index)">
                    <i class="el-icon-check" v-if="index < selectId"></i>
                    <span v-else>{{ index + 1 }}</span>
                </div>
                <div :class="index <= selectId?'title':'title_off'" @click="toMenu(item.id,index)">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                if (newVal.isRefresh) {
                    this.toMenu(this.stepList[newVal.progress - 1].id)
                    this.selectId = (newVal.progress - 1)
                }
            },
            deep: true
        }

    },

    data() {

        return {

            selectUrl: this.$route.path,

            selectId: 0,

            stepList: [
                {id: "/product/basic", name: "基础配置"},
                {id: "/product/effect", name: "功能定义"},
                {id: "/product/panel", name: "面板开发"},
                {id: "/product/firmware", name: "固件开发"},
                {id: "/product/release", name: "产品发布"},
            ]

        }

    },

    mounted() {
        this.initSelectId()
    },

    methods: {

        toMenu(url, index) {
            if (this.$route.path === url) return;
            this.selectUrl = url;
            this.selectId = index;
            this.$router.push({path: url, query: {id: this.$route.query.id}})
        },

        initSelectId() {
            if (this.selectUrl === '/product/panelSetting') {
                this.selectId = 2;
                return;
            }
            for (let i = 0; i < this.stepList.length; i++) {
                if (this.selectUrl === this.stepList[i].id) {
                    this.selectId = i;
                    return;
                }
            }
        }

    }

}
</script>

<style scoped>
.step {
    width: 100%;
    height: 100%;
    padding: 20px 20px 20px 40px;
}

.step_item {
    width: calc(100% - 40px);
    height: calc(((100% - 160px) / 4));
}

.step_item_first {
    width: calc(100% - 40px);
    height: 60px;
}

.dashed {
    height: calc(100% - 60px);
    border-left: 3px dashed #1c204f;
    width: 0;
    margin-left: 28px;
}

.dashed_off {
    height: calc(100% - 60px);
    border-left: 3px dashed #c1c1c1;
    width: 0;
    margin-left: 28px;
}

.step_title {
    width: 100%;
    height: 60px;
    line-height: 60px;
}

.number_off {
    width: 60px;
    height: 60px;
    border: 1px solid #1c204f;
    background-color: #ffffff;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    color: #888888;
    font-size: 20px;
    float: left;
    cursor: pointer;
}

.number {
    width: 60px;
    height: 60px;
    background-color: #1c204f;
    border: 1px solid #1c204f;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    color: white;
    font-size: 20px;
    float: left;
    cursor: pointer;
}

.title_off {
    height: 60px;
    float: left;
    margin-left: 20px;
    cursor: pointer;
}

.title {
    height: 60px;
    float: left;
    margin-left: 20px;
    cursor: pointer;
    font-weight: bold;
}
</style>
