<template>
    <div>
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="$router.back()"></i>
            {{$i18n.t('router.text18')}}
        </div>

        <div class="ota_main">

            <!-- 头部产品详情 start -->
            <div class="product_msg" v-if="!!productData.pid">
                <div>
                    <el-image :src="productData.productImageUrl" class="product_img">
                        <div slot="error">
                            <div class="image_slot">
                                {{$i18n.t('debug.text3')}}
                            </div>
                        </div>
                    </el-image>
                    <div class="product_msg_list">
                        <div class="product_msg_top">
                            <div class="product_name">{{ productData.productName }}</div>
                        </div>
                        <div class="product_msg_bottom">
                            <div class="product_msg_item">
                                <span class="product_msg_item_title">PID：</span>{{ productData.pid }}</div>
                            <div class="product_msg_item"><span class="product_msg_item_title">{{$i18n.t('product.text3')}}</span>{{ productData.model }}</div>
                            <div class="product_msg_item"><span class="product_msg_item_title">{{$i18n.t('product.text4')}}</span>{{ productData.categoryName }}</div>
                            <div class="product_msg_item">
                                <span class="product_msg_item_title">{{$i18n.t('product.text5')}}</span> {{
                                    $config.getMsgItemUtil($message.protocolTypeList, productData.communication, 'id', 'name')
                                }}
                            </div>
                            <div class="product_msg_item" :title="$i18n.t('product.text6') + productData.remarks"><span class="product_msg_item_title">{{$i18n.t('product.text6')}}</span> {{ productData.remarks }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product_msg" v-else>
                <el-empty :description="$i18n.t('debug.text6')" :image-size="40"></el-empty>
            </div>
            <!-- 头部产品详情 end -->

            <el-divider></el-divider>

            <div class="header">
                <el-tabs v-model="activeName" class="main" @tab-click="handleClick">
                    <el-tab-pane v-for="(item, index) in netConfigList" :key="index" :label="item.name" :name="item.value"></el-tab-pane>
                </el-tabs>
                <div class="header-right">
                    <el-popover
                        placement="bottom"
                        trigger="click">
                        <div style="font-size: 16px;">自定义配网方式</div>
                        <el-checkbox-group style="display: flex;flex-direction: column;" v-model="checkNetConfigList">
                            <el-checkbox style="margin-top: 20px;" :label="item.value" v-for="(item, index) in netConfigList" :key="index">{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                        <div
                            slot="reference"
                            @mouseover="isModuleMouse = true"
                            @mouseleave="isModuleMouse = false"
                            class="module-btn">
                            <img src="@/assets/icon_module_off.png" style="width: 16px;height: 16px;" v-show="!isModuleMouse"/>
                            <img src="@/assets/icon_module_on.png" style="width: 16px;height: 16px;" v-show="isModuleMouse"/>
                            <span style="margin-left: 4px;font-size: 14px;">自定义配网方式</span>
                        </div>
                    </el-popover>
                    <div
                        class="sort-btn"
                        @mouseover="isSortMouse = true"
                        @mouseleave="isSortMouse = false" >
                        <img src="@/assets/icon_sort_off.png" style="width: 16px;height: 16px;" v-show="!isSortMouse"/>
                        <img src="@/assets/icon_sort_on.png" style="width: 16px;height: 16px;" v-show="isSortMouse"/>
                        <span style="margin-left: 4px;font-size: 14px;">排序</span>
                    </div>
                </div>
            </div>

            <div class="net-config-body">

                <div class="body-left">

                    <el-radio-group @input="radioChange" v-model="step" size="mini">
                        <el-radio-button label="0">{{netConfigMsg[Number(activeName)].step0.name}}</el-radio-button>
                        <el-radio-button label="1">{{netConfigMsg[Number(activeName)].step1.name}}</el-radio-button>
                        <el-radio-button label="2">{{netConfigMsg[Number(activeName)].step2.name}}</el-radio-button>
                    </el-radio-group>

                    <operation-guide v-show="step === '0'" @change="onOperationGuide" :activeName="activeName" :formValue="JSON.stringify(netConfigMsg[Number(activeName)].step0)"/>

                    <net-setting
                        v-show="((activeName === '0' || activeName === '1') && step === '2') || (activeName === '2' && step === '1')"
                        :activeName="activeName"
                        :step="step"
                        @change="onNetSetting"
                        :formValue="
                            ((activeName === '0' || activeName === '1') && step === '2') ? JSON.stringify(netConfigMsg[Number(activeName)].step2) :
                            (activeName === '2' && step === '1') ? JSON.stringify(netConfigMsg[Number(activeName)].step1) : ''
                        "/>

                    <pairing-code
                        @change="onPairingCode"
                        :activeName="activeName"
                        :step="step"
                        v-show="((activeName === '0' || activeName === '1') && step === '1') || (activeName === '2' && step === '2')"
                        :formValue="
                            ((activeName === '0' || activeName === '1') && step === '1') ? JSON.stringify(netConfigMsg[Number(activeName)].step1) :
                            (activeName === '2' && step === '2') ? JSON.stringify(netConfigMsg[Number(activeName)].step2) : ''
                        "/>

                </div>

                <div class="body-right">

                    <el-radio-group v-model="languageDemo" size="mini">
                        <el-radio-button label="zh">中文</el-radio-button>
                        <el-radio-button label="en">英文</el-radio-button>
                    </el-radio-group>

                    <div class="phone-demo" v-if="step === '0'">
                        <div class="step0">
                            <span class="page-name">{{netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].pageName ? netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].pageName : (languageDemo === 'zh' ? '请输入页面名称' : 'Please enter the page name')}}</span>
                            <div class="page-body">
                                <span class="subtitle">{{ netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].subtitle ? netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].subtitle : (languageDemo === 'zh' ? '请输入引导标题' : 'Please enter the guide title') }}</span>
                                <span class="instructions">{{ netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].instructions ? netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].instructions : (languageDemo === 'zh' ? '请输入引导说明' : 'Please enter the guide description') }}</span>
                                <el-carousel
                                    height="100px"
                                    :indicator-position="netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].file.length === 1 ? 'none' : ''"
                                    :arrow="netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].file.length === 1 ? 'never' : 'hover'"
                                    :autoplay="false" v-if="netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].file.length > 0">
                                    <el-carousel-item v-for="(image, i) in netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].file" :key="i">
                                        <el-image :src="image.url" class="guide_img">
                                            <div slot="error" class="image-slot">
                                                {{languageDemo === 'zh' ? '加载错误' : 'Loading error'}}
                                            </div>
                                        </el-image>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-image class="guide_img" v-else>
                                    <div slot="error" class="image-slot">
                                        {{languageDemo === 'zh' ? '暂无图片预览' : 'No image preview available'}}
                                    </div>
                                </el-image>
                            </div>
                            <div class="phone-footer">
                                <el-checkbox v-model="netConfigMsg[Number(activeName)].step0.list[step0Index].isSelectRadio" v-show="netConfigMsg[Number(activeName)].step0.list[step0Index].isShowRadio">{{netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].radioText ? netConfigMsg[Number(activeName)].step0.list[step0Index][languageDemo].radioText : (languageDemo === 'zh' ? '请输入单选文案' : 'single-choice text')}}</el-checkbox>
                                <div class="phone-footer-btn" v-show="netConfigMsg[Number(activeName)].step0.list[step0Index].isShowNextBtn" v-if="(step0Index + 1) < netConfigMsg[Number(activeName)].step0.list.length && (step0Index + 1) > 1">
                                    <el-button size="small" style="flex: 1;" @click="previous">{{ languageDemo === 'zh' ? '上一步' : 'Previous' }}</el-button>
                                    <el-button size="small" type="primary" style="flex: 1;" @click="next">{{ languageDemo === 'zh' ? '下一步' : 'Next' }}</el-button>
                                </div>
                                <div class="phone-footer-btn" v-show="netConfigMsg[Number(activeName)].step0.list[step0Index].isShowNextBtn" v-else-if="(step0Index + 1) === 1">
                                    <el-button size="small" type="primary" style="flex: 1;width: 150px;" @click="next">{{ languageDemo === 'zh' ? '下一步' : 'Next' }}</el-button>
                                </div>
                                <div class="phone-footer-btn" v-show="netConfigMsg[Number(activeName)].step0.list[step0Index].isShowNextBtn" v-else>
                                    <el-button size="small" style="flex: 1;width: 150px;" @click="previous">{{ languageDemo === 'zh' ? '上一步' : 'Previous' }}</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="phone-demo" v-if="activeName === '0' && step === '1'">
                        <div class="step0">
                            <span class="page-name">{{netConfigMsg[Number(activeName)].step1[languageDemo].pageName ? netConfigMsg[Number(activeName)].step1[languageDemo].pageName : (languageDemo === 'zh' ? '请输入页面名称' : 'Please enter the page name')}}</span>
                            <div class="page-body">
                                <span class="subtitle">{{ languageDemo === 'zh' ? '验证配对码' : 'Verify pairing code' }}</span>
                                <span class="instructions">{{ netConfigMsg[Number(activeName)].step1[languageDemo].instructions ? netConfigMsg[Number(activeName)].step1[languageDemo].instructions : (languageDemo === 'zh' ? '请输入引导说明' : 'Please enter the guide description') }}</span>
                                <span class="instructions">
                                    {{ languageDemo === 'zh' ? '请输入6位配对码' : 'Please enter the 6-digit pairing code' }}  <span style="color: rgb(24, 144, 255);">30s</span>
                                </span>
                                <div class="phone-code">
                                    <div class="phone-code-item" v-for="item in 6" :key="item">
                                        <span class="phone-code-item-text">8</span>
                                    </div>
                                </div>
                                <el-carousel
                                    height="100px"
                                    :indicator-position="netConfigMsg[Number(activeName)].step1[languageDemo].file.length === 1 ? 'none' : ''"
                                    :arrow="netConfigMsg[Number(activeName)].step1[languageDemo].file.length === 1 ? 'never' : 'hover'"
                                    :autoplay="false" v-if="netConfigMsg[Number(activeName)].step1[languageDemo].file.length > 0">
                                    <el-carousel-item v-for="(image, i) in netConfigMsg[Number(activeName)].step1[languageDemo].file" :key="i">
                                        <el-image :src="image.url" class="guide_img">
                                            <div slot="error" class="image-slot">
                                                {{languageDemo === 'zh' ? '加载错误' : 'Loading error'}}
                                            </div>
                                        </el-image>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-image class="guide_img" v-else>
                                    <div slot="error" class="image-slot">
                                        {{languageDemo === 'zh' ? '暂无图片预览' : 'No image preview available'}}
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </div>

                    <div class="phone-demo" v-if="((activeName === '0' || activeName === '1') && step === '2') || (activeName === '2' && step === '1')">
                        <div class="step0">
                            <span class="page-name">{{ languageDemo === 'zh' ? '网络设置' : 'Network Settings' }}</span>
                            <div class="page-body">
                                <span class="subtitle">{{ languageDemo === 'zh' ? '选择2.4Ghz Wi-Fi网络并输入密码' : 'Select a 2.4GHz Wi-Fi network and enter the password' }}</span>
                                <span class="instructions">{{ languageDemo === 'zh' ? '目前仅支持2.4G网络' : 'Currently, only 2.4GHz networks are supported' }}</span>
                                <div class="input-top">
                                    <span class="input-placeholder">{{ languageDemo === 'zh' ? 'WiFi账号' : 'WiFi Account' }}</span>
                                    <i class="el-icon-arrow-down" style="color: rgba(0, 0, 0, 0.4);margin-right: 6px;"></i>
                                </div>
                                <div class="input-bottom">
                                    <span class="input-placeholder">{{ languageDemo === 'zh' ? 'WiFi密码' : 'WiFi Password' }}</span>
                                </div>
                            </div>

                            <div class="phone-footer">
                                <el-checkbox
                                    style="width: 142px;margin-left: 22px;"
                                    v-show="((activeName === '0' || activeName === '1') && step === '2') ?
                                    netConfigMsg[Number(activeName)].step2.isShowRadio : netConfigMsg[Number(activeName)].step1.isShowRadio"
                                    v-model="((activeName === '0' || activeName === '1') && step === '2') ?
                                     netConfigMsg[Number(activeName)].step2.isSelectRadio : netConfigMsg[Number(activeName)].step1.isSelectRadio">
                                    {{ languageDemo === 'zh' ? '确认WiFi账号和密码正确' : 'Confirm that the WiFi account and password are correct' }}
                                </el-checkbox>
                                <div class="phone-footer-btn" v-if="activeName === '0' && netConfigMsg[Number(activeName)].step2.isOpenSkip">
                                    <el-button size="small" style="flex: 1;">{{ languageDemo === 'zh' ? '跳过' : 'Skip' }}</el-button>
                                    <el-button size="small" type="primary" style="flex: 1;">{{ languageDemo === 'zh' ? '下一步' : 'Next' }}</el-button>
                                </div>
                                <div class="phone-footer-btn" v-else>
                                    <el-button size="small" type="primary" style="flex: 1;width: 150px;" @click="next">{{ languageDemo === 'zh' ? '下一步' : 'Next' }}</el-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="phone-demo" v-if="activeName === '1' && step === '1'">
                        <div class="step0">
                            <span class="page-name">{{netConfigMsg[Number(activeName)].step1[languageDemo].pageName ? netConfigMsg[Number(activeName)].step1[languageDemo].pageName : (languageDemo === 'zh' ? '请输入页面名称' : 'Please enter the page name')}}</span>
                            <div class="page-body">
                                <span class="instructions">{{ netConfigMsg[Number(activeName)].step1[languageDemo].instructions ? netConfigMsg[Number(activeName)].step1[languageDemo].instructions : (languageDemo === 'zh' ? '请输入引导说明' : 'Please enter the guide description') }}</span>
                                <el-carousel
                                    height="100px"
                                    :indicator-position="netConfigMsg[Number(activeName)].step1[languageDemo].file.length === 1 ? 'none' : ''"
                                    :arrow="netConfigMsg[Number(activeName)].step1[languageDemo].file.length === 1 ? 'never' : 'hover'"
                                    :autoplay="false" v-if="netConfigMsg[Number(activeName)].step1[languageDemo].file.length > 0">
                                    <el-carousel-item v-for="(image, i) in netConfigMsg[Number(activeName)].step1[languageDemo].file" :key="i">
                                        <el-image :src="image.url" class="guide_img">
                                            <div slot="error" class="image-slot">
                                                {{languageDemo === 'zh' ? '加载错误' : 'Loading error'}}
                                            </div>
                                        </el-image>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-image class="guide_img" v-else>
                                    <div slot="error" class="image-slot">
                                        {{languageDemo === 'zh' ? '暂无图片预览' : 'No image preview available'}}
                                    </div>
                                </el-image>
                            </div>
                            <div class="phone-footer">
                                <div class="phone-footer-btn">
                                    <el-button size="small" type="primary" style="flex: 1;width: 150px;">{{ languageDemo === 'zh' ? '重连设备WiFi' : 'Reconnect device WiFi' }}</el-button>
                                </div>
                                <span style="font-size: 10px;color: rgba(0, 0, 0, 0.3);margin-top: 8px;padding: 0 22px;">{{ languageDemo === 'zh' ? '确认连接热点无误，继续下一步' : 'Confirm hotspot connection, proceed to next step' }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="phone-demo" v-if="activeName === '2' && step === '2'">
                        <div class="step0">
                            <span class="page-name">{{netConfigMsg[Number(activeName)].step2[languageDemo].pageName ? netConfigMsg[Number(activeName)].step2[languageDemo].pageName : (languageDemo === 'zh' ? '请输入页面名称' : 'Please enter the page name')}}</span>
                            <div class="page-body">
                                <span class="instructions">{{ netConfigMsg[Number(activeName)].step2[languageDemo].instructions ? netConfigMsg[Number(activeName)].step2[languageDemo].instructions : (languageDemo === 'zh' ? '请输入引导说明' : 'Please enter the guide description') }}</span>
                                <img src="../../assets/icon_qr.png" class="qr_img"/>
                                <el-carousel
                                    height="100px"
                                    :indicator-position="netConfigMsg[Number(activeName)].step2[languageDemo].file.length === 1 ? 'none' : ''"
                                    :arrow="netConfigMsg[Number(activeName)].step2[languageDemo].file.length === 1 ? 'never' : 'hover'"
                                    :autoplay="false" v-if="netConfigMsg[Number(activeName)].step2[languageDemo].file.length > 0">
                                    <el-carousel-item v-for="(image, i) in netConfigMsg[Number(activeName)].step2[languageDemo].file" :key="i">
                                        <el-image :src="image.url" class="guide_img">
                                            <div slot="error" class="image-slot">
                                                {{languageDemo === 'zh' ? '加载错误' : 'Loading error'}}
                                            </div>
                                        </el-image>
                                    </el-carousel-item>
                                </el-carousel>
                                <el-image class="guide_img" v-else>
                                    <div slot="error" class="image-slot">
                                        {{languageDemo === 'zh' ? '暂无图片预览' : 'No image preview available'}}
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="page_footer">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="primary" size="small" style="margin-left: 16px;">确认</el-button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import OperationGuide from "@/views/netConfig/components/operationGuide";
import NetSetting from "@/views/netConfig/components/netSetting";
import PairingCode from "@/views/netConfig/components/pairingCode";

let stepItem = {
    isShowRadio: true,
    isShowNextBtn: true,
    isShowAdvancedSettings: false,
    isSelectRadio: true,
    zh: {
        pageName: "",
        subtitle: "",
        instructions: "",
        file: [],
        radioText: ""
    },
    en: {
        pageName: "",
        subtitle: "",
        instructions: "",
        file: [],
        radioText: ""
    }
}

export default {

    name: "netConfig",
    components: {PairingCode, NetSetting, OperationGuide},
    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                console.log('productMsg', newVal)
                this.productData = {
                    productName: newVal.name,
                    pid: newVal.pid,
                    productImageUrl: newVal.icon,
                    model: newVal.model,
                    communication: newVal.protocolType,
                    remarks: newVal.remark,
                    categoryName: newVal.categoryName ? (this.language === 'zh' ? JSON.parse(newVal.categoryName).zh : JSON.parse(newVal.categoryName).en) : ""
                }
            },
            deep: true
        }
    },

    data() {
        return {
            //产品详情
            productData: {
                //产品名称
                productName: "",
                pid: "",
                //产品拟物图
                productImageUrl: "",
                //品类名称
                categoryName: "",
                model: "",
                communication: "",
                remarks: ""
            },

            step: "0",

            step0Index: 0,

            activeName: '1',

            isModuleMouse: false,

            isSortMouse: false,

            language: localStorage.getItem('language'),

            languageDemo: localStorage.getItem('language'),

            netConfigList: [
                {name: "蓝牙配网", value: "0"},
                {name: "热点配网", value: "1"},
                {name: "设备扫描二维码配网", value: "2"}
            ],

            checkNetConfigList: ['0', '1', '2'],

            netConfigMsg: [
                {
                    step0: {
                        name: "操作指引",
                        list: [this.$config.deepCopy(stepItem)]
                    },
                    step1: {
                        name: "配对码",
                        isOpenCode: true,
                        zh: {
                            pageName: "",
                            instructions: "",
                            file: []
                        },
                        en: {
                            pageName: "",
                            instructions: "",
                            file: []
                        }
                    },
                    step2: {
                        name: "网络设置",
                        isShowRadio: true,
                        isSelectRadio: true,
                        isOpenSkip: true
                    }
                },
                {
                    step0: {
                        name: "操作指引",
                        list: [this.$config.deepCopy(stepItem)]
                    },
                    step1: {
                        name: "连接设备热点",
                        zh: {
                            pageName: "",
                            instructions: "",
                            file: []
                        },
                        en: {
                            pageName: "",
                            instructions: "",
                            file: []
                        }
                    },
                    step2: {
                        name: "网络设置",
                        isShowRadio: true
                    }
                },
                {
                    step0: {
                        name: "操作指引",
                        list: [this.$config.deepCopy(stepItem)]
                    },
                    step1: {
                        name: "网络设置",
                        isShowRadio: true
                    },
                    step2: {
                        name: "二维码页",
                        zh: {
                            pageName: "",
                            instructions: "",
                            file: []
                        },
                        en: {
                            pageName: "",
                            instructions: "",
                            file: []
                        }
                    }
                }
            ]

        }
    },

    mounted() {
        this.queryProductDetail();
        this.activeName = (this.$route.query.activeName && this.$route.query.activeName !== '') ? this.$route.query.activeName : '0'
        this.step = (this.$route.query.step && this.$route.query.step !== '') ? this.$route.query.step : '0'
    },

    methods: {

        ...mapActions('product', ['queryProduct', 'getProductDetail']),

        previous() {
            if (this.netConfigMsg[Number(this.activeName)].step0.list.length === 1) return
            this.step0Index = this.step0Index - 1;
        },

        next() {
            if (this.netConfigMsg[Number(this.activeName)].step0.list.length === 1) return
            this.step0Index = this.step0Index + 1;
        },

        cancel() {
            this.$dialog.showConfirm({
                content: "取消后当前编辑数据将不被保存，是否确认取消编辑？",
                title: this.$i18n.t('ota.text38'),
                btn1: "确认",
                btn2: "取消",
                icon: 2
            }, () => {
                this.$router.back();
            }, () => {

            })
        },

        queryProductDetail() {
            this.getProductDetail({
                productCode: this.$route.query.id,
                isRefresh: true
            });
        },

        handleClick() {
            this.$config.changeURLArg('activeName', this.activeName);
            this.step = "0";
            this.$config.changeURLArg('step', '0');
            this.step0Index = 0;
        },

        radioChange() {
            this.$config.changeURLArg('step', this.step);
        },

        onOperationGuide(formList) {
            this.netConfigMsg[Number(this.activeName)].step0.list = formList;
        },

        onNetSetting(form) {
            if (this.activeName === '2') {
                this.netConfigMsg[Number(this.activeName)].step1 = form;
            } else {
                this.netConfigMsg[Number(this.activeName)].step2 = form;
            }
        },

        onPairingCode(form) {
            if (this.activeName === '2') {
                this.netConfigMsg[Number(this.activeName)].step2 = form;
            } else {
                this.netConfigMsg[Number(this.activeName)].step1 = form;
            }
        }

    },

}
</script>

<style scoped>

.ota_main {
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 74px 24px;
    padding: 20px;
}

.product_msg {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product_img {
    width: 60px;
    height: 60px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
}

.product_msg_top {
    height: 22px;
    display: flex;
}

.product_msg_bottom {
    margin-top: 4px;
    height: 22px;
}

.product_msg_list {
    margin-top: 10px;
    margin-left: 14px;
    float: left;
}

.product_msg_item_title {
    font-weight: bold;
}

.product_name {
    font-weight: bold;
    font-size: 16px;
    color: black;
    float: left;
    line-height: 22px;
}

.product_msg_item {
     float: left;
     font-size: 10px;
     color: #595959;
     font-weight: 400;
     margin-left: 40px;
 }

.product_msg_item:first-child {
    margin-left: 0;
}

.el-empty {
    padding: 0!important;
}

/deep/ .el-divider--horizontal {
    margin: 20px 0 0 0!important;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.main {
    /*height: calc(100% - 150px);*/
    border: none;
}


/deep/ .main .el-tabs__item {
    padding: 0 20px !important;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
}

/deep/ .el-tabs__header {
    margin: 0 0 -1px 0!important;
}

/deep/ .el-tabs__nav-wrap::after {
    height: 0!important;
}

.module-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-right: 8px;
}

.module-btn:hover {
    color: rgb(24, 144, 255);
}

.sort-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 0 8px;
    border-left: 1px solid #DCDFE6;
}

.sort-btn:hover {
    color: rgb(24, 144, 255);
}

.net-config-body {
    display: flex;
    flex-direction: row;
    border: 1px solid #DCDFE6;
}

.body-left {
    width: calc(100% - 271px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.body-right {
    width: 270px;
    height: 500px;
    border-left: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 37px; /* 吸顶位置 */
}

.phone-demo {
    width: 210px;
    height: 430px;
    background-image: url("../../assets/icon_phone_bg.png");
    background-repeat: no-repeat;
    margin-top: 10px;
    background-size: 100%;
    display: flex;
    flex-direction: column;
}

.step0 {
    display: flex;
    flex-direction: column;
}

.page-name {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 36px;
}

.page-body {
    margin: 12px 22px;
    display: flex;
    flex-direction: column;
}

.subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
}

.instructions {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    text-align: left;
    margin-top: 6px;
}

.guide_img {
    height: 90px;
    background-color: rgba(24, 144, 255, 0.08);
    border-radius: 3px;
    margin-top: 6px;
}

.phone-footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 40px;
    width: 210px;
    text-align: center;
    justify-content: center;
}

.phone-footer-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    margin-top: 10px;
}

.qr_img {
    width: 110px;
    height: 110px;
    margin: 10px auto;
}

/deep/ .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
}

/deep/ #tab-0, /deep/ #tab-1, /deep/ #tab-2 {
    padding-left: 0!important;
}

.page_footer {
    position: fixed;
    left: 232px;
    bottom: 0;
    right: 0;
    background-color: #ffffff;
    text-align: center;
    z-index: 8;
    padding: 8px 0;
    box-shadow: 0 -2px 6px 0 rgba(0,0,0,.11);
    font-weight: 500;
    font-size: 14px;
}

.phone-code {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.phone-code-item {
    width: 20px;
    height: 26px;
    background-color: rgb(234, 234, 234);
    text-align: center;
    line-height: 26px;
    border-radius: 2px;
}

.phone-code-item-text {
    font-size: 18px;
    font-weight: bold;
}

.input-top {
    width: 100%;
    height: 24px;
    background-image: url("../../assets/input_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.input-placeholder {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 6px;
}

.input-bottom {
    width: 100%;
    height: 24px;
    background-image: url("../../assets/input_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/deep/ .el-checkbox__label {
    display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left;
    font-size: 10px;
}

/deep/ .el-checkbox__inner {
    zoom: .7;
}

</style>
