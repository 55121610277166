<template>
    <div class="notice">
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: 'calc(100% - 30px)'
            }"
            shadow="never">
            <div slot="header" class="clearfix">
                <span>公告</span>
                <el-button style="float: right; padding: 3px 0" size="mini" type="text">
                    更多
                    <i class="el-icon-arrow-right"></i>
                </el-button>
            </div>
            <div v-for="item in noticeList" :key="item.id" class="notice_item" @click="toNoticeMsg(item)">
                <el-row :gutter="15">
                    <el-col :span="16">
                        <div class="notice_title" :title="item.title">
                            {{ item.title }}
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="notice_time" :title="item.createTime">{{ item.createTime }}</div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    data() {

        return {

            noticeList: []

        }
    },

    mounted() {

        this.getMsg();

    },

    methods: {

        ...mapActions('main', ['queryNotice']),

        /**
         * 获取公告列表
         */
        getMsg() {
            this.queryNotice({
                currPage: 1,
                pageSize: 20
            }).then(res => {
                this.noticeList = res.result.list ? res.result.list : [];
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        toNoticeMsg(item) {
            console.log(item);
            this.$store.commit("main/SET_NOTICE_MSG", item);
            this.$store.commit("main/SET_NOTICE_MSG_DIALOG_VISIBLE", true);
        }

    }

}
</script>

<style scoped>
.el-card {
    height: 100%;
}

.notice {
    width: 100%;
    height: 100%;
    /* background-color: white;
    border-radius: 4px; */
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.notice_item {
    margin-top: 5px;
    cursor: pointer;
}

.notice_title, .notice_time {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}

.notice_time {
    margin-top: 3px;
    text-align: right;
}
</style>
