var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticStyle:{"height":"100%"}},[_c('img',{staticClass:"login_left",attrs:{"src":require("../../assets/login_left.png")},on:{"load":_vm.loadImage}}),(_vm.isShowRight)?_c('div',{staticClass:"login_right",style:({
                width: 'calc(100% - ' + (_vm.loginLeftWidth + 1) + 'px)',
                textAlign: 'center'
            })},[_c('div',{staticClass:"right-top"},[_c('img',{staticClass:"logo",style:({left: (30 + _vm.loginLeftWidth) + 'px'}),attrs:{"src":require("../../assets/login_logo.png")}}),_c('div',{staticClass:"right-top-right"},[_c('el-select',{staticClass:"data_centers",attrs:{"size":"mini","placeholder":_vm.$i18n.t('login.text1')},on:{"change":_vm.selectDataCenters},model:{value:(_vm.dataCenters),callback:function ($$v) {_vm.dataCenters=$$v},expression:"dataCenters"}},_vm._l((_vm.countryList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-select',{staticClass:"language",staticStyle:{"width":"120px"},attrs:{"size":"mini","placeholder":_vm.$i18n.t('login.text2')},on:{"change":_vm.selectLanguage},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},[_c('img',{staticStyle:{"width":"14px","height":"14px","margin-top":"7px","margin-left":"4px"},attrs:{"slot":"prefix","src":require("../../assets/lang.png")},slot:"prefix"}),_vm._l((_vm.languageList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})})],2)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadEnd),expression:"isLoadEnd"}],staticClass:"card-form",style:({
                    height: _vm.activeName === 'register' ? '470px' : '425px',
                    margin: `${_vm.activeName === 'register' ? '-230px' : '-220px'} auto`
                })},[_c('el-tabs',{on:{"tab-click":_vm.switchTab},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":_vm.$i18n.t('login.text3'),"name":"login"}},[_c('login',{ref:"login",attrs:{"language":_vm.language,"dataCenters":_vm.dataCenters,"areaNumberList":_vm.areaNumberList,"systemConfig":_vm.systemConfig}})],1),_c('el-tab-pane',{attrs:{"label":_vm.$i18n.t('login.text4'),"name":"register"}},[_c('register',{ref:"register",attrs:{"language":_vm.language,"dataCenters":_vm.dataCenters,"areaNumberList":_vm.areaNumberList,"systemConfig":_vm.systemConfig},on:{"registerSuccess":_vm.registerSuccess}})],1)],1)],1),(_vm.language === 'zh')?_c('el-link',{staticClass:"copyright",attrs:{"type":"info","target":"_blank","href":"https://beian.miit.gov.cn"}},[_vm._v("Copyright   ©2022   广州朗国电子科技有限公司    粤ICP备14024048号")]):_c('div')],1):_vm._e()]),_c('reset-password-dialog',{attrs:{"language":_vm.language,"dataCenters":_vm.dataCenters,"areaNumberList":_vm.areaNumberList,"systemConfig":_vm.systemConfig}}),_c('set-password-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }