<template>
    <el-row :gutter="10">
        <el-col style="width: 200px;">
            <el-card shadow="never" class="box-card">
                <side class="side" ref="side" :list="userMenu"></side>
            </el-card>
        </el-col>
        <el-col style="width: calc(100% - 200px)">
            <div class="user_right">
                <router-view/>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import side from '@/views/setting/components/side';
import {mapState} from "vuex";

export default {
    components: {
        side
    },
    computed: {
        ...mapState('login', ['menuData'])
    },
    watch: {
        menuData: {
            handler(newVal) {
                this.userMenu = newVal.settingMenuList;
            },
            deep: true
        }
    },
    data() {
        return {
            userMenu: []
        }
    },
    mounted() {
        this.userMenu = this.menuData.settingMenuList;
    },
    methods: {}
}
</script>

<style scoped>


.el-card {
    border: none;
    height: 100%;
    border-radius: 8px;
}

/deep/ .el-card__body {
    padding: 0px;
    overflow: hidden;
}

.side {
    margin: 20px 0;
}

.user_right {
    width: 100%;
    height: 100px;
}

.el-row, .box-card, .el-col, .user_right, .routerLink {
    height: 100%;
}

</style>
