<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('empower.text106')"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog">

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">

            <el-form-item :label="$i18n.t('empower.text110')" prop="status">
                <el-select
                    v-model="form.status"
                    :popper-append-to-body="false"
                    clearable
                    :placeholder="$i18n.t('empower.text111')">
                    <el-option
                        v-for="item in $message.deviceStateList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item :label="$i18n.t('empower.text112')" prop="amount">
                <el-input-number v-model.trim="form.amount" style="width: 100%;" :placeholder="$i18n.t('empower.text113')" controls-position="right" :max="50000"></el-input-number>
            </el-form-item>

            <el-form-item>
                <el-checkbox v-model="form.noviewed">{{$i18n.t('empower.text114')}}</el-checkbox>
            </el-form-item>

        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{$i18n.t('empower.text52')}}</el-button>
            <el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('empower.text53')}}</el-button>
        </span>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "exportLicenseDialog",

    computed: {
        ...mapState('empower', ['exportLicenseDialogVisible'])
    },

    watch: {
        exportLicenseDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },

    data() {
        return {

            loading: false,

            visible: false,

            formLabelWidth: "100px",

            form: {

                //导出数量
                amount: "",

                //状态 - 1:待激活; 2:已激活
                status: "",

                //是否优先导出未查看过
                noviewed: false

            },

            rules: {
                status: [{required: true, message: this.$i18n.t('empower.text115'), trigger: "blur"}],
                amount: [
                    {required: true, message: this.$i18n.t('empower.text113'), trigger: 'blur'},
                    {pattern: /^[0-9]*[1-9][0-9]*$/, message: this.$i18n.t('empower.text54')},
                ]
            }

        }
    },

    methods: {

        ...mapActions('empower', ['closeExportLicenseDialog', 'exportLicense']),

        closeDialog() {
            this.closeExportLicenseDialog();
            this.form = {
                amount: "",
                status: "",
                noviewed: false
            }
            this.$refs.ruleForm.resetFields();
        },

        openDialog() {},

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$dialog.showConfirm({
                        content: this.$i18n.t('empower.text116'),
                        title: this.$i18n.t('empower.text106'),
                        btn1: this.$i18n.t('empower.text117'),
                        btn2: this.$i18n.t('empower.text40'),
                        icon: 2
                    }, () => {
                        this.loading = true;
                        this.exportLicense({
                            amount: this.form.amount,
                            authorizationCode: this.$route.query.code,
                            noviewed: this.form.noviewed ? 'Y' : 'N',
                            status: this.form.status
                        }).then(res => {
                            let time = this.$config.format(new Date().getTime(), false, true);
                            this.$config.downloadFile(res,  'License_' + this.$route.query.code +'_' + time + '.xls');
                            this.$dialog.showMessage(this.$i18n.t('empower.text118'), this.$config.TOAST_SUCCESS);
                            this.closeDialog();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false;
                        })
                    }, () => {
                    })
                }
            })
        }

    }

}
</script>

<style scoped>
/deep/ .el-dialog__body {
    margin-top: 20px;
}

.el-select {
    width: 100%;
}
</style>
