<template>
    <el-row class="add_product_card">
        <el-col :span="12" class="product_card_item" v-for="(item,index) in cardList" :key="index">

            <el-row :class="'product_card_item'+index">
                <el-col :span="7" style="text-align: right;">
                    <i :class=" item.icon + ' main-icon' "></i>
                </el-col>
                <el-col :span="17" style="padding-left: 10px">
                    <div class="card_title">{{ item.title }}</div>
                    <div class="card_content">{{ item.subtitle }}</div>
                    <el-button
                        type="primary"
                        round
                        size="small"
                        :disabled="item.id == 2"
                        @click="addProduct">
                        新建产品
                        <i class="el-icon-arrow-right el-icon--right"></i>
                    </el-button>
                </el-col>
            </el-row>

        </el-col>
    </el-row>
</template>

<script>

export default {

    data() {

        return {

            cardList: [
                {
                    id: 1,
                    title: "设备接入",
                    subtitle: "采取LangoIot平台提供的相关协议实现设备的接入",
                    icon: 'icon-shebeixieru'
                },
                {
                    id: 2,
                    title: "设备开发",
                    subtitle: "采取LangoIot平台提供的相关模组等实现设备的低代码开发",
                    icon: 'icon-shebeikaifa'
                }
            ]

        }

    },

    methods: {
        addProduct() {
            this.$router.push({path: "/addProduct"});
        }
    }

}
</script>

<style scoped>
.add_product_card {
    width: 100%;
    height: 190px;
}

.product_card_item {
    height: 100%;
    text-align: left;
    line-height: 30px;
}

.product_card_item0 {
    height: 100%;
    margin-right: 9px;
    background-color: white;
    border-radius: 8px;
}

.product_card_item1 {
    height: 100%;
    margin-left: 9px;
    background-color: white;
    border-radius: 8px;
}

.card_title {
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    margin-top: 40px;
}

.card_content {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin: 10px auto;
}

.main-icon {
    color: #3296FA;
    font-size: 90px;
    margin-right: 20px;
    margin-top: 80px;
    display: block;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
