<template>
    <div class="step0">
        <div class="step0-item" v-for="(item, index) in formList" :key="index">
            <div class="step-header">
                <span class="step-title">步骤({{`${index+1}/${formList.length}`}})</span>
                <el-button type="text" icon="el-icon-delete" @click="delStep(index)" v-show="formList.length > 1"></el-button>
            </div>
            <div class="step-form">
                <el-form ref="leftForm" class="left-form" size="small" :rules="rules" :model="item.zh" label-width="100px">
                    <el-form-item label="页面名称:" prop="pageName">
                        <span class="item-tip">简体中文</span>
                        <el-input v-model="item.zh.pageName" @blur="onFormListChange" placeholder="请输入页面名称"></el-input>
                    </el-form-item>
                    <el-form-item label="引导标题:" prop="subtitle">
                        <span class="item-tip">简体中文</span>
                        <el-input v-model="item.zh.subtitle" @blur="onFormListChange" placeholder="请输入引导标题"></el-input>
                    </el-form-item>
                    <el-form-item label="引导说明:" prop="instructions">
                        <span class="item-tip">简体中文</span>
                        <el-input v-model="item.zh.instructions" @blur="onFormListChange" type="textarea" maxlength="40" rows="4" show-word-limit placeholder="请输入引导说明"></el-input>
                    </el-form-item>
                    <el-form-item label="引导图片:" prop="file">
                        <span class="item-tip">简体中文</span>
                        <el-upload
                            list-type="picture-card"
                            :action="$message.uploadImgUrl"
                            :data="{
                               bucket: 'netConfig',
                               holdName: 'N' // 保持上传文件名不变
                            }"
                            :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: language
                            }"
                            accept=".png"
                            :limit="3"
                            :on-success="(response, file, fileList) => onSuccess(response, file, fileList, index, true)"
                            :on-error="onError"
                            :before-upload="(file) => onbeforeunload(file, index)"
                            :file-list="item.zh.file"
                            :on-remove="(file, fileList) => handleRemove(file, fileList, index, true)">
                            <i class="el-icon-plus" :id="`picture-card-zh-${index}`" v-show="item.zh.file.length < 3"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="展示单选项:" :prop="item.isShowRadio ? 'radioText' : ''">
                        <el-switch
                            style="margin-top: 6px;width: 40px;"
                            @change="onFormListChange"
                            v-model="item.isShowRadio">
                        </el-switch>
                        <span class="item-tip" v-show="item.isShowRadio">简体中文</span>
                        <el-input v-show="item.isShowRadio" @blur="onFormListChange" v-model="item.zh.radioText" placeholder="请输入单选文案"></el-input>
                    </el-form-item>
                </el-form>
                <el-form ref="rightForm" class="right-form" size="small" :rules="rules" :model="item.en" label-width="0">
                    <el-form-item prop="pageName">
                        <span class="item-tip">英语</span>
                        <el-input v-model="item.en.pageName" @blur="onFormListChange" placeholder="Please enter the boot name"></el-input>
                    </el-form-item>
                    <el-form-item prop="subtitle">
                        <span class="item-tip">英语</span>
                        <el-input v-model="item.en.subtitle" @blur="onFormListChange" placeholder="Please enter the guide title"></el-input>
                    </el-form-item>
                    <el-form-item prop="instructions">
                        <span class="item-tip">英语</span>
                        <el-input v-model="item.en.instructions" @blur="onFormListChange" type="textarea" maxlength="40" rows="4" show-word-limit placeholder="Please enter the guide description"></el-input>
                    </el-form-item>
                    <el-form-item prop="file">
                        <span class="item-tip">英语</span>
                        <el-upload
                            list-type="picture-card"
                            :action="$message.uploadImgUrl"
                            :data="{
                               bucket: 'netConfig',
                               holdName: 'N' // 保持上传文件名不变
                            }"
                            :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: language
                            }"
                            accept=".png"
                            :limit="3"
                            :on-success="(response, file, fileList) => onSuccess(response, file, fileList, index, false)"
                            :on-error="onError"
                            :before-upload="(file) => onbeforeunload(file, index)"
                            :file-list="item.en.file"
                            :on-remove="(file, fileList) => handleRemove(file, fileList, index, false)">
                            <i class="el-icon-plus" :id="`picture-card-en-${index}`" v-show="item.en.file.length < 3"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item :prop="item.isShowRadio ? 'radioText' : ''">
                        <span class="item-tip" style="margin-top: 26px;" v-show="item.isShowRadio">英语</span>
                        <el-input v-show="item.isShowRadio" @blur="onFormListChange" v-model="item.en.radioText" placeholder="Please enter the single choice text"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <el-button type="text" style="width: 56px;margin-left: 100px;padding: 0 0 18px 0;" @click="item.isShowAdvancedSettings = !item.isShowAdvancedSettings">高级设置<i :class="(item.isShowAdvancedSettings ? 'el-icon-arrow-up' : 'el-icon-arrow-down') + ' el-icon--right'"></i></el-button>
            <div class="next-btn" v-show="item.isShowAdvancedSettings">
                <span class="next-btn-label" style="margin-left: 100px;">开启【下一步】按钮：</span>
                <el-switch
                    disabled
                    style="width: 40px;"
                    @change="onFormListChange"
                    v-model="item.isShowNextBtn">
                </el-switch>
            </div>
        </div>
        <el-button type="text" style="width: 56px;margin-left: 100px;padding: 0;" @click="addStep" icon="el-icon-plus">添加步骤</el-button>
    </div>
</template>

<script>

let stepItem = {
    isShowRadio: true,
    isShowNextBtn: true,
    isShowAdvancedSettings: false,
    isSelectRadio: true,
    zh: {
        pageName: "",
        subtitle: "",
        instructions: "",
        file: [],
        radioText: ""
    },
    en: {
        pageName: "",
        subtitle: "",
        instructions: "",
        file: [],
        radioText: ""
    }
}

export default {

    name: "operationGuide",

    props: {
        formValue: {
            Type: String,
            default: ""
        },
        activeName: {
            Type: String,
            default: ""
        }
    },

    watch: {

        activeName: {
            handler(neWValue) {
                this.formList = JSON.parse(this.formValue).list;
            }
        }

    },

    data() {
        return {
            formList: [],
            rules: {
                pageName: [{required: true, message: "请输入页面名称", trigger: "blur"}],
                subtitle: [{required: true, message: "请输入引导标题", trigger: "blur"}],
                instructions: [{required: true, message: "请输入引导说明", trigger: "blur"}],
                file: [{ required: true, message: '请上传引导图片', trigger: 'change' }],
                radioText: [{ required: true, message: '请输入单选文案', trigger: 'change' }]
            },
            dialogImageUrl: '',
            language: localStorage.getItem('language'),
            dialogVisible: false
        }
    },

    mounted() {
        this.formList = JSON.parse(this.formValue).list
    },

    methods: {

        addStep() {
            this.formList.push(this.$config.deepCopy(stepItem))
            console.log(this.formList)
            this.onFormListChange();
        },

        delStep(index) {
            this.formList.splice(index, 1);
            this.onFormListChange();
        },

        onFormListChange() {
            this.$emit("change", this.formList);
        },

        handleRemove(file, fileList, index, isZH) {
            console.log(file, fileList, this.formList);
            this.formList[index][isZH ? 'zh' : 'en'].file = fileList;
            this.onFormListChange();
            if (this.formList[index][isZH ? 'zh' : 'en'].file.length < 3) {
                document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}-${index}`).parentNode.style.display = ""
            } else {
                document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}-${index}`).parentNode.style.display = "none"
            }
        },

        onbeforeunload(file, index) {},

        onSuccess(response, file, fileList, index, isZH) {
            console.log(response)
            if (response.success) {
                this.formList[index][isZH ? 'zh' : 'en'].file = fileList;
                if (this.formList[index][isZH ? 'zh' : 'en'].file.length < 3) {
                    document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}-${index}`).parentNode.style.display = ""
                } else {
                    document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}-${index}`).parentNode.style.display = "none"
                }
                this.onFormListChange()
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
            console.log(this.formList)
        },

        onError(err, file, fileList) {
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },

    }

}
</script>

<style scoped>

.step0 {
    width: calc(100% - 48px);
    margin: 24px;
    display: flex;
    flex-direction: column;
}

.step0-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.step-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(247, 247, 247);
    padding: 6px 12px;
    border-radius: 4px;
}

.step-title {
    font-size: 16px;
    font-weight: bold;
}

/deep/ .step-header .el-button--text {
    padding: 0!important;
    color: #F56C6C;
}

.step-form {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.left-form {
    flex: 6;
}

.right-form {
    flex: 5;
    margin-left: 24px;
}

/deep/ .el-form-item__content {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

/deep/ .el-form-item__label {
    font-weight: bold;
}

.item-tip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}

/deep/ .el-upload-list__item, /deep/ .el-upload--picture-card {
    width: 100px!important;
    height: 100px!important;
}

/deep/ .el-form-item__content > div {
    display: flex;
}

/deep/ .el-upload--picture-card {
    line-height: 110px!important;
    margin-bottom: 20px;
    /*margin-right: 8px;*/
}

/deep/ .el-upload-list--picture-card .el-progress {
    /*width: 80px!important;*/
    /*height: 80px!important;*/
    zoom: 70%;
}

.next-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
}

.next-btn-label {
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}

</style>
