<template>

    <el-card
        :body-style="{
            textAlign: 'left',
            padding: '15px',
            height: '100%'
        }"
        shadow="always">

        <div slot="header" class="clearfix">
            资料下载
        </div>

    </el-card>

</template>

<script>
export default {

    data() {

        return {}

    }

}
</script>

<style scoped>

.el-card {
    height: 100%;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold !important;
}
</style>
