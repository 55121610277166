<template>
    <div>
        <div class="page_title">
            售后运维
        </div>

        <!-- 设备概览 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            style="
                height: 120px;
                margin: 64px 24px 24px 24px;
            "
            shadow="never">

            <div slot="header" class="clearfix">
                <span>设备概览</span>
            </div>

            <el-row style="text-align: center;">
                <el-col :span="8">
                    <div class="device_title">总工单数</div>
                    <div class="device_msg">
                        <span class="device_msg_number">{{ overview.totalWorkOrders }}</span>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="device_title">已处理工单数</div>
                    <div class="device_msg1">
                        维修
                        <span class="device_msg_number2">{{ overview.processed.repair }}</span>
                        客服
                        <span class="device_msg_number2">{{ overview.processed.customerService }}</span>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="device_title">待处理工单数</div>
                    <div class="device_msg1">
                        维修
                        <span class="device_msg_number2">{{ overview.pending.repair }}</span>
                        客服
                        <span class="device_msg_number2">{{ overview.pending.customerService }}</span>
                    </div>
                </el-col>
            </el-row>

        </el-card>
        <!-- 设备概览 end -->


        <!-- 工单列表 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '0',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <div slot="header" class="clearfix">
                <span>工单列表</span>
                <el-form :inline="true" size="mini" :model="form" class="work_order_from">
                    <el-form-item>
                        <el-input
                            prefix-icon="el-icon-search" v-model.trim="form.keyword" clearable
                            placeholder="设备ID/联系人/联系电话" @clear="getMsg(1)"
                            @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.workOrderType" clearable placeholder="工单类型">
                            <el-option
                                v-for="item in $message.workOrderTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.workOrderStates" clearable placeholder="工单状态">
                            <el-option
                                v-for="item in $message.workOrderStatesList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="showDialog">
                            人员管理
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="card_left">
                <div
                    :class="item.id === leftMenuAction.id ? 'card_left_item_action' : 'card_left_item'"
                    v-for="(item, index) in leftMenu"
                    :key="index"
                    @click="selectMenu(item)"
                >
                    <div class="card_left_states">{{ item.type === 1 ? "维修工单" : "客服咨询" }}</div>
                    <div class="card_left_item_row">
                        <span class="card_left_title">设备ID：</span>
                        <span class="card_left_msg">{{ item.code }}</span>
                    </div>
                    <div class="card_left_item_row">
                        <span class="card_left_title">联系电话：</span>
                        <span class="card_left_msg">{{ item.telephone }}</span>
                    </div>
                    <div class="card_left_item_row">
                        <span class="card_left_title">联系人：</span>
                        <span class="card_left_msg">{{ item.contacts }}</span>
                    </div>
                    <span :style="{
                        border: '1px solid ' + setColor(item.states),
                        color: setColor(item.states),
                        borderRadius: '4px',
                        width: 'auto',
                        fontSize: '12px',
                        padding: '2px',
                        position: 'absolute',
                        top: '10px',
                        right: '20px'
                    }">
                        {{ $config.getMsgItemUtil($message.workOrderStatesList, item.states, 'id', 'name') }}
                    </span>
                    <div :class="item.id === leftMenuAction.id ? 'right_div_action' : 'right_div'"></div>
                </div>
            </div>

            <div class="card_right">
                <el-descriptions title="工单详情" :column="3">
                    <el-descriptions-item label="用户ID">kooriookami</el-descriptions-item>
                    <el-descriptions-item label="设备ID">18100000000</el-descriptions-item>
                    <el-descriptions-item label="售后类型">苏州市</el-descriptions-item>
                    <el-descriptions-item label="联系人">学校</el-descriptions-item>
                    <el-descriptions-item label="服务地址">江苏省苏州市</el-descriptions-item>
                    <el-descriptions-item label="问题描述">江苏省苏州市</el-descriptions-item>
                    <el-descriptions-item label="问题图片" :span="3">
                        <el-image
                            style="width: 60px; height: 60px"
                            :src="'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'"
                            :preview-src-list="[
                              'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                              'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
                            ]">
                        </el-image>
                    </el-descriptions-item>
                </el-descriptions>

                <el-descriptions title="工单处理" :column="3"
                                 v-if="leftMenuAction.states === 3 && leftMenuAction.type === 1">
                    <el-descriptions-item label="处理人">老屎壳郎</el-descriptions-item>
                    <el-descriptions-item label="上门时间">2022-3-3 12:00</el-descriptions-item>
                    <el-descriptions-item label="处理意见">xxxxxxx</el-descriptions-item>
                    <el-descriptions-item :span="3" label="处理结果">工单结束</el-descriptions-item>
                </el-descriptions>

                <el-descriptions title="工单处理" :column="1"
                                 v-if="leftMenuAction.states === 3 && leftMenuAction.type === 2">
                    <el-descriptions-item label="咨询记录">xxxxxxxxxxxxxxxxxxxxxxxxxx</el-descriptions-item>
                </el-descriptions>

                <div class="el-descriptions__title" style="padding: 14px;"
                     v-if="leftMenuAction.states === 1">工单处理
                </div>
                <el-form
                    label-width="100px"
                    :rules="consultFormRules"
                    size="mini"
                    :model="consultForm"
                    class="consult_form"
                    v-if="leftMenuAction.states === 1 && leftMenuAction.type === 2">
                    <el-form-item
                        label="咨询记录"
                        style="width: 100%;"
                        prop="consultMsg">
                        <el-input
                            type="textarea"
                            placeholder="请记录咨询详情"
                            v-model="consultForm.consultMsg"
                            :rows="4"
                            maxlength="100"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: center;width: 100%;">
                        <el-button type="primary" size="small">完成</el-button>
                    </el-form-item>
                </el-form>


                <el-form
                    :rules="repairFormRules"
                    size="mini"
                    label-width="100px"
                    :model="repairForm"
                    class="repair_form"
                    v-if="leftMenuAction.states === 1 && leftMenuAction.type === 1">

                    <el-form-item
                        label="处理人"
                        prop="handlingPeople">
                        <el-select v-model="repairForm.handlingPeople" :popper-append-to-body="false" clearable placeholder="请选择">
                            <el-option
                                v-for="item in handlingPeopleList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item
                        label="上门时间"
                        prop="time">
                        <el-date-picker
                            v-model="repairForm.time"
                            type="datetime"
                            placeholder="选择上门时间">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item
                        label="处理意见"
                        prop="opinion">
                        <el-input
                            type="textarea"
                            placeholder="请根据设备日志给出处理意见,没有则填无"
                            v-model="repairForm.opinion"
                            :rows="4"
                            maxlength="100"
                            show-word-limit
                        ></el-input>
                    </el-form-item>

                    <el-form-item style="text-align: center;width: 100%;">
                        <el-button type="primary" size="small">派单</el-button>
                    </el-form-item>

                </el-form>
            </div>

        </el-card>
        <!-- 工单列表 start -->

        <personnel-management-dialog></personnel-management-dialog>

    </div>
</template>

<script>
import PersonnelManagementDialog from "@/views/maintain/components/dialog/personnelManagementDialog";
export default {

    name: "afterSales",

    components: {PersonnelManagementDialog},

    data() {

        return {

            leftMenu: [
                {
                    id: 0,
                    type: 1,
                    code: "jjsjdsklf",
                    telephone: "10086",
                    contacts: "13548821713",
                    states: 1
                },
                {
                    id: 1,
                    type: 1,
                    code: "jjsjdsklf",
                    telephone: "10086",
                    contacts: "13548821713",
                    states: 3
                },
                {
                    id: 2,
                    type: 2,
                    code: "jjsjdsklf",
                    telephone: "10086",
                    contacts: "13548821713",
                    states: 2
                },
                {
                    id: 3,
                    type: 2,
                    code: "jjsjdsklf",
                    telephone: "10086",
                    contacts: "13548821713",
                    states: 3
                },
                {
                    id: 4,
                    type: 2,
                    code: "jjsjdsklf",
                    telephone: "10086",
                    contacts: "13548821713",
                    states: 1
                }
            ],

            handlingPeopleList: [
                {id: 0, name: "张三"},
                {id: 1, name: "李四"},
                {id: 2, name: "王五"},
                {id: 3, name: "赵六"}
            ],

            leftMenuAction: {
                id: 1,
                type: 1,
                code: "jjsjdsklf",
                telephone: "10086",
                contacts: "13548821713",
                states: 3
            },

            form: {
                //设备ID/绑定用户
                keyword: "",
                //工单状态
                workOrderStates: "",
                //工单类型
                workOrderType: ""
            },

            consultFormRules: {
                consultMsg: [
                    {required: true, message: "请输入咨询详情"}
                ]
            },

            repairFormRules: {
                handlingPeople: [
                    {required: true, message: "请选择处理人"}
                ],
                time: [
                    {required: true, message: "请选择上门时间"}
                ],
                opinion: [
                    {required: true, message: "请输入咨询详情"}
                ]
            },

            consultForm: {
                consultMsg: ""
            },

            repairForm: {
                handlingPeople: "",
                time: "",
                opinion: ""
            },

            //卡片高度
            cardHeight: 300,

            //授权证书概览
            overview: {
                //总工单数
                totalWorkOrders: 8000,
                //已处理工单数
                processed: {
                    repair: 8000,
                    customerService: 8000
                },
                //待处理工单数
                pending: {
                    repair: 8000,
                    customerService: 8000
                }
            },

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
    },

    methods: {

        getMsg(page) {

        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 330 - 12;
        },

        /**
         * 左侧菜单点击
         * @param item
         */
        selectMenu(item) {
            this.leftMenuAction = item;
        },

        /**
         * 设置状态颜色
         * @param index
         */
        setColor(index) {
            return index === 1 ? "#FE7A23" : index === 2 ? "#1890FF" : "#999999";
        },

        showDialog() {
            this.$store.commit("maintain/SET_PERSONNEL_MANAGEMENT_DIALOG_VISIBLE", true)
        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    }


}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 24px;
}

.card_left {
    width: 240px;
    height: 100%;
    border-right: 1px solid #E9E9E9;
    overflow-y: auto;
    float: left;
}

.card_right {
    width: calc(100% - 241px);
    height: 100%;
    float: left;
    margin-top: -10px;
    overflow-y: auto;
}

.consult_form, .repair_form {
    padding: 10px;
}

/deep/ .el-date-editor {
    width: 200px!important;
}

/deep/ .consult_form .el-form-item__content,
/deep/ .repair_form .el-form-item__content {
    width: 90%;
}

.el-descriptions {
    padding: 14px;
}

.el-descriptions:first-child {
    border-bottom: 1px solid #E9E9E9;
}

.card_left::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: #f2f2f2;
    padding-right: 2px;
}

.card_left_item {
    border-bottom: 1px solid #E9E9E9;
    padding: 14px;
    position: relative;
    cursor: pointer;
}

.card_left_item_action, .card_left_item:hover {
    border-bottom: 1px solid #E9E9E9;
    padding: 14px;
    position: relative;
    background-color: rgba(24, 144, 255, 0.06);
}

.card_left_item_row {
    margin-top: 10px;
}

.card_left_states {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.card_left_title {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
}

.card_left_msg {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
}

.right_div {
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.right_div_action {
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #02A7F0;
}

.clearfix {
    text-align: left;
}

/deep/ .el-card__header {
    font-weight: bold;
    padding: 10px;
}

.device_title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.60);
}

.device_msg {
    margin-top: 5px;
}

.device_msg1 {
    margin-top: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.60);
}

.work_order_from {
    float: right;
    margin-top: -4px;
}

.device_msg_number {
    font-family: HelveticaNeue;
    font-size: 24px;
    color: #1890FF;
    margin-right: 3px;
}

.device_msg_number2 {
    font-family: HelveticaNeue;
    font-size: 14px;
    color: #1890FF;
    margin-right: 3px;
}

/deep/ .el-date-editor {
    width: 350px;
}

.el-select {
    width: 100px;
}

/deep/ .el-table__body .el-table__row.hover-bg td {
    background-color: #DEE0E5 !important;
}
</style>
