<template>
  <div class="main">
    <div class="step1" v-if="step === 1">
      <img src="../../assets/mobile_logo.png" class="logo" />
      <span class="step1-title">注销账号</span>
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
        <el-form-item prop="tel" :error="error">
          <el-input v-model="form.tel" placeholder="手机号码" style="width: 240px;margin-top: 30px;"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" round style="width: 240px;margin-top: 30px;" :loading="loadingBtn1" @click="next()">下一步</el-button>
    </div>

    <div class="step2" v-else-if="step === 2">
      <span class="step2-title">注销账号</span>
      <div style="text-align: left;width: 40%;margin-top: 20px;">
        <p class="step2-span1">账号注销，将发生如下变化:</p>
        <p class="step2-span2">账号注销，永久无法登录</p>
        <p class="step2-span3">为了保障您的用户权益，我们将永久删除您的数据，且解除第三方账号绑定</p>
        <p class="step2-span2">账号关联的信息无法找回</p>
        <p class="step2-span3">为了保障您的信息安全等，我们将删除一切账号关联的数据信息，包含但不限:</p>
        <p class="step2-span3">1.智能设备数据</p>
        <p class="step2-span3">2.Funlink云服务照片、视频、通讯录等数据</p>
        <p class="step2-span3">3.其他关联数据</p>
      </div>
      <el-button type="primary" round style="width: 240px;margin-top: 30px;" @click="visible = true">已知晓，确认注销</el-button>
    </div>

    <div class="step2" v-else-if="step === 3">
      <img src="../../assets/icon_success.png" class="logo" >
      <span class="step1-title">注销成功</span>
<!--      <el-button type="primary" round style="margin-top: 40px;width: 100px;" @click="$router.push({path: '/'})">完成</el-button>-->
    </div>


    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="为了安全起见，请验证您的身份"
      :visible.sync="visible"
      width="25%"
      @close="closeDialog"
      @open="openDialog"
      center>
      <el-form :model="form" :rules="rules1" ref="ruleForm1" label-width="0" class="demo-ruleForm">
        <el-form-item prop="code" :error="error1">
          <el-input
            type="text"
            v-model.trim="form.code"
            placeholder="验证码">
            <template slot="append">
              <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                         :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
            <el-button @click="closeDialog" round>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit" round>验证</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "destroy",
  data: () => ({
    step: 1,
    form: {
      tel: "",
      code: ""
    },
    //验证码
    code: "",
    smsId: "",
    rules: {
      tel: [
        {required: true, message: "请输入手机号", trigger: "blur"},
        {pattern: /^1[0-9]{10}$/, message: '手机号格式不正确'}
      ]
    },
    rules1: {
      code: [
        {required: true, message: "请输入验证码", trigger: "blur"}
      ]
    },
    visible: false,
    codeBtnLoading: false,
    isCodeBtnDisabled: false,
    codeBtnMsg: "获取验证码",
    time: 60,
    loading: false,
    error: "",
    error1: "",
    loadingBtn1: false
  }),
  methods: {
    ...mapActions('login', ['sendSms', 'isAllowRegistration', 'destroy']),
    next() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingBtn1 = true;
          this.isAllowRegistration({
            platformType  : "3",
            userName: this.form.tel
          }).then(res => {
            if (res.code === '0') {
              this.error = "用户不存在";
              return;
            }
          }).catch(err => {
            if (err.code === '-1') {
              this.step = 2;
            }
          }).finally(() => this.loadingBtn1 = false)
        }
      })
    },
    closeDialog() {

    },
    openDialog() {

    },
    getCode() {
      this.codeBtnLoading = true;
      this.sendSms({
        phoneCode: this.form.tel,
        type: '7'
      }).then(res => {
        this.smsId = res.result;
        this.countDown();
      }, err => {
        console.log(err);
        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
      }).finally(() => {
        this.codeBtnLoading = false;
      })
    },
    submit() {
      this.loading = true;
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          this.destroy({
            code: this.form.code,
            smsId: this.smsId,
            userName: this.form.tel
          }).then(res => {
            this.visible = false;
            this.step = 3
          }).catch(err => {
            this.error1 = err.resultMsg;
          }).finally(() => this.loading = false)
        }
      })
    },

    /**
     * 发送完成验证码后的倒计时处理
     */
    countDown() {
      this.isCodeBtnDisabled = true;
      this.codeBtnMsg = this.time + "s后重新获取"
      let codeTime = setInterval(() => {
        this.time--;
        this.codeBtnMsg = this.time + "s后重新获取"
        if (this.time < 0) {
          this.time = 60;
          this.codeBtnMsg = "重新获取验证码"
          this.isCodeBtnDisabled = false;
          clearInterval(codeTime);
        }
      }, 1000)
    },
  }
}
</script>

<style scoped>
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.step1, .step2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.logo {
  width: 70px;
  height: 70px;
}

.step1-title {
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-top: 10px;
}

.step2-title {
  font-size: 26px;
  font-weight: bold;
  color: black;
}

.step2-span1 {
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.step2-span2 {
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-top: 20px;
}

.step2-span3 {
  font-size: 16px;
  color: black;
  margin-top: -10px;
}

/deep/ .el-dialog .el-dialog__header{
  text-align: center !important;
  border-bottom: none!important;
}

/deep/ .el-dialog--center .el-dialog__footer {
  text-align: center !important;
}
</style>
