<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$i18n.t('member.text32')"
        :visible.sync="visible"
        width="50%"
        append-to-body
        @close="closeDialog"
        @open="openDialog"
        center>

        <el-form :inline="true" size="small" :model="productForm">
            <!--            <el-form-item>-->
            <!--                <el-input v-model="productForm.productName" clearable placeholder="产品名称" @clear="getMsg(1)" @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item>
                <el-input v-model.trim="productForm.keyword" clearable :placeholder="$i18n.t('member.text42')" @clear="getMsg(1)"
                          @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
            </el-form-item>

            <!--            <el-form-item>-->
            <!--                <el-input v-model="productForm.pid" clearable placeholder="PID" @clear="getMsg(1)" @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item>
                <el-cascader
                    clearable
                    @change="getMsg(1)"
                    :show-all-levels="false"
                    v-model="productForm.productType"
                    :options="productTypeList"
                    :props="props">
                </el-cascader>
            </el-form-item>
            <el-form-item>
                <el-select @change="getMsg(1)" style="width: 260px" :popper-append-to-body="false" v-model="productForm.communication" clearable :placeholder="$i18n.t('member.text43')">
                    <el-option
                        v-for="item in $message.protocolTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select @change="getMsg(1)" style="width: 200px" :popper-append-to-body="false" v-model="productForm.state" clearable :placeholder="$i18n.t('member.text44')">
                    <el-option
                        v-for="item in $message.productStatusList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <!--            <el-form-item>-->
            <!--                <el-button type="primary" icon="el-icon-search" @click="getMsg(1); btnLoading = true;"-->
            <!--                           :loading="btnLoading">搜索-->
            <!--                </el-button>-->
            <!--                <el-button type="primary" icon="el-icon-refresh" circle></el-button>-->
            <!--            </el-form-item>-->
        </el-form>

        <el-table
            ref="productTable"
            class="product_table"
            :data="productMsg.list"
            size="small"
            :height="300"
            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
            v-loading="loading"
            @select="handleSelectionChange"
            @select-all="selectAll"
            style="width: 100%;">
            <el-table-column
                type="selection"
                width="80">
            </el-table-column>
            <el-table-column
                :label="$i18n.t('member.text45')"
                width="150">
                <template slot-scope="scope">
                    <el-image
                        style="width: 50px; height: 50px"
                        :src="scope.row.icon"
                        :preview-src-list="[scope.row.icon]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                width="300"
                :label="$i18n.t('member.text46')">
            </el-table-column>
            <el-table-column
                width="180"
                prop="pid"
                label="PID">
            </el-table-column>
            <el-table-column
                width="150"
                :label="$i18n.t('member.text47')">
                <template slot-scope="scope">
                    {{ JSON.parse(scope.row.categoryName).zh }}
                </template>
            </el-table-column>
            <el-table-column
                width="200"
                :label="$i18n.t('member.text48')">
                <template slot-scope="scope">
                    {{ $config.getMsgItemUtil($message.protocolTypeList, scope.row.protocolType, 'id', 'name') }}
                </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                width="200"
                :label="$i18n.t('member.text49')">
            </el-table-column>
            <el-table-column
                width="150"
                prop="remark"
                :label="$i18n.t('member.text50')">
            </el-table-column>
            <el-table-column
                width="180"
                :label="$i18n.t('member.text51')">
                <template slot-scope="scope">
                    {{ $config.getMsgItemUtil($message.productStatusList, scope.row.status, 'id', 'name') }}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            :current-page="page"
            @current-change="handleCurrentChange"
            :page-size="10"
            layout=" prev, pager, next"
            style="margin-top: 10px;text-align: right;"
            :total="parseInt(productMsg.totalRecords)">
        </el-pagination>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{$i18n.t('member.text33')}}</el-button>
            <el-button type="primary" :loading="submitLoading" @click="submit">{{$i18n.t('member.text34')}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('member', ['selectProductDialogVisible'])
    },

    watch: {
        selectProductDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            },
            deep: true
        }
    },

    data() {

        return {

            //查询表单
            productForm: {
                keyword: "",
                productName: "",
                pid: "",
                productType: "",
                communication: "",
                state: ""
            },

            btnLoading: false,

            productTypeList: [],

            visible: false,

            loading: false,

            submitLoading: false,

            selectMsg: [],

            props: {
                lazy: true,
                value: "code",
                label: "name",
                children: "children",
                lazyLoad: (node, resolve) => {
                    console.log(node);
                    if (!node.value) {
                        //获取一级品类
                        this.getProtocolTypeList();
                        return
                    }
                    this.getLevelProtocolTypeList(node.value, resolve)
                }
            },

            //当前页
            page: 1,

            //数据源
            productMsg: {
                list: [],
                totalRecords: 0
            }

        }

    },

    methods: {

        ...mapActions('member', ['closeSelectProductDialog']),
        ...mapActions('product', ['queryProduct']),
        ...mapActions('category', ['queryFirstLevelCategory', 'queryChildsCategory']),

        closeDialog() {
            this.closeSelectProductDialog();
            this.selectMsg = [];
        },

        openDialog() {
            this.getProtocolTypeList();
            this.getMsg();
        },

        getMsg(page) {
            if (page) this.page = page;
            this.loading = true;
            this.queryProduct({
                categoryCode: this.productForm.productType[2],
                currPage: this.page,
                pageSize: 10,
                keyword: this.productForm.keyword,
                // pid: this.productForm.pid,
                // productName: this.productForm.productName,
                productStatus: this.productForm.state,
                protocolType: this.productForm.communication,
            }).then(res => {
                this.productMsg = res.result;
                this.setCheckbox();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.btnLoading = false;
                this.loading = false;
            })
        },

        setCheckbox() {
            this.selectMsg.forEach(row => {
                this.productMsg.list.forEach(item => {
                    if (row.code == item.code) {
                        this.$nextTick(() => {
                            this.$refs.productTable.toggleRowSelection(item, true);
                        })
                    }
                })
            })
        },

        setMsg(list) {
            this.selectMsg = list;
            this.setCheckbox();
        },

        getProtocolTypeList() {
            this.queryFirstLevelCategory().then(res => {
                res.result.forEach(item => {
                    item.name = JSON.parse(item.name).zh
                })
                this.productTypeList = res.result
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg()
        },

        selectAll(selection) {
            if (selection.length <= 0) {
                this.selectMsg = [];
                return;
            }
            for (let i = 0; i < selection.length; i++) {
                let index = this.selectMsg.findIndex(item => {
                    return item.code === selection[i].code;
                });
                if (index < 0) this.selectMsg.push(selection[i]);
            }
        },

        handleSelectionChange(selection, row) {
            let index = this.selectMsg.findIndex(item => {
                return item.code === row.code;
            });
            if (index >= 0) {
                this.selectMsg.splice(index, 1);
            }
            for (let i = 0; i < selection.length; i++) {
                if (row.code == selection[i].code) {
                    this.selectMsg.push(row);
                }
            }
        },

        getLevelProtocolTypeList(code, resolve) {
            this.queryChildsCategory({
                code
            }).then(res => {
                res.result.forEach(item => {
                    item.name = JSON.parse(item.name).zh;
                    if (item.level == 2) {
                        item.children = [];
                    }
                    if (item.level == 3) {
                        item.leaf = true;
                    }
                })
                console.log(res.result)
                resolve(res.result)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        submit() {
            this.$emit('change', this.$config.deepCopy(this.selectMsg));
            this.closeDialog()
        }

    }

}
</script>

<style scoped>
.el-input {
    width: 100px;
}

.el-select {
    width: 100px;
}

.el-cascader {
    width: 100px;
}


/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-form {
    margin-top: 20px;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
