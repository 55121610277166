<template>
    <el-container class="full-container">
        <el-header class="shadow">
            <home-header :lang="lang"></home-header>
        </el-header>

        <!-- 开放生态 start -->
        <div class="banner">
            <img src="../../assets/index/banner_bg.png" class="banner-bg">
            <div class="banner-text">
                <span>{{$i18n.t('index.text1')}}</span>
                <p>{{$i18n.t('index.text2')}}</p>
            </div>
        </div>
        <!-- 开放生态 end -->

        <!-- 多种接入方式 start -->
        <div class="module">
            <el-row>
                <el-col :span="12">
                    <div class="module-text">
                        <span>{{$i18n.t('index.text3')}}</span>
                        <p>
                            {{$i18n.t('index.text4')}}<br/>
                            {{$i18n.t('index.text5')}}
                        </p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <img src="../../assets/index/multiple_access_methods.png" class="module-img">
                </el-col>
            </el-row>
        </div>
        <!-- 多种接入方式 end -->

        <!-- 产品解决方案 start -->
        <div class="module">
            <el-row>
                <el-col :span="12">
                    <img src="../../assets/index/product_solutions.png" class="module-img">
                </el-col>
                <el-col :span="12">
                    <div class="module-text">
                        <span>{{$i18n.t('index.text6')}}</span>
                        <p>
                            {{$i18n.t('index.text7')}}<br/>
                            {{$i18n.t('index.text8')}}
                        </p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 产品解决方案 end -->

        <!-- 各类智能硬件模组 start -->
        <div class="hardware-module">
            <span class="title">{{$i18n.t('index.text9')}}</span>
            <p>{{$i18n.t('index.text10')}}</p>

            <el-row style="width: 1200px;margin: 0 auto;">
                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="table-header">{{$i18n.t('index.text11')}}</div>
                    <div class="table-body">
                        {{$i18n.t('index.text12')}}<br/>
                        {{$i18n.t('index.text13')}}<br/>
                        {{$i18n.t('index.text14')}}
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="table-header">{{$i18n.t('index.text15')}}</div>
                    <div class="table-body">
                        {{$i18n.t('index.text16')}}<br/>
                        {{$i18n.t('index.text17')}}
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="table-header">{{$i18n.t('index.text18')}}</div>
                    <div class="table-body" style="text-align: center;">
                        {{$i18n.t('index.text19')}}<br/>
                        WIFI<br/>
                        WIFI4 + WIFI5<br/>
                        {{$i18n.t('index.text20')}} <br/>
                        WIFI4 + WIFI 6
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 各类智能硬件模组 end -->

        <!-- AI算法模型 start -->
        <div class="ai-module">
            <span class="title">{{$i18n.t('index.text21')}}</span>
            <p>{{$i18n.t('index.text22')}}</p>

            <el-row style="width: 1200px;margin: 0 auto;">
                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="ai-table-body">
                        <img src="../../assets/index/icon_ai3.png" class="ai-img">
                        <span class="ai-item-title">{{$i18n.t('index.text23')}}</span>
                        <p>{{$i18n.t('index.text24')}}</p>
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="ai-table-body">
                        <img src="../../assets/index/icon_ai2.png" class="ai-img">
                        <span class="ai-item-title">{{$i18n.t('index.text25')}}</span>
                        <p>{{$i18n.t('index.text26')}}</p>
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="ai-table-body">
                        <img src="../../assets/index/icon_ai1.png" class="ai-img">
                        <span class="ai-item-title">{{$i18n.t('index.text27')}}</span>
                        <p>{{$i18n.t('index.text28')}}</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- AI算法模型 end -->

        <!-- 合作流程 start -->
        <div class="cooperation-module">
            <span class="title">{{$i18n.t('index.text29')}}</span>

            <el-row>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process1.png" class="cooperation-img">
                    <div>
                        <span>{{$i18n.t('index.text30')}}</span>
                        <p>{{$i18n.t('index.text31')}}</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <i class="el-icon-arrow-right"></i>
                </el-col>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process2.png" class="cooperation-img">
                    <div>
                        <span>{{$i18n.t('index.text32')}}</span>
                        <p>{{$i18n.t('index.text33')}}</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <i class="el-icon-arrow-right"></i>
                </el-col>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process3.png" class="cooperation-img">
                    <div>
                        <span>{{$i18n.t('index.text34')}}</span>
                        <p>{{$i18n.t('index.text35')}}</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <i class="el-icon-arrow-right"></i>
                </el-col>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process4.png" class="cooperation-img">
                    <div>
                        <span>{{$i18n.t('index.text36')}}</span>
                        <p>{{$i18n.t('index.text37')}}</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 合作流程 end -->

        <el-footer style="height: 36px;" v-if="lang === 'zh'">

            <el-link type="info" class="copyright" target="_blank" href="https://beian.miit.gov.cn">Copyright&nbsp;&nbsp;&nbsp;©2022&nbsp;&nbsp;&nbsp;广州朗国电子科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备14024048号</el-link>
        </el-footer>
    </el-container>
</template>

<script>

import HomeHeader from "@/views/index/components/homeHeader";
import {mapActions} from "vuex";

export default {

    name: "index",

    components: {HomeHeader},

    data() {

        return {
            lang: 'zh'
        }

    },

    beforeMount() {
        this.lang = window.localStorage.getItem('language');
    },
    mounted() {
        !this.lang && this.getCountryList()
    },

    methods: {
        ...mapActions('login', ['queryCountryList']),

        getCountryList() {
            this.queryCountryList().then(res => {
                res.result.forEach(item => {
                    if (location.hostname === 'localhost' || location.hostname === '192.168.154.98') {
                        window.localStorage.setItem('language', 'zh');
                        if (item.countryCode === 'CN') {
                            this.systemConfig = item.dataCenter.systemConfig;
                        }
                    }
                    if (location.hostname === item.dataCenter.server) {
                        this.systemConfig = item.dataCenter.systemConfig;
                        window.localStorage.setItem('language', item.countryCode === 'CN' ? 'zh' : 'en');
                    }
                })
                this.lang = window.localStorage.getItem('language');
            });
        },
    }

}

</script>

<style scoped>
.shadow {
    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    height: 64px !important;
    padding: 0;
    position: fixed;
    z-index: 1000;
    background-color: white;
}

.banner {
    width: 100%;
    margin-top: 64px;
    position: relative;
    text-align: center;
}

.banner-bg {
    width: 100%;
}

.banner-text {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    text-align: center;
    padding-top: 12%;
}

.banner-text span {
    font-weight: 500;
    font-size: 36px;
    color: #000000;
}

.banner-text p {
    font-weight: 500;
    width: 1000px;
    font-size: 20px;
    color: #333333;
    margin: 20px auto;
    line-height: 36px;
}

.module {
    width: 100%;
    height: 470px;
}

.module-text {
    width: 406px;
    text-align: left;
    margin: 0 auto;
    padding-top: 153px;
}

.module-text span {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}

.module-text p {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
}

.module-img {
    width: 406px;
    height: 357px;
    margin-top: 31px;
}

.hardware-module {
    width: 100%;
    height: 605px;
    background: #F5F7FB;
}

.hardware-module p, .ai-module > p {
    width: 978px;
    margin: 32px auto 48px auto;
    font-weight: 400;
    font-size: 20px;
    color: #333333;
    text-align: center;
}

.hardware-module .title, .cooperation-module .title, .ai-module .title {
    font-weight: 500;
    font-size: 36px;
    color: #000000;
    padding-top: 80px;
    display: block;
}

.table-header {
    width: 100%;
    height: 64px;
    background-image: linear-gradient(90deg, #82B6FF 0%, #1276FE 100%);
    border-radius: 8px 8px 0 0;
    margin: 0 auto;

    line-height: 64px;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
}


.table-body {
    width: calc(100% - 60);
    height: 168px;
    border-radius: 0 0 8px 8px;
    background-color: white;
    margin: 0 auto;
    text-align: left;
    padding: 30px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 28px;
}

.ai-module {
    width: 100%;
    height: 666px;
    background: #F7F7F7;
}

.ai-img {
    width: 240px;
    height: 160px;
    margin-bottom: 36px;
}

.ai-table-body {
    width: 240px;
    height: 300px;
    background: #FFFFFF;
    border: 0.6px solid rgba(0,0,0,0.26);
    border-radius: 12px;
    padding: 30px 36px;
    text-align: center;
}

.ai-item-title {
    font-weight: 500;
    font-size: 20px;
    color: #333333;
}

.ai-table-body p{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.cooperation-module {
    width: 100%;
    height: 512px;
}

.cooperation-module .el-row {
    width: 1200px;
    margin: 100px auto 0 auto;
}

.cooperation-module .el-col {
    text-align: center;
}

.cooperation-img {
    width: 88px;
    height: 88px;
}

.cooperation-module .el-col span {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
}

.cooperation-module .el-col p {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
}

.cooperation-module .el-col i {
    font-size: 24px;
    margin-top: 36%;
    color: #BCBCBC;
}

.copyright {
    line-height: 36px;
}

</style>
