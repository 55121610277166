<template>
    <div class="body">
        <div v-if="language === 'zh'">
            <div v-if="type === 'jjfa'">
                <div class="title">可能的原因及解决方法如下：</div>

                <div class="title1">1. 设备已被其他用户绑定</div>
                <div class="content">如果您不知道是谁绑定了设备，您可以尝试重置该设备。查看说明书了解重置方式，也可通过他人分享使用设备。</div>

                <div class="title1">2. 设备未进入配网状态</div>
                <div class="content">请在尝试手动添加设备，并按照说明使设备进入配网状态。如果您不确定如何操作，请查看产品说明书或联系产品提供商。</div>

                <div class="title1">3. 网络环境差</div>
                <div class="content">请确保设备所处位置信号良好，尽量让手机/平板靠近设备（建议小于1米）。</div>

                <div class="title1">4. 手机/平板无线网或蓝牙未开启</div>
                <div class="content">为了发现和添加设备，请确保手机/平板的无线网络和蓝牙功能均已开启。</div>

                <div class="title1">5. 设备未通电或电量不足</div>
                <div class="content">请确保设备已正常通电或电量充足。</div>
            </div>
            <div v-else-if="type === 'czms'">
                <div class="title1">方法一</div>
                <div class="content">
                    1. 使用已绑定该设备的小蜂连连APP账号登录APP。<br/>
                    2. 进入设备页面，长按智能门锁卡片，在弹出的信息框中选择“<span style="color: #1a1a1a;font-weight: bold;">删除设备</span>”进行解绑。<br/>
                    注意：解绑操作需要在门锁在线状态下进行。
                </div>
                <div class="title1">方法二</div>
                <div class="content">
                    1. 长按设备后锁板中间的“<span style="color: #1a1a1a;font-weight: bold;">菜单键</span>”6秒。<br/>
                    2. 输入<span style="color: #1a1a1a;font-weight: bold;">管理员密码</span>验证身份，完成解绑操作。
                </div>
                <div class="title1">方法三</div>
                <div class="content">
                    进入设备端<span style="color: #1a1a1a;font-weight: bold;">系统菜单</span>，选择<span style="color: #1a1a1a;font-weight: bold;">网络设置</span>，点击<span style="color: #1a1a1a;font-weight: bold;">重置网络</span>，完成解绑操作
                </div>
            </div>
            <div v-else-if="type === 'gywm'">
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-weight:bold">关于我们</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri; font-weight:bold">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri; font-weight:bold">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span>小蜂连连是广州朗国电子科技股份有限公司提供的智能设备控制、管理平台，广州朗国电子科技股份有限公司是专注于人工智能交互领域的解决方案商</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span>联系地址：广东省广州市黄埔区云升科学园</span>
                    <span style="font-family:Calibri">B1 15</span>
                    <span>层</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt">
                    <span style="font-size:10.5pt">联系电话：</span>
                    <span>86-20-38329601</span>
                </p>
            </div>
        </div>
        <div v-else>
            <div v-if="type === 'jjfa'">
                <div class="title">Possible Causes and Solutions:</div>

                <div class="title1">1. The device has been bound by another user</div>
                <div class="content">If you don't know who has bound the device, you can try to reset it. Refer to the manual for the reset method, or you can use the device through someone else's sharing.</div>

                <div class="title1">2. The device is not in network pairing mode</div>
                <div class="content">Please try to manually add the device and follow the instructions to put the device into network pairing mode. If you are unsure how to operate, please check the product manual or contact the product provider.</div>

                <div class="title1">3. Poor network environment</div>
                <div class="content">Please ensure that the device is in a location with a good signal and try to keep your phone/tablet close to the device (recommended within 1 meter).</div>

                <div class="title1">4. The phone/tablet's Wi-Fi or Bluetooth is not turned on</div>
                <div class="content">To discover and add the device, please ensure that the Wi-Fi and Bluetooth functions of your phone/tablet are both turned on.</div>

                <div class="title1">5. The device is not powered on or has insufficient battery</div>
                <div class="content">Please ensure that the device is powered on normally or has sufficient battery.</div>
            </div>
            <div v-else-if="type === 'czms'">
                <div class="title1">Method One</div>
                <div class="content">
                    1. Use the Xiaofeng Lianlian APP account that is bound to the device to log in to the APP.<br/>
                    2. Go to the device page, long press the smart lock card, and select "<span style="color: #1a1a1a;font-weight: bold;">Delete Device</span>" in the pop-up message box to unbind.<br/>
                    Note: The unbinding operation needs to be performed while the lock is online.
                </div>
                <div class="title1">Method Two</div>
                <div class="content">
                    1. Long press the "Menu Button" in the middle of the back panel of the device for 6 seconds.<br/>
                    2. Enter the <span style="color: #1a1a1a;font-weight: bold;">administrator password</span> to verify your identity and complete the unbinding operation.
                </div>
                <div class="title1">Method Three</div>
                <div class="content">
                    Enter the <span style="color: #1a1a1a;font-weight: bold;">System Menu</span> on the device, select <span style="color: #1a1a1a;font-weight: bold;">Network Settings</span>, and click <span style="color: #1a1a1a;font-weight: bold;">Reset Network</span> to complete the unbinding operation.
                </div>
            </div>
            <div v-else-if="type === 'gywm'">
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-weight:bold">About Us</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri; font-weight:bold">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri; font-weight:bold">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span>Xiao Feng Lian Lian is a smart device control and management platform provided by Guangzhou Langguo Electronic Technology Co., Ltd., a solution provider focused on the field of artificial intelligence interaction.</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span>Contact Address: Floor 15, Building B1, Yunsheng Science Park, Huangpu District, Guangzhou, Guangdong Province</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:10.5pt">
                    <span style="font-family:Calibri">&nbsp;</span>
                </p>
                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; widows:0; orphans:0; font-size:12pt">
                    <span style="font-size:10.5pt">Contact Number:</span>
                    <span>86-20-38329601</span>
                </p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "help",
    data() {
        return {
            type: "",
            language: ""
        }
    },
    mounted() {
        window.addEventListener('hashchange',this.onHASHChange);
        this.type = this.$route.query.type;
        this.language = this.$route.query.language;
    },
    methods: {
        onHASHChange() {
            this.type = this.$route.query.type;
            this.language = this.$route.query.language;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.onHASHChange);
    }
}
</script>

<style scoped>
    .body {
        margin: 1.5rem;
        text-align: left;
    }
    .title {
        font-weight: bold;
        font-size: 1rem;
        color: #1A1A1A;
    }
    .title1 {
        font-weight: bold;
        font-size: 0.88rem;
        color: #1A1A1A;
        margin-top: 1.5rem;
    }
    .content {
        font-weight: 400;
        font-size: 0.88rem;
        color: #666666;
        margin-top: 0.5rem;
    }
</style>
