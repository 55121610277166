<template>
    <!-- 公告详情抽屉 -->
    <el-drawer
        :title="noticeItemMsg.title"
        :visible.sync="drawer"
        direction="rtl"
        size="50%"
        @open="handleOpen"
        @close="handleClose">

        <!-- 发布公告人 -->
        <div class="user_name">{{ noticeItemMsg.userName }}</div>

        <!-- 发布时间 -->
        <div class="time">{{ noticeItemMsg.createTime }}</div>

        <!-- 公告内容 -->
        <div class="content  ql-editor" v-html="contents"></div>

    </el-drawer>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('main', ['noticeMsgDialogVisible', 'noticeMsg'])
    },

    watch: {

        //公告抽屉开关
        noticeMsgDialogVisible: {
            handler(newVal) {
                this.drawer = newVal;
            }
        },

        //公告详情
        noticeMsg: {
            handler(newVal) {
                this.noticeItemMsg = newVal;
            },
            deep: true
        }

    },

    data() {
        return {

            //抽屉开关柜
            drawer: false,

            //公告详情
            noticeItemMsg: {
                //公告code
                code: "",
                //公告标题
                title: "",
                //公告发布时间
                createTime: "",
                //公告发布人
                userName: ""
            },

            //公告详情
            contents: ''

        }

    },

    methods: {

        ...mapActions('main', ['closeNoticeMsgDialog', 'getNoticeDetailByCode']),

        /**
         * 关闭抽屉回调
         */
        handleClose() {
            this.closeNoticeMsgDialog();
        },

        /**
         * 打开抽屉回调
         */
        handleOpen() {
            //获取公告详情
            this.getNoticeDetailByCode({
                code: this.noticeItemMsg.code
            }).then(res => {
                this.contents = res.result.contents;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        }

    }

}
</script>

<style scoped>
.content {
    padding: 10px 16px;
    text-align: left;
}

.time, .user_name {
    text-align: center;
    font-size: 12px;
}

.time {
    margin-top: 10px;
}

/deep/ .el-drawer__header {
    font-weight: bold;
    font-size: 20px;
    color: black;
    text-align: center;
}
</style>
