<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="type == 0 ? $i18n.t('member.text20') : $i18n.t('member.text21')"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item prop="areaNumber" :label="$i18n.t('member.text22')" :error="areaErr" v-if="type == 0">
                <el-select v-model="form.areaNumber" @change="() => areaErr = ''" style="width: 100%;" :placeholder="$i18n.t('member.text23')">
                    <el-option
                        v-for="item in areaNumberList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$i18n.t('member.text24')" :error="userNameErr" prop="userId" v-if="type == 0">
                <el-input :placeholder="$i18n.t('member.text25')" @focus="() => userNameErr = ''" :disabled="isSearch" v-model.trim="form.userId"
                          class="input-with-select">
                    <el-button slot="append" :disabled="isSearch" :loading="searchLoading" icon="el-icon-search"
                               @click="searchUser"></el-button>
                </el-input>
                <el-card
                    v-show="userMsg.userId">
                    <el-button type="text" icon="el-icon-error" size="medium" @click="cleanUser()"
                               class="close_img"></el-button>
                    <el-row>
                        <el-col :span="8">
                            <el-avatar icon="el-icon-s-custom" v-if="!userMsg.headImg"></el-avatar>
                            <el-avatar v-else :src="userMsg.headImg"></el-avatar>
                        </el-col>
                        <el-col :span="16">
                            <div style="color: #000;font-size: 14px;margin-top:5px;">{{ userMsg.userId }}</div>
                            <div style="color: #888;font-size: 12px;margin-bottom:5px;">{{ userMsg.userName }}</div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-form-item>
            <el-form-item :label="$i18n.t('member.text26')">
                <el-input
                    type="text"
                    maxlength="20"
                    v-model.trim="form.remarks"
                    :placeholder="$i18n.t('member.text27')">
                </el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('member.text28')" prop="role">
                <!-- <el-select v-model="form.role" placeholder="选择角色">
                    <el-option
                        v-for="item in roleList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select> -->
                <select-tree
                    ref="roleSelectTree"
                    :treedata="roleTreeList"
                    :nodeKey="'code'"
                    @setParentMenu="changeRole">
                </select-tree>
            </el-form-item>
            <el-form-item :label="$i18n.t('member.text29')" required>
                <el-radio-group v-model="form.productId" @change="selectRadio">
                    <el-radio label="Y">{{$i18n.t('member.text30')}}</el-radio>
                    <el-radio label="N">{{$i18n.t('member.text31')}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-show="isShowProduct" :error="selectErr">
                <!-- <el-select v-model="form.productIdList" multiple placeholder="请选择产品">
                    <el-option
                        v-for="item in productList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select> -->
                <el-button type="primary" @click="selectProduct">{{$i18n.t('member.text32')}}</el-button>
                <div v-show="selectMsg.nameList">{{ selectMsg.nameList }}</div>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">{{$i18n.t('member.text33')}}</el-button>
            <el-button type="primary" :loading="loading" @click="type == 0 ? submit() : updateUser()">{{$i18n.t('member.text34')}}</el-button>
        </span>
        <select-product-dialog ref="selectProductDialog" @change="selectProductChange"></select-product-dialog>
    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";
import selectTree from '@/components/selectTree';
import selectProductDialog from '@/views/setting/views/member/components/dialog/selectProductDialog';

export default {

    components: {
        selectTree,
        selectProductDialog
    },

    computed: {
        ...mapState('member', ['userDialogVisible', 'userDialogType', 'userItemMsg']),
        ...mapState('roleManage', ['roleMsg'])
    },

    watch: {

        userDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        userDialogType: {
            handler(newVal) {
                console.log("dialog", newVal)
                this.type = newVal;
            }
        },

        userItemMsg: {
            handler(newVal) {
                this.form = {
                    userId: newVal.userId,
                    remarks: newVal.remark,
                    role: newVal.roleCode,
                    productId: newVal.allProduct,
                    productIdList: "",
                    code: newVal.code
                };
                this.$nextTick(() => {
                    this.isShowProduct = newVal.allProduct == 'N';
                    this.$refs.roleSelectTree.setMineStatus(newVal.roleName);
                    if (newVal.allProduct == 'N') {
                        this.selectProductChange(newVal.memberProductList);
                    }
                })
            }
        },

        roleMsg: {
            handler(newVal) {
                this.roleTreeList = newVal;
            },
            deep: true
        }

    },

    data() {

        return {
            visible: false,
            isSearch: false,
            formLabelWidth: "100px",
            loading: false,
            type: 0,
            isShowProduct: false,
            searchLoading: false,
            areaErr: "",
            userNameErr: "",
            form: {
                areaNumber: "",
                userId: "",
                remarks: "",
                role: "",
                productId: "Y",
                productIdList: ""
            },
            userMsg: {
                headImg: "",
                userId: "",
                userName: ""
            },
            rules: {
                areaNumber: [{required: true, message: this.$i18n.t('member.text23'), trigger: "blur"}],
                userId: [{required: true, message: this.$i18n.t('member.text35'), trigger: "blur"}],
                role: [{required: true, message: this.$i18n.t('member.text36'), trigger: "blur"}]
            },
            roleTreeList: [],
            productList: [
                {id: 1, name: "朗国水杯"},
                {id: 2, name: "朗国插座"},
                {id: 3, name: "朗国电灯"},
            ],
            selectMsg: {},
            selectErr: "",
            selectData: [],
            areaNumberList: [],
            language: localStorage.getItem('language')
        }

    },

    mounted() {
        this.formLabelWidth = this.language === 'zh' ? '100px' : '180px'
    },

    methods: {

        ...mapActions('member', ['closeUserDialog', 'findUserBaseInfo', 'addMember', 'modifyMember']),
        ...mapActions('roleManage', ['roleTree']),
        ...mapActions('login', ['queryCountryListInDataCenter']),


        getCountryListInDataCenter() {
            this.queryCountryListInDataCenter().then(res => {
                let list = [];
                res.result.forEach(item => {
                    list.push({
                        value: item.areaNumber,
                        label: item.localeName
                    });
                    if (item.areaNumber === this.userMsg.areaNumber) {
                        this.systemConfig = item.dataCenter.systemConfig
                    }
                })
                this.areaNumberList = list;
            })
        },

        cleanUser() {
            this.userMsg = {
                userId: "",
                userName: ""
            };
            this.form.userId = "";
            this.isSearch = false;
        },

        searchUser() {
            if (!this.form.areaNumber) {
                this.areaErr = this.$i18n.t('member.text23')
                return;
            }
            if (!this.form.userId) {
                this.userNameErr = this.$i18n.t('member.text25')
                return;
            }
            this.searchLoading = true;
            this.findUserBaseInfo({
                userName: this.form.userId,
                areaNumber: this.form.areaNumber
            }).then(res => {
                this.searchLoading = false;
                this.userMsg = res.result;
                this.isSearch = true;
            }, err => {
                this.searchLoading = false;
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },

        closeDialog() {
            this.$refs.ruleForm.resetFields();
            this.isSearch = false;
            this.form = {
                areaNumber: "",
                userId: "",
                remarks: "",
                role: "",
                productId: "Y",
                productIdList: ""
            };
            this.userMsg = {
                userId: "",
                userName: ""
            };
            this.$refs.roleSelectTree.setMineStatus("")
            this.selectErr = "";
            this.selectMsg = {};
            this.selectData = [];
            this.isShowProduct = false;
            this.closeUserDialog();
        },

        openDialog() {
            this.roleTree();
            this.getCountryListInDataCenter();
            this.type = this.userDialogType;
        },

        selectRadio(val) {
            console.log(val);
            this.isShowProduct = (val == 'N')
        },

        selectProductChange(e) {
            this.selectData = [];
            let codeList = [];
            let nameList = [];
            for (let i = 0; i < e.length; i++) {
                console.log(e[i].productCode ? e[i].productCode : e[i].code)
                e[i].code = e[i].productCode ? e[i].productCode : e[i].code;
                codeList.push(e[i].productCode ? e[i].productCode : e[i].code);
                nameList.push(e[i].name ? e[i].name : e[i].productName);
            }
            this.selectMsg = {
                codeList,
                nameList: nameList.join("、")
            }
            this.selectData = e;
        },

        selectProduct() {
            this.$store.commit("member/SET_SELECT_PRODUCT_DIALOG_VISIBLE", true);
            this.$refs.selectProductDialog.setMsg(this.$config.deepCopy(this.selectData))
            console.log(this.selectData)
        },

        /**
         * 选择角色回调
         * @param {选择的角色对象} data
         */
        changeRole(data) {
            this.form.role = data.code;
        },

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (this.form.productId == 'N' && (!this.selectMsg.codeList || this.selectMsg.codeList.length == 0)) {
                    this.selectErr = this.$i18n.t('member.text37')
                    return;
                }
                this.selectErr = "";
                if (valid) {
                    this.loading = true;
                    this.addMember({
                        allProduct: this.form.productId,
                        productCodes: this.form.productId == 'N' ? this.selectMsg.codeList.toString() : null,
                        remark: this.form.remarks,
                        roleCode: this.form.role,
                        userId: this.userMsg.userId
                    }).then(res => {
                        this.loading = false;
                        this.$dialog.showMessage(this.$i18n.t('member.text38'), this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                        this.closeDialog();
                    }, err => {
                        this.loading = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg ? err.resultMsg : this.$i18n.t('member.text39'), this.$config.TOAST_ERROR);
                    })
                }
            })
        },

        updateUser() {
            this.$refs.ruleForm.validate((valid) => {
                if (this.form.productId == 'N' && (!this.selectMsg.codeList || this.selectMsg.codeList.length == 0)) {
                    this.selectErr = this.$i18n.t('member.text37')
                    return;
                }
                this.selectErr = "";
                if (valid) {
                    this.loading = true;
                    this.modifyMember({
                        code: this.form.code,
                        productCodes: this.form.productId == 'N' ? this.selectMsg.codeList.toString() : null,
                        remark: this.form.remarks,
                        roleCode: this.form.role,
                        allProduct: this.form.productId
                    }).then(res => {
                        this.loading = false;
                        this.$dialog.showMessage(this.$i18n.t('member.text40'), this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                        this.closeDialog();
                    }, err => {
                        this.loading = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg ? err.resultMsg : this.$i18n.t('member.text39'), this.$config.TOAST_ERROR);
                    })
                }
            });
        }

    },
}
</script>

<style scoped>
.el-select {
    width: 100%;
}

.el-avatar {
    margin-top: 17px;
}

.el-card {
    width: 50%;
    margin-top: 10px;
    position: relative;
}

.close_img {
    color: black;
    position: absolute;
    right: 4px;
    margin-top: -20px;
    z-index: 9999;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-form {
    margin-top: 20px;
}
</style>
