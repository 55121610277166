<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="(type === 0 ? $i18n.t('userInfo.text29') : $i18n.t('userInfo.text30'))"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <el-form :model="form" size="small" style="margin-top: 20px;" :rules="rules" ref="ruleForm">
            <el-form-item prop="areaNumber">
                <el-select v-model="form.areaNumber" style="width: 100%;" :placeholder="$i18n.t('userInfo.text31')" v-if="type === 0">
                    <el-option
                        v-for="item in areaNumberList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :error="phoneErr" v-if="type === 0">
                <el-input
                    type="text"
                    @focus="() => phoneErr = ''"
                    v-model.trim="form.username"
                    :placeholder="$i18n.t('userInfo.text32')">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item :error="emailErr" v-else>
                <el-input
                    type="text"
                    @focus="() => emailErr = ''"
                    v-model.trim="form.username"
                    :placeholder="$i18n.t('userInfo.text33')">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input
                    v-model.trim="form.code"
                    :placeholder="$i18n.t('userInfo.text34')">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">{{$i18n.t('userInfo.text35')}}</el-button>
			<el-button type="primary" :loading="loading" @click="submit">{{$i18n.t('userInfo.text36')}}</el-button>
		</span>
    </el-dialog>

</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "bindEmailOrTelDialog",

    computed: {
        ...mapState('setting', ['bindEmailOrTelDialogVisible', 'bindEmailOrTelType']),
        ...mapState('login', ['userInfo'])
    },

    watch: {

        bindEmailOrTelDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        bindEmailOrTelType: {
            handler(newVal) {
                this.type = newVal;
            }
        }

    },

    props: {
        areaNumberList: {
            Type: Array,
            default: []
        }
    },

    data() {
        return {
            loading: false,
            visible: false,
            type: 0,
            phoneErr: "",
            emailErr: "",
            form: {
                username: "",
                areaNumber: "",
                code: "",
                smsId: ""
            },
            codeTime: null,
            codeBtnLoading: false,
            time: 60,
            isCodeBtnDisabled: false,
            codeBtnMsg: this.$i18n.t('userInfo.text37'),
            rules: {
                areaNumber: [{required: true, message: this.$i18n.t('userInfo.text38'), trigger: "blur"}],
                code: [{required: true, message: this.$i18n.t('userInfo.text39'), trigger: "blur"}]
            },
        }
    },

    methods: {

        ...mapActions('setting', ['closeBindEmailOrTelDialog']),

        ...mapActions('login', ['sendSms', 'getlogininfo', 'modifyEmail', 'isAllowRegistration', 'modifyPhone']),

        closeDialog() {
            this.cleanCountDown();
            this.closeBindEmailOrTelDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                username: "",
                areaNumber: "",
                code: "",
                smsId: ""
            };
        },

        openDialog() {
            this.form.areaNumber = this.userInfo.areaNumber;
        },

        async getCode() {
            if (this.type === 0) {
                if (!this.$config.phoneRight(this.form.username)) {
                    this.phoneErr = this.$i18n.t('userInfo.text40')
                    return;
                }
                if (!this.form.username) {
                    this.phoneErr = this.$i18n.t('userInfo.text32')
                    return;
                }
            } else {
                if (!this.$config.emailRight(this.form.username)) {
                    this.emailErr = this.$i18n.t('userInfo.text41')
                    return;
                }
                if (!this.form.username) {
                    this.emailErr = this.$i18n.t('userInfo.text33')
                    return;
                }
            }

            let isRegistred = false;
            await this.isAllowRegistration({
                platformType: 2,
                userName: this.form.username,
                areaNumber: this.form.areaNumber
            }).catch(err => {
                isRegistred = true;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
            if (isRegistred) {
                return
            }

            this.phoneErr = ""
            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.form.username,
                type: this.type === 0 ? '4' : '9',
                areaNumber: this.form.areaNumber
            }).then(res => {
                this.form.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + this.$i18n.t('userInfo.text42')
            this.codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + this.$i18n.t('userInfo.text42')
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = this.$i18n.t('userInfo.text43')
                    this.isCodeBtnDisabled = false;
                    clearInterval(this.codeTime);
                }
            }, 1000)
        },

        cleanCountDown() {
            if (this.codeTime) {
                clearInterval(this.codeTime);
            }
            this.time = 60;
            this.codeBtnLoading = false;
            this.codeBtnMsg = this.$i18n.t('userInfo.text37');
            this.isCodeBtnDisabled = false;
        },

        submit() {
            if (!this.form.smsId) {
                this.$dialog.showMessage(this.$i18n.t('userInfo.text44'), this.$config.TOAST_WARNING);
                return
            }
            let isStart = true;
            if (this.type === 0) {
                if (!this.$config.phoneRight(this.form.username)) {
                    this.phoneErr = this.$i18n.t('userInfo.text40')
                    isStart = false;
                }
                if (!this.form.username) {
                    this.phoneErr = this.$i18n.t('userInfo.text32')
                    isStart = false;
                }
            } else {
                if (!this.$config.emailRight(this.form.username)) {
                    this.emailErr = this.$i18n.t('userInfo.text41')
                    isStart = false;
                }
                if (!this.form.username) {
                    this.emailErr = this.$i18n.t('userInfo.text33')
                    isStart = false;
                }
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    this.loading = true;
                    if (this.type === 0) {
                        this.modifyPhone({
                            code: this.form.code,
                            newPhone: this.form.username,
                            smsId: this.form.smsId,
                            areaNumber: this.form.areaNumber
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('userInfo.text45'), this.$config.TOAST_SUCCESS);
                            this.closeDialog();
                            this.$emit('getUserInfo');
                            this.getlogininfo();
                        }, err => {
                            console.log(err);
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false;
                        })
                    } else {
                        this.modifyEmail({
                            code: this.form.code,
                            email: this.form.username,
                            smsId: this.form.smsId
                        }).then(res => {
                            this.$dialog.showMessage(this.$i18n.t('userInfo.text45'), this.$config.TOAST_SUCCESS);
                            this.closeDialog();
                            this.$emit('getUserInfo');
                            this.getlogininfo();
                        }, err => {
                            console.log(err);
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        }).finally(() => {
                            this.loading = false;
                        })
                    }
                }
            });
        }

    }
}
</script>

<style scoped>
/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

/deep/ .el-dialog__footer {
    text-align: right;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}
</style>
