<template>
    <el-card
        :body-style="{
            textAlign: 'left',
            padding: '15px',
            height: '100%'
        }"
        shadow="always">
        <div slot="header" class="clearfix">
            <el-row>
                <el-col :span="1" style="width: 30px;">
                    <i class="el-icon-back" @click="back"></i>
                </el-col>
                <el-col :span="23">
                    <el-breadcrumb separator="/" style="float: left;">
                        <el-breadcrumb-item>
                            产品
                        </el-breadcrumb-item>
                        <el-breadcrumb-item>面板配置</el-breadcrumb-item>
                    </el-breadcrumb>
                </el-col>
            </el-row>
        </div>

        <el-upload
            class="panel-upload"
            v-loading="loadImg"
            drag
            accept=".png,.jpg"
            :on-success="onSuccess"
            :on-error="onError"
            :on-progress="onProgress"
            :before-upload="onUpdateBefor"
            :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/">
            <div v-if="!file.name">
                <i class="el-icon-upload" style="margin-top: 40px"></i>
                <div class="el-upload__text">
                    将封面图拖拽到此处上传，或<em>点击上传</em>
                    <span class="subtitle">(支持png、jpg格式，尺寸750px*1334px)</span>
                </div>
            </div>
            <div style="margin-top: 110px;font-size: 20px;color: #0AADC1;" v-else>
                <span>{{ file.name }}</span>
                <el-button type="text" style="padding: 0px;"
                           icon="el-icon-delete" @click.stop="delBtn1()"></el-button>
            </div>
        </el-upload>

        <el-upload
            class="panel-upload1"
            v-loading="loadImg1"
            drag
            accept=".tar.gz"
            :on-success="onSuccess1"
            :on-error="onError1"
            :on-progress="onProgress1"
            :before-upload="onUpdateBefor1"
            :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/">
            <div v-if="!file.name">
                <i class="el-icon-upload" style="margin-top: 40px"></i>
                <div class="el-upload__text">
                    将UI包拖拽到此处上传，或<em>点击上传</em>
                    <span class="subtitle">(支持.tar.gz格式，小于20M)</span>
                </div>
            </div>
            <div style="margin-top: 110px;font-size: 20px;color: #0AADC1;" v-else>
                <span>{{ file.name }}</span>
                <el-button type="text" style="padding: 0px;"
                           icon="el-icon-delete" @click.stop="delBtn2()"></el-button>
            </div>
        </el-upload>
        <div class="release">
            <el-button type="primary" class="btn_release">发 布</el-button>
        </div>
    </el-card>
</template>

<script>
export default {
    data() {
        return {
            file: {},
            file1: {},
            loadImg: false,
            loadImg1: false,
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        onUpdateBefor(file) {
            if (this.$config.getType(file.name) == '.png' || this.$config.getType(file.name) == '.jpg') {
                this.loadImg = true;
            } else {
                this.$dialog.showMessage('只能上传.png.jpg', this.$config.TOAST_WARNING);
                return false
            }
        },
        onProgress(event, file, fileList) {
            console.log('onProgress', event, file, fileList)
        },
        onSuccess(response, file, fileList) {
            this.loadImg = false;
            if (response.success) {
                this.$dialog.showMessage('文件上传成功', this.$config.TOAST_SUCCESS);
                this.file = file;
                this.file.url = response.result.fileList[0].url
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
            console.log('onSuccess', response, file, fileList)
        },
        onError(err, file, fileList) {
            this.loadImg = false;
            this.$dialog.showMessage('文件上传失败', this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },


        onUpdateBefor1(file) {
            if (this.$config.getType(file.name) == '.tar.gz') {
                this.loadImg1 = true;
            } else {
                this.$dialog.showMessage('只能上传.tar.gz', this.$config.TOAST_WARNING);
                return false
            }
        },
        onProgress1(event, file, fileList) {
            console.log('onProgress1', event, file, fileList)
        },
        onSuccess1(response, file, fileList) {
            this.loadImg1 = false;
            if (response.success) {
                this.$dialog.showMessage('文件上传成功', this.$config.TOAST_SUCCESS);
                this.file1 = file;
                this.file1.url = response.result.fileList[0].url
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
            console.log('onSuccess1', response, file, fileList)
        },
        onError1(err, file, fileList) {
            this.loadImg1 = false;
            this.$dialog.showMessage('文件上传失败', this.$config.TOAST_ERROR);
            console.log('onError1', err, file, fileList)
        },
    },
}
</script>

<style scoped>
.el-card {
    height: 100%;
}

/deep/ .el-card__header {
    padding: 12px !important;
    text-align: left;
    font-size: 16px;
}

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
}

.el-icon-back {
    font-size: 20px !important;
    font-weight: bold;
    float: left;
    cursor: pointer;
}

.subtitle {
    color: #AAAAAA;
}

.upload, /deep/ .el-upload, /deep/ .el-upload-dragger {
    width: 100%;
    height: 200px;
}

.release {
    text-align: center;
}

.btn_release {
    margin-top: 20px;
    width: 300px;
}

.panel-upload1 {
    margin-top: 10px;
}
</style>
