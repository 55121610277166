<template>
    <div class="main">
        <div style="height:100%;">
<!--            <el-button type="text" class="document_center" icon="el-icon-tickets" @click="openDoc()">文档中心</el-button>-->
            <img src="../../assets/login_left.png" class="login_left" @load="loadImage">
            <div class="login_right" v-if="isShowRight" :style="{
                width: 'calc(100% - ' + (loginLeftWidth + 1) + 'px)',
                textAlign: 'center'
            }">

                <div class="right-top">
                    <img src="../../assets/login_logo.png" class="logo" :style="{left: (30 + loginLeftWidth) + 'px'}"/>
                    <div class="right-top-right">
                        <el-select v-model="dataCenters" class="data_centers" size="mini" @change="selectDataCenters" :placeholder="$i18n.t('login.text1')">
                            <el-option
                                v-for="item in countryList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="language" style="width: 120px;" @change="selectLanguage" class="language" size="mini" :placeholder="$i18n.t('login.text2')">
                            <img src="../../assets/lang.png" style="width: 14px;height: 14px;margin-top: 7px;margin-left: 4px;" slot="prefix"/>
                            <el-option
                                v-for="item in languageList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="card-form" :style="{
                    height: activeName === 'register' ? '470px' : '425px',
                    margin: `${activeName === 'register' ? '-230px' : '-220px'} auto`
                }" v-show="isLoadEnd">
                    <el-tabs v-model="activeName" @tab-click="switchTab">
                        <el-tab-pane :label="$i18n.t('login.text3')" name="login">
                            <login ref="login" :language="language" :dataCenters="dataCenters" :areaNumberList="areaNumberList" :systemConfig="systemConfig"></login>
                        </el-tab-pane>
                        <el-tab-pane :label="$i18n.t('login.text4')" name="register">
                            <register ref="register" :language="language" :dataCenters="dataCenters" :areaNumberList="areaNumberList" :systemConfig="systemConfig" @registerSuccess="registerSuccess"></register>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <el-link v-if="language === 'zh'" type="info" class="copyright" target="_blank" href="https://beian.miit.gov.cn">Copyright&nbsp;&nbsp;&nbsp;©2022&nbsp;&nbsp;&nbsp;广州朗国电子科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备14024048号</el-link>
                <div v-else></div>
            </div>
        </div>

        <!-- 重置密码弹窗 -->
        <reset-password-dialog :language="language" :dataCenters="dataCenters" :areaNumberList="areaNumberList" :systemConfig="systemConfig"></reset-password-dialog>

        <!-- 设置密码弹窗 -->
        <set-password-dialog></set-password-dialog>

    </div>
</template>

<script>
import login from '@/views/login/components/login'
import register from '@/views/login/components/register'
import resetPasswordDialog from '@/views/login/components/dialog/resetPasswordDialog'
import setPasswordDialog from '@/views/login/components/dialog/setPasswordDialog'
import {mapActions} from "vuex";

export default {

    data() {
        return {

            //当前表单（login：登录  register：注册）
            activeName: "login",

            isLoadEnd: false,

            loginLeftWidth: 0,

            //是否展示右侧表单
            isShowRight: false,

            countryList: [],

            areaNumberList: [],

            dataCenters: '',

            language: '',

            languageList: [
                {value: 'zh', label: '中文'},
                {value: 'en', label: 'English'},
            ],

            systemConfig: {
                "supportTelephone": true,
                "supportWechat": false,
                "supportMicroApp": false,
                "supportApple": false,
                "supportEmail": false,
                "supportGzh": false
            }
        }
    },

    components: {
        login,
        register,
        resetPasswordDialog,
        setPasswordDialog
    },

    mounted() {
        this.language = window.localStorage.getItem('language');
        this.isLoadEnd = true;
        this.activeName = this.$route.query.type ? this.$route.query.type : "login";
        window.addEventListener('resize', this.setHeight);
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight)
    },

    methods: {

        ...mapActions('login', ['queryCountryList', 'queryCountryListInDataCenter']),

        getCountryList() {
            this.queryCountryList().then(res => {
                let list = [];
                res.result.forEach(item => {
                    if (location.hostname === 'localhost' || location.hostname === '192.168.154.98') {
                        !this.language && window.localStorage.setItem('language', 'zh');
                        if (item.dataCenter.datacenterCode === 'D1') {
                            this.systemConfig = item.dataCenter.systemConfig;
                            this.dataCenters = item.dataCenter.datacenterCode;
                        }
                    }
                    if (location.hostname === item.dataCenter.server) {
                        this.systemConfig = item.dataCenter.systemConfig;
                        !this.language && window.localStorage.setItem('language', item.countryCode === 'CN' ? 'zh' : 'en');
                        this.dataCenters = item.dataCenter.datacenterCode;
                    }
                    let language = window.localStorage.getItem('language');
                    let label = JSON.parse(item.dataCenter.datacenterName);
                    list.push({
                        value: item.dataCenter.datacenterCode,
                        label: language === 'zh' ? label.zh_CN : label.en,
                        dataCenter: item.dataCenter
                    })
                })
                this.language = window.localStorage.getItem('language');
                this.countryList = list;
            });
        },

        getCountryListInDataCenter() {
            this.queryCountryListInDataCenter().then(res => {
                console.log(res);
                let list = [];
                res.result.forEach(item => {
                    let label = JSON.parse(item.countryName);
                    let language = window.localStorage.getItem('language');
                    list.push({
                        value: item.areaNumber,
                        label: language === 'zh' ? label.zh_CN : label.en,
                    })
                })
                this.areaNumberList = list;
            })
        },

        /**
         * 打开文档中心
         */
        openDoc() {
            window.open(this.$message.docUrl, '_blank')
        },

        selectDataCenters(event) {
            console.log(event)
            this.countryList.forEach(item => {
                if (event === item.value) {
                    window.open(`${item.dataCenter.protocol}://${item.dataCenter.server}`, '_self')
                }
            })
        },

        selectLanguage(event) {
            console.log(event)
            window.localStorage.setItem('language', event);
            this.$router.go(0);
        },

        /**
         * 设置高度
         */
        setHeight() {
            this.loginLeftWidth = document.getElementsByClassName('login_left')[0].offsetWidth;
        },

        /**
         * 左侧图片加载完毕回调
         */
        loadImage() {
            //获取左侧图片宽度
            this.loginLeftWidth = document.getElementsByClassName('login_left')[0].offsetWidth;
            this.isShowRight = true;
            this.getCountryList();
            this.getCountryListInDataCenter();
        },

        /**
         * 顶部登录与注册切换
         * @param tab
         */
        switchTab(tab) {
            this.$config.changeURLArg('type', tab._props.name)
            this.$refs[tab._props.name].initForm();
        },

        /**
         * 注册完毕后回调
         */
        registerSuccess() {
            this.activeName = "login";
        }

    }

}
</script>

<style scoped>

.main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F7F7F7;
}

.login_left {
    float: left;
    height: 100%;
}

.login_right {
    height: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.right-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.right-top-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.card-form {
    width: 380px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 15px;
}

/deep/ .el-tabs__nav-wrap::after {
    height: 0 !important;
}

/deep/ .el-tabs__nav-scroll {
    display: flex!important;
    justify-content: center!important;
}

/deep/ .el-tabs__item {
    height: 30px!important;
    line-height: 30px!important;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
}

.document_center {
    position: absolute;
    top: 5px;
    right: 20px;
    color: #838383;
}

.language {
    margin-right: 15px;
}

.data_centers {
    margin-right: 10px;
}

.logo {
    margin: 15px;
}

.copyright {
    text-align: center;
    margin-bottom: 16px;
}
</style>
