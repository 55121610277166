<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="新增用户"
        :visible.sync="visible"
        width="30%"
        @close="closeDialog"
        @open="openDialog"
        append-to-body
        center>

        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="手机号：" prop="phone">
                <el-input v-model.trim="form.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "addUserDialog",

    computed: {
        ...mapState('maintain', ['addUserDialogVisible'])
    },

    watch: {

        addUserDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            form: {
                phone: ""
            },

            rules: {
                phone: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {pattern: /^0?1[0-9]{10}$/, message: '手机号格式不正确'}
                ]
            }

        }

    },

    methods: {

        ...mapActions('maintain', ['closeAddUserDialog']),

        openDialog() {},

        closeDialog() {
            this.closeAddUserDialog();
        }

    }

}
</script>

<style scoped>

</style>
