<template>
    <el-card
        class="licenseFrom_table"
        :body-style="{
            textAlign: 'left',
            padding: '15px'
        }"
        shadow="never">
        <div slot="header" class="clearfix">
            <span>License列表</span>
        </div>

        <el-empty
            v-if="empowerMsg.state !== 1"
            :description="empowerMsg.state === 0 ? '待审核中' : '审核未通过'"
            :image="empowerMsg.state === 0 ? require('../../../assets/icon_review0.png') : require('../../../assets/icon_review2.png')"></el-empty>

        <div class="header_btn"
             v-if="empowerMsg.state === 1">
            <el-form :inline="true" size="small">
                <el-form-item>
                    <el-input
                        v-model="licenseFrom.keyword"
                        clearable
                        suffix-icon="el-icon-search"
                        placeholder="License搜索"
                        @clear="getMsg(1)"
                        @keydown.enter.native="getMsg(1)"
                        @blur="getMsg(1)"/>
                </el-form-item>

                <el-form-item>
                    <el-select
                        v-model="licenseFrom.deviceState"
                        :popper-append-to-body="false"
                        clearable
                        placeholder="全部"
                        @change="getMsg(1)">
                        <el-option
                            v-for="item in $message.deviceStateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item style="float: right;">
                    <el-button class="btn" size="small" type="primary" @click="exportLicense" plain>
                        导出
                    </el-button>
                    <el-button class="btn" size="small" type="primary" @click="addLicense" plain>
                        新增License
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table
            v-if="empowerMsg.state === 1"
            ref="licenseTable"
            class="license_table"
            :data="licenseMsg.list"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            v-loading="loading"
            style="width: 100%;">
            <el-table-column
                prop="license"
                label="License"
            >
            </el-table-column>
            <el-table-column
                label="激活状态"
            >
                <template slot-scope="scope">
                    {{ $config.getMsgItemUtil($message.deviceStateList, scope.row.status, 'id', 'name') }}
                </template>
            </el-table-column>
            <el-table-column
                label="激活日期"
            >
                <template slot-scope="scope">
                    {{ scope.row.activationTime ? scope.row.activationTime : "—" }}
                </template>
            </el-table-column>

        </el-table>

        <el-pagination
            background
            v-if="empowerMsg.state === 1"
            :current-page="page"
            @current-change="handleCurrentChange"
            :page-size="10"
            layout=" prev, pager, next"
            style="float: right;margin-top: 10px;margin-bottom: 10px;"
            :total="parseInt(licenseMsg.totalRecords)">
        </el-pagination>

        <!-- 导出license -->
        <export-license-dialog></export-license-dialog>

        <!-- 新增license -->
        <add-license-dialog @getMsg="getMsg"></add-license-dialog>

    </el-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ExportLicenseDialog from "@/views/empower/components/dialog/exportLicenseDialog";
import AddLicenseDialog from "@/views/empower/components/dialog/addLicenseDialog";

export default {

    name: "licenseTable",

    components: {AddLicenseDialog, ExportLicenseDialog},

    computed: {
        ...mapState('empower', ['itemMsg'])
    },

    watch: {
        itemMsg: {
            handler(newVal) {
                this.empowerMsg = newVal;
            },
            deep: true
        }
    },

    data() {

        return {
            loading: false,

            licenseFrom: {
                keyword: "",
                deviceState: ""
            },

            page: 1,

            empowerMsg: {},

            licenseMsg: {}

        }

    },

    mounted() {
        this.getMsg();
    },

    methods: {

        ...mapActions('empower', ['queryLicense']),

        exportLicense() {
            this.$store.commit('empower/SET_EXPORT_LICENSE_DIALOG_VISIBLE', true);
        },

        addLicense() {
            this.$store.commit('empower/SET_ADD_LICENSE_DIALOG_VISIBLE', true);
        },

        /**
         * 获取已激活设备信息
         */
        getMsg(page) {
            if (page) this.page = 1;
            this.loading = true
            this.queryLicense({
                authorizationCode: this.$route.query.code,
                currPage: this.page,
                pageSize: 10,
                status: this.licenseFrom.deviceState,
                license: this.licenseFrom.keyword
            }).then(res => {
                this.licenseMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg()
        }

    }

}
</script>

<style scoped>
.authorization_table {
    width: 100%;
}

.el-card {
    margin: 20px 0 !important;
}

/deep/ .el-card__header {
    padding: 10px;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}

.el-select {
    width: 150px;
}
</style>
