var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"close-on-click-modal":false,"close-on-press-escape":false,"title":"个人信息","visible":_vm.visible,"width":"35%","center":""},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.closeDialog,"open":_vm.openDialog}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form,"size":"small","rules":_vm.rules,"label-width":_vm.formLabelWidth}},[_c('el-form-item',{attrs:{"label":"用户ID："}},[_vm._v(" "+_vm._s(_vm.form.userId)+" ")]),_c('el-form-item',{attrs:{"label":"昵称：","prop":"nickname"}},[_c('el-input',{attrs:{"type":"text","maxlength":"20","show-word-limit":"","placeholder":"请输入昵称"},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.nickname"}})],1),_c('el-form-item',{attrs:{"label":"头像："}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.$message.uploadImgUrl,"data":{
                    bucket: 'user'
                },"headers":{
                    accessToken: _vm.$token.getToken().accessToken,
                    refreshToken: _vm.$token.getToken().refreshToken,
                    language: _vm.$message.language
                },"show-file-list":false,"accept":".png,.jpeg,.jpg,.gif,.bmp","on-success":_vm.handleAvatarSuccess,"on-error":_vm.onErr,"before-upload":_vm.beforeAvatarUpload}},[(_vm.form.headImg)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.form.headImg}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('span',{staticClass:"tips"},[_vm._v("头像尺寸为30*30，格式为png、jpeg、jpg、gif、bmp")])],1),_c('el-form-item',{attrs:{"label":"地址：","prop":"address"}},[_c('el-input',{attrs:{"type":"text","maxlength":"50","show-word-limit":"","placeholder":"请输入地址"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.address"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("确 认")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }