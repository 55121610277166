<template>
    <div class="main">
        <el-row class="main_row">
            <el-col :span="24" class="main_col">
                <product-table ref="productTable"></product-table>
            </el-col>
        </el-row>
        <add-product-dialog @getMsg="getProductMsg"></add-product-dialog>
        <copy-product-dialog @getMsg="getProductMsg"></copy-product-dialog>
    </div>
</template>

<script>
import cardList from '@/views/main/components/cardList';
import productTable from '@/views/main/components/productTable';
import noticeList from '@/views/main/components/noticeList';
import addProductDialog from '@/views/main/components/dialog/addProductDialog';
import copyProductDialog from '@/views/main/components/dialog/copyProductDialog';

export default {

    components: {
        cardList,
        productTable,
        noticeList,
        addProductDialog,
        copyProductDialog
    },

    data() {
        return {}
    },

    mounted() {

    },

    methods: {

        /**
         * 获取产品列表
         * @param page
         */
        getProductMsg(page) {
            console.log(page)
            this.$refs.productTable.getMsg(page);
        }

    }

}
</script>

<style scoped>
.main {
    width: 100%;
    height: 100%;
    /* background-color: white; */
}

.main_row {
    width: 100%;
    height: 100%;
}

.main_col {
    height: calc(100% - 5px);
}
</style>
