<template>
    <div class="step1">
        <div class="next-btn" v-if="activeNum === '0' && stepNum === '1'">
            <span class="next-btn-label">开启配对码验证：</span>
            <el-switch
                style="width: 40px;"
                v-model="form.isOpenCode">
            </el-switch>
        </div>

        <div class="step-form" v-show="(activeNum === '0' && stepNum === '1' && form.isOpenCode) || activeNum !== '0'">
            <el-form ref="leftForm" class="left-form" size="small" :rules="rules" :model="form.zh" label-width="130px">
                <el-form-item label="页面名称:" prop="pageName">
                    <span class="item-tip">简体中文</span>
                    <el-input v-model="form.zh.pageName" @blur="onFormChange" placeholder="请输入页面名称"></el-input>
                </el-form-item>
                <el-form-item label="引导说明:" prop="instructions">
                    <span class="item-tip">简体中文</span>
                    <el-input v-model="form.zh.instructions" @blur="onFormChange" type="textarea" maxlength="40" rows="4" show-word-limit placeholder="请输入引导说明"></el-input>
                </el-form-item>
                <el-form-item label="引导图片:" prop="file">
                    <span class="item-tip">简体中文</span>
                    <el-upload
                        list-type="picture-card"
                        :action="$message.uploadImgUrl"
                        :data="{
                               bucket: 'netConfig',
                               holdName: 'N' // 保持上传文件名不变
                            }"
                        :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: language
                            }"
                        accept=".png"
                        :limit="3"
                        :on-success="(response, file, fileList) => onSuccess(response, file, fileList, true)"
                        :on-error="onError"
                        :before-upload="(file) => onbeforeunload(file)"
                        :file-list="form.zh.file"
                        :on-remove="(file, fileList) => handleRemove(file, fileList, true)">
                        <i class="el-icon-plus" :id="`picture-card-zh`" v-show="form.zh.file.length < 3"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <el-form ref="rightForm" class="right-form" size="small" :rules="rules" :model="form.en" label-width="0">
                <el-form-item prop="pageName">
                    <span class="item-tip">英语</span>
                    <el-input v-model="form.en.pageName" @blur="onFormChange" placeholder="Please enter the boot name"></el-input>
                </el-form-item>
                <el-form-item prop="instructions">
                    <span class="item-tip">英语</span>
                    <el-input v-model="form.en.instructions" @blur="onFormChange" type="textarea" maxlength="40" rows="4" show-word-limit placeholder="Please enter the guide description"></el-input>
                </el-form-item>
                <el-form-item prop="file">
                    <span class="item-tip">英语</span>
                    <el-upload
                        list-type="picture-card"
                        :action="$message.uploadImgUrl"
                        :data="{
                               bucket: 'netConfig',
                               holdName: 'N' // 保持上传文件名不变
                            }"
                        :headers="{
                                accessToken: $token.getToken().accessToken,
                                refreshToken: $token.getToken().refreshToken,
                                language: language
                            }"
                        accept=".png"
                        :limit="3"
                        :on-success="(response, file, fileList) => onSuccess(response, file, fileList, false)"
                        :on-error="onError"
                        :before-upload="(file) => onbeforeunload(file)"
                        :file-list="form.en.file"
                        :on-remove="(file, fileList) => handleRemove(file, fileList, false)">
                        <i class="el-icon-plus" :id="`picture-card-en`" v-show="form.en.file.length < 3"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "pairingCode",

    props: {
        formValue: {
            Type: String,
            default: ""
        },
        activeName: {
            Type: String,
            default: ""
        },
        step: {
            Type: String,
            default: ""
        }
    },

    watch: {
        activeName: {
            handler(newVal) {
                this.activeNum = newVal;
            }
        },
        step: {
            handler(newVal) {
                this.stepNum = newVal;
            }
        },
        formValue: {
            handler(newVal) {
                if (newVal) {
                    this.form = JSON.parse(newVal)
                }
            }
        }
    },

    data() {
        return {
            form: {
                name: "配对码",
                isOpenCode: false,
                zh: {
                    pageName: "",
                    instructions: "",
                    file: []
                },
                en: {
                    pageName: "",
                    instructions: "",
                    file: []
                }
            },
            activeNum: "",
            stepNum: "",
            language: localStorage.getItem('language'),
            rules: {
                pageName: [{required: true, message: "请输入页面名称", trigger: "blur"}],
                instructions: [{required: true, message: "请输入引导说明", trigger: "blur"}],
                file: [{ required: true, message: '请上传引导图片', trigger: 'change' }],
            }
        }
    },

    methods: {

        onSuccess(response, file, fileList, isZH) {
            console.log(response)
            if (response.success) {
                this.form[isZH ? 'zh' : 'en'].file = fileList;
                if (this.form[isZH ? 'zh' : 'en'].file.length < 3) {
                    document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}`).parentNode.style.display = ""
                } else {
                    document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}`).parentNode.style.display = "none"
                }
                this.onFormChange()
            } else {
                this.$dialog.showMessage(response.resultMsg, this.$config.TOAST_ERROR);
            }
        },

        onbeforeunload(file) {},

        handleRemove(file, fileList, isZH) {
            this.form[isZH ? 'zh' : 'en'].file = fileList;
            this.onFormChange();
            console.log(this.form[isZH ? 'zh' : 'en'].file, this.form[isZH ? 'zh' : 'en'].file.length, document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}}`))
            if (this.form[isZH ? 'zh' : 'en'].file.length < 3) {
                document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}`).parentNode.style.display = ""
            } else {
                document.getElementById(`picture-card-${isZH ? 'zh' : 'en'}`).parentNode.style.display = "none"
            }
        },

        onError(err, file, fileList) {
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            console.log('onError', err, file, fileList)
        },

        onFormChange() {
            this.$emit('change', this.form);
        }
    }
}
</script>

<style scoped>

.step1 {
    width: calc(100% - 48px);
    margin: 24px;
    display: flex;
    flex-direction: column;
}

.next-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
}

.next-btn-label {
    width: 130px;
    text-align: right;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}

.step-form {
    display: flex;
    flex-direction: row;
}

.left-form {
    flex: 7;
}

.right-form {
    flex: 5;
    margin-left: 24px;
}

.item-tip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}

/deep/ .el-form-item__content {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

/deep/ .el-form-item__label {
    font-weight: bold;
}

/deep/ .el-upload-list__item, /deep/ .el-upload--picture-card {
    width: 100px!important;
    height: 100px!important;
}

/deep/ .el-form-item__content > div {
    display: flex;
}

/deep/ .el-upload--picture-card {
    line-height: 110px!important;
    margin-bottom: 20px;
}

/deep/ .el-upload-list--picture-card .el-progress {
    /*width: 80px!important;*/
    /*height: 80px!important;*/
    zoom: 70%;
}
</style>
