<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="人员管理"
        :visible.sync="visible"
        width="40%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-button size="small" type="primary" plain @click="addPersonnel">
            新增人员
        </el-button>


        <el-table
            ref="deviceTable"
            class="device_table"
            :data="personnelMsg.list"
            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
            v-loading="tableLoading"
            style="width: 100%;margin-top: 10px;">

            <el-table-column
                prop="totalProbation"
                label="姓名">

            </el-table-column>

            <el-table-column
                prop="totalProbation"
                label="手机号">

            </el-table-column>

            <el-table-column
                prop="totalProbation"
                label="用户ID">

            </el-table-column>
            <el-table-column
                label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" style="color: #FF4D4F;">删除</el-button>
                </template>
            </el-table-column>

        </el-table>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 定</el-button>
        </span>

        <add-personnel-dialog/>

    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";
import AddPersonnelDialog from "@/views/maintain/components/dialog/addPersonnelDialog";

export default {

    name: "personnelManagementDialog",
    components: {AddPersonnelDialog},
    computed: {
        ...mapState('maintain', ['personnelManagementDialogVisible'])
    },

    watch: {

        personnelManagementDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            tableLoading: false,

            loading: false,

            personnelMsg: {
                list: [],
                totalRecords: 10
            }

        }

    },

    methods: {

        ...mapActions('maintain', ['closePersonnelManagementDialog']),

        addPersonnel() {
            this.$store.commit('maintain/SET_ADD_PERSONNEL_DIALOG_VISIBLE', true)
        },

        openDialog() {

        },

        closeDialog() {
            this.closePersonnelManagementDialog();
        }

    }

}
</script>

<style scoped>

</style>
