<template>
    <el-drawer
        :title="$i18n.t('dashboard.text17')"
        :visible.sync="visible"
        direction="rtl"
        class="drawer"
        v-loading="loading"
        @open="handleOpen"
        @close="handleClose">
        <div class="drawer_body">
            <span class="product_name">{{productMsg.name}}</span>
            <span class="pid">PID: {{productMsg.pid}}</span>
            <span class="order_number">{{$i18n.t('dashboard.text8')}}: {{selectOrder.orderNo ? selectOrder.orderNo : '--'}}</span>
            <span class="test_time">{{$i18n.t('dashboard.text15')}}: {{selectOrder.startTime}} ~ {{selectOrder.endTime}}</span>
            <span class="address">{{$i18n.t('dashboard.text9')}} : {{selectOrder.address}}</span>

            <div class="card_list">
                <div class="card_item">
                    <span class="card_title">{{$i18n.t('dashboard.text11')}}</span>
                    <span class="card_value">{{selectOrder.successAmount + selectOrder.failureAmount}}</span>
                </div>
                <div class="card_item">
                    <span class="card_title">{{$i18n.t('dashboard.text12')}}</span>
                    <span class="card_value">{{selectOrder.successAmount}}</span>
                </div>
                <div class="card_item">
                    <span class="card_title">{{$i18n.t('dashboard.text13')}}</span>
                    <span class="card_value">{{selectOrder.failureAmount}}</span>
                </div>
                <div class="card_item">
                    <span class="card_title">{{$i18n.t('dashboard.text14')}}</span>
                    <span class="card_value">
                        <span :style="{color: selectOrder.failureAmount > 0 ? 'red' : ''}">
                            {{(selectOrder.failureAmount/(selectOrder.successAmount + selectOrder.failureAmount)*100).toFixed(3) + '%'}}
                        </span>
                    </span>
                </div>
            </div>

            <span class="subtitle">{{$i18n.t('dashboard.text23')}}</span>
            <div id="chart" style="width: 100%;"></div>
        </div>
        <div class="demo-drawer__footer">
            <el-button type="primary" @click="exportLog">{{$i18n.t('dashboard.text24')}}</el-button>
        </div>
    </el-drawer>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {Pie} from '@antv/g2plot';

export default {

    name: "factoryTestMsgDrawer",

    computed: {
        ...mapState('product', ['productMsg']),
        ...mapState('factoryTest', ['factoryTestMsgDrawerVisible', 'selectOrder'])
    },

    watch: {

        factoryTestMsgDrawerVisible: {
            handler(newVal) {
                console.log('factoryTestMsgDrawer => ', newVal)
                this.visible = newVal;
            },
            deep: true
        }

    },

    data() {
        return {

            //弹窗开关
            visible: false,

                loading: false,

            chart: null,

            data: [],

            total: 0,

            exportLoading: false

        }
    },

    methods: {

        ...mapActions('factoryTest', ['closeFactoryTestMsgDrawer', 'queryDeviceStatisticsItemByOrderNo', 'exportFactoryTestLog']),

        handleOpen() {
            this.loading = true;
            let data = {pid: this.selectOrder.pid}
            if (this.selectOrder.ip) data.ip = this.selectOrder.ip
            if (this.selectOrder.orderNo) {
                data.orderNo = this.selectOrder.orderNo;
            } else {
                data.theTime = this.selectOrder.theTime;
            }
            this.queryDeviceStatisticsItemByOrderNo(data).then(res => {
                let data = []
                let total = 0;
                res.result.forEach(item => {
                    if (item.failureAmount > 0) {
                        data.push({
                            type: item.itemName,
                            value: item.failureAmount
                        })
                        total = total + item.failureAmount;
                    }
                })
                this.data = data;
                this.total = total;
                this.setChart();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.loading = false)
        },

        setChart() {

            this.chart = new Pie('chart', {
                appendPadding: 90,
                padding: [-80, 20, 50, 20],
                data: this.data,
                angleField: 'value',
                colorField: 'type',
                radius: 1,
                innerRadius: 0.6,
                label: {
                    type: 'outer', // 使用外部标签类型
                    content: null, // 不显示标签内容
                },
                legend: {
                    position: 'bottom',
                    offsetY: -100
                },
                statistic: {
                    title: false,
                    content: {
                        style: {
                            opacity: 0, // 将文本透明度设为0
                        },
                    },
                },
                tooltip: {
                    customContent: (title, items) => {
                        console.log(items)
                        return `<div style="margin: 10px;">
                                    ${items.map(item => `
                                      <div style="display: flex;flex-direction: column;">
                                        <span style="font-size: 14px;font-weight: bold;color: rgba(0, 0, 0, 0.85);">${item.name}</span>
                                        <span style="margin-top: 14px;color: rgba(0, 0, 0, 0.65);font-size: 14px;">${this.$i18n.t('dashboard.text25')} : ${((item.value/this.total)*100).toFixed(0)}%</span>
                                      </div>
                                    `).join('')}
                                  </div>`;
                    }
                }
            })
            this.chart.render();
        },

        exportLog() {
            this.exportLoading = true;
            let data = {pid: this.selectOrder.pid}
            if (this.selectOrder.ip) data.ip = this.selectOrder.ip
            if (this.selectOrder.orderNo) {
                data.orderNo = this.selectOrder.orderNo;
            } else {
                data.theTime = this.selectOrder.theTime;
            }
            this.exportFactoryTestLog(data).then(res => {
                console.log(res);
                if (res.data instanceof Blob) {
                    console.log("准备下载")
                    const fileName = `${this.selectOrder.pid}_${this.selectOrder.orderNo ? this.selectOrder.orderNo : this.selectOrder.theTime}_${new Date().getTime()}.xls`;
                    this.downFile(res.data, fileName);
                    return;
                }
                this.exportLoading = false;
                this.$dialog.showMessage(res.data.resultMsg, this.$config.TOAST_ERROR);
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },



        /**
         * 文件下载
         * @param data
         * @param fileName
         * @returns {boolean}
         */
        downFile(data, fileName) {
            if (!data) {
                return false;
            }
            // 下面就是DOM操作 1.添加一个a标签 2.给a标签添加了属性 3.给他添加了点击事件(点击后移除)
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        handleClose() {
            this.chart.destroy();
            this.loading = false;
            this.exportLoading = false;
            this.chart = null;
            this.total = 0;
            this.data = [];
            this.closeFactoryTestMsgDrawer()
        }

    }

}
</script>

<style scoped>
.drawer {
    text-align: left!important;
}
.drawer_body {
    padding: 16px;
    display: flex;
    flex-direction: column;
}

/deep/ .el-drawer__header {
    margin: 16px!important;
    padding: 0;
}

.product_name {
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
}

.pid, .order_number, .test_time, .address {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 8px;
}

.card_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card_item {
    width: calc((100% - 48px)/4);
    height: 72px;
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card_title {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
}

.card_value {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 4px;
}

.subtitle {
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 24px;
}

.demo-drawer__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: white;
}
</style>
