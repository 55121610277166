<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="设置密码"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <!-- 设置密码表单 start -->
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm">
            <el-form-item prop="password">
                <el-input
                    type="password"
                    v-model.trim="form.password"
                    show-password
                    placeholder="输入密码">
                </el-input>
            </el-form-item>

            <el-form-item prop="password">
                <el-input
                    type="password"
                    v-model.trim="form.confirmPassword"
                    show-password
                    placeholder="确认密码">
                </el-input>
            </el-form-item>
        </el-form>
        <!-- 设置密码表单 end -->

        <!-- 弹窗底部按钮 start -->
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" :loading="loading" @click="submit" style="width: 100%;">完 成</el-button>
        </span>
        <!-- 弹窗底部按钮 end -->

    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['passwordDialogVisible'])
    },

    watch: {
        //设置密码弹窗开关
        passwordDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },

    data() {
        return {

            loading: false,

            form: {
                password: "",
                confirmPassword: ""
            },

            visible: false,

            rules: {
                password: [{required: true, message: "请输入密码", trigger: "blur"}]
            }

        }

    },

    methods: {

        ...mapActions('login', ['closePasswordDialog']),

        openDialog() {},

        /**
         * 关闭弹窗回调
         */
        closeDialog() {
            this.closePasswordDialog();
            this.$refs.ruleForm.resetFields();
        },

        submit() {
            this.closeDialog();
        }

    }
}
</script>

<style scoped>
.code_btn {
    font-size: 10px;
    padding: 0 10px;
}
</style>
