<template>
    <el-menu
        :router="true"
        :default-active="$route.meta.parentPath"
        active-text-color="#FFFFFF"
        :unique-opened="true">
        <tree-menu :item="item1" v-for="(item1,index) in userMenu" :key="index"></tree-menu>
    </el-menu>
</template>

<script>
// import {userMenu} from "../../../utils/message";
import {mapState, mapActions} from "vuex";
import treeMenu from '@/components/treeMenu'

export default {
    computed: {
        ...mapState('login', ['menuData'])
    },
    watch: {
        list: {
            handler(newVal) {
                this.userMenu = newVal;
            },
            deep: true
        }
    },
    components: {
        treeMenu
    },
    props: {
        list: {
            Type: Array,
            default: () => []
        }
    },
    data() {
        return {
            userMenu: []
        }
    },
    mounted() {

    },
    methods: {}
}
</script>

<style scoped>

.el-menu {
    height: 100%;
    border-right-width: 0;
}

.iconfont {
    color: inherit !important;
    margin: 0 6px;
}


.el-submenu .el-menu-item {
    padding-left: 52px !important;
}

.top-5 {
    margin-top: 5px;
}

.el-menu {
    overflow: hidden;
}
</style>
