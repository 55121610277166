<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="个人信息"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item label="用户ID：">
                {{ form.userId }}
            </el-form-item>
            <el-form-item label="昵称：" prop="nickname">
                <el-input
                    type="text"
                    maxlength="20"
                    show-word-limit
                    v-model.trim="form.nickname"
                    placeholder="请输入昵称">
                </el-input>
            </el-form-item>
            <el-form-item label="头像：">
                <el-upload
                    class="avatar-uploader"
                    :action="$message.uploadImgUrl"
                    :data="{
                        bucket: 'user'
                    }"
                    :headers="{
                        accessToken: $token.getToken().accessToken,
                        refreshToken: $token.getToken().refreshToken,
                        language: $message.language
                    }"
                    :show-file-list="false"
                    accept=".png,.jpeg,.jpg,.gif,.bmp"
                    :on-success="handleAvatarSuccess"
                    :on-error="onErr"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="form.headImg" :src="form.headImg" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span class="tips">头像尺寸为30*30，格式为png、jpeg、jpg、gif、bmp</span>
            </el-form-item>
            <el-form-item label="地址：" prop="address">
                <el-input
                    type="text"
                    maxlength="50"
                    show-word-limit
                    v-model.trim="form.address"
                    placeholder="请输入地址">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('setting', ['updateUserDialogVisible']),
        ...mapState('login', ['userInfo'])
    },

    watch: {
        updateUserDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },
        userInfo: {
            handler(newVal) {
                this.form = {
                    nickname: newVal.nickname,
                    userId: newVal.userId,
                    headImg: newVal.headImg,
                    address: newVal.address
                };
            }
        }
    },

    data() {
        return {
            formLabelWidth: "100px",
            loading: false,
            visible: false,
            form: {
                nickname: "",
                userId: "",
                headImg: "",
                address: ""
            },
            rules: {
                nickname: [
                    {required: true, message: "请输入昵称", trigger: "blur"}
                ],
                communication: [
                    {required: true, message: "请输入地址", trigger: "blur"}
                ]
            }
        }
    },

    methods: {

        ...mapActions('setting', ['closeUpdateUserDialog']),

        ...mapActions('login', ['modifyUserInfo', 'getlogininfo']),

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.modifyUserInfo(this.form).then(res => {
                        this.closeDialog();
                        this.$emit("getUserInfo");
                        this.getlogininfo();
                        this.$dialog.showMessage('修改成功', this.$config.TOAST_SUCCESS);
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        console.log(err)
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        },

        handleAvatarSuccess(res, file) {
            console.log(res, file)
            if (res.success) {
                this.form.headImg = res.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isGIF = file.type === 'image/gif';
            const isBMP = file.type === 'image/bmp';
            // const isLt2M = file.size / 1024 / 1024 < 2;
            const isSize = new Promise(function (resolve, reject) {
                let width = 30;
                let height = 30;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage('上传头像尺寸不符合,只能是30*30!', this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)
            if (!(isJPG || isJPEG || isPNG || isGIF || isBMP)) {
                this.$dialog.showMessage('图片仅支持jpg/jpeg/png/gif/bmp格式！', this.$config.TOAST_WARNING);
            }
            // if (!isLt2M) {
            //     this.$dialog.showMessage('上传头像图片大小不能超过 2MB!', this.$config.TOAST_WARNING);
            // }
            return (isJPG || isJPEG || isPNG || isGIF || isBMP) && isSize;
        },

        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        closeDialog() {
            this.closeUpdateUserDialog();
            this.$refs.ruleForm.resetFields();
        },

        openDialog() {
            this.form = {
                nickname: this.userInfo.nickname,
                userId: this.userInfo.userId,
                headImg: this.userInfo.headImg,
                address: this.userInfo.address
            }
        }

    }
}
</script>

<style scoped>

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

/deep/ .el-dialog__footer {
    text-align: right;
}

.tips {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

.el-form {
    margin-top: 20px;
}

.avatar {
    width: 80px;
    height: 80px;
    border-radius: 4px;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
</style>
