<template>
    <div class="step_side">
        <div class="side_header">
            <div class="side_title">{{ msg.name }}</div>
            <div class="side_id">{{ msg.id }}</div>
        </div>
        <step class="step"></step>
    </div>
</template>

<script>

import step from '@/views/product/components/step';
import {mapState} from "vuex";

export default {

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {

        productMsg: {
            handler(newVal) {
                this.msg = {
                    name: newVal.name,
                    id: newVal.pid
                }
            },
            deep: true
        }

    },

    components: {
        step
    },

    data() {

        return {

            msg: {
                name: "",
                id: ""
            }

        }

    }

}
</script>

<style scoped>
.step_side {
    width: 100%;
    height: 100%;
}

.side_header {
    width: 100%;
    text-align: center;
}

.side_title {
    font-size: 16px;
    font-weight: bold;
}

.side_id {
    font-size: 14px;
    margin-top: 5px;
}

.step {
    width: 100%;
    height: 100%;
}
</style>
