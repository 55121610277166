<template>
    <el-card
        :body-style="{
            textAlign: 'left',
            padding: '15px'
        }"
        shadow="never">
        <div slot="header" class="clearfix">
            <span style="font-size: 16px;color: black;">个人信息</span>
            <el-button plain style="float: right;margin-top: -3px;" size="mini" type="primary" @click="upadteUser">
                编辑
            </el-button>
        </div>
        <div class="content">
<!--            <el-form ref="form" label-width="80px">-->
<!--                <el-form-item label="昵称：">-->
<!--                    {{ from.nickname ? from.nickname : '' }}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="用户ID：">-->
<!--                    {{ from.userId }}-->
<!--                </el-form-item>-->
<!--                <el-form-item label="头像：">-->
<!--                    <el-avatar :size="60" v-if="from.headImg">-->
<!--                        <img :src="from.headImg"/>-->
<!--                    </el-avatar>-->
<!--                    <el-avatar :size="60" icon="el-icon-s-custom" v-else></el-avatar>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="地址：">-->
<!--                    {{ from.address ? from.address : '' }}-->
<!--                </el-form-item>-->
<!--            </el-form>-->

            <el-descriptions title=" " :column="1" :labelStyle="{
                fontWeight: 'bold'
            }">
                <el-descriptions-item label="昵称">
                    {{ from.nickname ? from.nickname : '' }}
                </el-descriptions-item>
                <el-descriptions-item label="用户ID">
                    {{ from.userId }}
                </el-descriptions-item>
                <el-descriptions-item label="头像">
                    <el-avatar :size="60" v-if="from.headImg">
                        <img :src="from.headImg"/>
                    </el-avatar>
                    <el-avatar :size="60" style="background: transparent;" v-else>
                        <img src="../../../../../assets/icon_avatar.png"/>
                    </el-avatar>
                </el-descriptions-item>
                <el-descriptions-item label="地址">
                    {{ from.address ? from.address : '' }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </el-card>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['userInfo'])
    },

    watch: {
        userInfo: {
            handler(newVal) {
                this.from = newVal;
            }
        }
    },

    data() {
        return {
            from: {
                // nickname:"涛声依旧",
                // userId:"38298491211",
                // avatar:require("../../../../../assets/avatar.png"),
                // address:"长沙市高新区尖山路39号长沙中电软件园二期A20栋"
            }
        }
    },

    mounted() {
        this.from = this.userInfo;
    },

    methods: {
        upadteUser() {
            this.$store.commit("setting/SET_UPDATE_USER_DIALOG_VISIBLE", true);
        }
    }

}
</script>

<style scoped>

.el-card {
    border: none;
    border-radius: 8px;
    height: calc(50% - 23px);
    margin: 20px;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.content {
    margin-left: 80px;
    margin-top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.el-form-item {
    margin-bottom: 5px;
}

/deep/ .el-form-item__label {
    font-weight: bold;
}
</style>
