<template>
    <el-card
        :body-style="{
            textAlign: 'left',
            padding: '15px',
            height: '100%'
        }"
        shadow="never">
        <div slot="header" class="clearfix">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
                <el-breadcrumb-item>账号列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="header_btn">
            <el-button class="btn" size="small" type="primary" @click="addMenu">新增一级菜单</el-button>
        </div>
        <el-table
            :data="menuData"
            row-key="code"
            v-loading="loading"
            ref="menuTable"
            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
            :height="tableHeight"
            default-expand-all
            :tree-props="{children: 'children'}">
            <el-table-column
                prop="name"
                label="菜单名称">
            </el-table-column>
            <el-table-column
                prop="level"
                label="层级">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.level == 1">一级菜单</el-tag>
                    <el-tag v-if="scope.row.level == 2" type="success">二级菜单</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                prop="sort"
                label="排序">
            </el-table-column>
            <el-table-column
                prop="founder"
                label="创建人">
            </el-table-column>
            <el-table-column
                label="状态">
                <template slot-scope="scope">
                    {{ $config.getMsgItemUtil(stateList, scope.row.state, 'id', 'name') }}
                </template>
            </el-table-column>
            <el-table-column width="260" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
                    <el-popconfirm
                        :confirm-button-text="scope.row.state == 1?'禁用':'启用'"
                        cancel-button-text='取消'
                        icon="el-icon-info"
                        icon-color="red"
                        style="margin-left: 10px;margin-right: 10px;"
                        @confirm="setState(scope.row)"
                        :title="'是否'+(scope.row.state == 1?'禁用':'启用')+'当前菜单？'">
                        <el-button slot="reference" type="text" size="small">{{ scope.row.state == 1 ? '禁用' : '启用' }}
                        </el-button>
                    </el-popconfirm>
                    <el-button type="text" size="small" @click="addSubMenu(scope.row)">新建下级菜单</el-button>
                    <el-popconfirm
                        confirm-button-text="删除"
                        cancel-button-text='取消'
                        icon="el-icon-info"
                        icon-color="red"
                        style="margin-left: 10px;margin-right: 10px;"
                        @confirm="delMenu(scope.row)"
                        :title="'是否删除当前菜单？'">
                        <el-button slot="reference" type="text" size="small">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <add-menu-dialog></add-menu-dialog>
    </el-card>
</template>

<script>
import {mapState, mapActions} from "vuex";
import addMenuDialog from '@/views/setting/views/menuManage/components/dialog/addMenuDialog';

export default {
    components: {
        addMenuDialog
    },
    computed: {
        ...mapState('menu', ['menuList'])
    },
    watch: {
        menuList: {
            handler(newVal) {
                this.loading = false;
                this.menuData = newVal.homeMenuList.concat(newVal.settingMenuList);
                console.log(newVal);
            },
            deep: true
        }
    },
    data() {
        return {
            loading: false,
            menuData: [],
            tableHeight: 50,
            stateList: [
                {id: 1, name: "启用"},
                {id: 2, name: "禁用"}
            ]
        }
    },
    mounted() {
        setTimeout(() => {
            this.tableHeight = window.innerHeight - 45 - 180;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getMag();
    },
    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },
    methods: {
        ...mapActions('menu', ['getUserAuthorityTree']),

        setHeight() {
            this.tableHeight = window.innerHeight - 45 - 180;
        },
        getMag() {
            this.loading = true;
            this.getUserAuthorityTree();
        },
        edit(row) {
            console.log(row);
            this.$store.commit("menu/SET_ADD_MENU_TYPE", 1);
            this.$store.commit("menu/SET_ADD_MENU_DIALOG_VISIBLE", true);
            this.$store.commit("menu/SET_MENU_MSG_ITEM", row);
        },
        setState(row) {
            console.log(row);
        },
        addSubMenu(row) {
            console.log(row);
            this.$store.commit("menu/SET_ADD_MENU_TYPE", 3);
            this.$store.commit("menu/SET_ADD_MENU_DIALOG_VISIBLE", true);
            this.$store.commit("menu/SET_MENU_MSG_ITEM", row);
        },
        delMenu(row) {
            console.log(row);
        },
        handleCurrentChange(page) {
            console.log(page)
        },
        addMenu() {
            this.$store.commit("menu/SET_ADD_MENU_TYPE", 2);
            this.$store.commit("menu/SET_ADD_MENU_DIALOG_VISIBLE", true);
        }
    }
}
</script>

<style scoped>

.el-card {
    border: none;
    border-radius: 8px;
    height: 100%;
}

/deep/ .el-card__header {
    padding: 15px !important;
    text-align: left;
    font-size: 16px;
}

.member_table {
    padding-top: 10px;
    border-radius: 4px 4px 0 0;
}

.el-input {
    width: 200px;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

.header_btn {
    width: 100%;
    margin-bottom: 10px;
    text-align: right;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
