<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="移交企业"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <div class="tips">移交企业只可移交给管理员权限的企业成员，且原拥有者权限成员将自动从成员列表删除</div>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" class="ruleForm"
                 :label-width="formLabelWidth">
            <el-form-item label="移交人：" prop="userId">
                <el-select v-model="form.userId" :popper-append-to-body="false" placeholder="选择移交人">
                    <el-option
                        v-for="item in userList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
    computed: {
        ...mapState('member', ['transferEnterpriseDialogVisible'])
    },
    watch: {
        transferEnterpriseDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },
    data() {
        return {
            visible: false,
            formLabelWidth: "100px",
            loading: false,
            form: {
                userId: ""
            },
            rules: {
                userId: [{required: true, message: "请选择移交人", trigger: "blur"}]
            },
            userList: [
                {id: 1, name: "张三"},
                {id: 2, name: "李四"},
                {id: 3, name: "王五"},
            ]
        }
    },
    methods: {
        ...mapActions('member', ['closeTransferEnterpriseDialog']),
        closeDialog() {
            this.closeTransferEnterpriseDialog();
        },
        openDialog() {

        }
    },
}
</script>

<style scoped>
.el-select {
    width: 100%;
}

.tips {
    font-size: 12px;
    text-align: center;
}

.ruleForm {
    margin-top: 10px;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-form {
    margin-top: 20px;
}
</style>
