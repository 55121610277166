var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page_title"},[_vm._v(" "+_vm._s(_vm.$i18n.t('enterpriseInfo.text1'))+" ")]),_c('el-card',{attrs:{"body-style":{
            textAlign: 'left',
            padding: '15px',
            height: '100%'
        },"shadow":"never"}},[_c('div',{staticClass:"header_btn"},[_c('el-button',{staticClass:"btn",attrs:{"size":"small","type":"primary","plain":""},on:{"click":_vm.toAuthentication}},[_vm._v(_vm._s(_vm.$i18n.t('enterpriseInfo.text2')))])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"enterpriseTable",staticClass:"enterprise_table",staticStyle:{"width":"100%"},attrs:{"data":_vm.enterpriseMsg.list,"height":_vm.tableHeight,"header-cell-style":{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'},"cell-style":{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}}},[_c('el-table-column',{attrs:{"prop":"fullName","label":_vm.$i18n.t('enterpriseInfo.text3')}}),_c('el-table-column',{attrs:{"prop":"shortName","label":_vm.$i18n.t('enterpriseInfo.text4')}}),_c('el-table-column',{attrs:{"prop":"shortNameEn","label":_vm.$i18n.t('enterpriseInfo.text5')}}),_c('el-table-column',{attrs:{"prop":"legalPerson","label":_vm.$i18n.t('enterpriseInfo.text6')}}),_c('el-table-column',{attrs:{"prop":"contacts","label":_vm.$i18n.t('enterpriseInfo.text7')}}),_c('el-table-column',{attrs:{"prop":"contactNumber","label":_vm.$i18n.t('enterpriseInfo.text8')}}),_c('el-table-column',{attrs:{"width":"200","label":_vm.$i18n.t('enterpriseInfo.text9')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{style:({
                            color: _vm.setColor(scope.row.state),
                            border: '1px solid ' + _vm.setColor(scope.row.state),
                            borderRadius: '4px',
                            width: 'auto',
                            fontSize: '12px',
                            padding: '3px'
                        })},[_vm._v(" "+_vm._s(_vm.$config.getMsgItemUtil(_vm.$message.certifiedEnterpriseStatusList, scope.row.state, 'id', 'name'))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$i18n.t('enterpriseInfo.text10'),"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.state === 2)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.modifyCertification(scope.row)}}},[_vm._v(_vm._s(_vm.$i18n.t('enterpriseInfo.text11'))+" ")]):_c('span',{staticStyle:{"color":"#1890FF"}},[_vm._v("—")])]}}])})],1),_c('el-pagination',{staticStyle:{"float":"right","margin-top":"10px","margin-bottom":"10px"},attrs:{"background":"","page-size":10,"current-page":_vm.page,"layout":" prev, pager, next","total":_vm.enterpriseMsg.totalRecords},on:{"current-change":_vm.handleCurrentChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }