<template>
    <div v-html="msg" style="margin: 10px;text-align: left;">

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "protocol",
    data() {
        return {
            msg: ""
        }
    },
    mounted() {
        window.addEventListener('hashchange',this.onHASHChange);
        this.getDoc();
    },
    methods: {
        getDoc() {
            axios.get('./doc.json', {}).then(res => {
                this.msg = res.data[this.$route.query.type];
            }, err => {
                console.log(err)
            })
        },
        onHASHChange() {
            window.scrollTo(0, 0);
            this.getDoc();
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.onHASHChange);
    }
}
</script>

<style scoped>

</style>
